<style>
  .background-sky {
    background-color: #1ab394;
    background: linear-gradient(145deg,#18b38e,#21c6ca);
    color: #fff;
  }

  .hero {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    min-height: 480px;
    height: 80vh;
    max-height: 560px;
    box-sizing: border-box;
    padding: 48px 48px 32px;
    overflow: hidden;
    -webkit-transform: skewY(8deg);
    transform: skewY(8deg);
    -webkit-transform-origin: 100%;
    transform-origin: 125%;
  }

  .FuenteLetra {
    font-family: Roboto,"Helvetica Neue Light","Helvetica Neue",Helvetica,Arial,"Lucida Grande",sans-serif;
  }

  .hero-headline {
    font-size: 57px;
    line-height: 60px;
    font-weight: 500;
    margin: 32px 0;
  }
</style>
<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="background-sky hero"></div>
    <div class="col-lg-12">
      <div class="col-lg-2">
      </div>
      <div class="col-lg-8 text-white FuenteLetra">
        <div class="col-lg-5">
          <img alt="Logo" height="250" class="pull-right img-circle" src="assets/images/Logo.png" width="250">
        </div>
        <div class="col-lg-7">
          <div class="text-center hero-headline">
            <div>
              Bienvenido a {{AppName}}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-2">
      </div>
    </div>
  </div>
</div>
