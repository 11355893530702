<div class="row">
  <div class="col-lg-4">
  </div>
  <div class="col-lg-4 text-center">
    <h1>Seguimientos de la familia</h1>
  </div>
  <div class="col-lg-4">
    <button type="button" class="btn btn-primary btn-sm pull-right" (click)="ModalNuevoSeguimiento()"><i class="fa fa-save (alias)"></i> Nuevo</button>
  </div>
</div>
<div class="hr-line-dashed"></div>
<div class="row">
  <div class="col-lg-12">
    <app-data-table-generic [NameReport]="'Seguimientos de la familia'" (CellClicked)="ClickFila($event)" [Filas]="Seguimientos" [ShowLoading]="CargandoTablaSeguimientos" [columns]="Columnas"></app-data-table-generic>
  </div>
</div>
<div class="hr-line-dashed"></div>
<div class="row">
  <div class="col-lg-12">
    <div class="m-b-md">
      <button type="button" class="btn btn-primary btn-sm pull-right" (click)="GestionSeguimiento()"><i class="fa fa-save (alias)"></i> Guardar</button>
      <h2 class="center">Seguimiento</h2>
    </div>
  </div>
  <div class="col-lg-12" *ngIf="RequestGestion && RequestGestion.Seguimiento.Secciones.length>0 && RequestGestion.Seguimiento.Secciones[0].Id==0">
    <div class="alert alert-warning">
      <p>Estimado usuario por favor recuerde dar click en guardar para que el cuestionario presente sea almacenado en el sistema. </p>
      <small class="text-danger">Este mensaje sera vigente hasta tanto usted no guarde el formulario.</small>
    </div>
  </div>
  <div class="col-lg-6" *ngIf="RequestGestion.Seguimiento.ValoracionSeguimiento">
    <div>
      <p>
        Valor seguimiento: <strong>{{RequestGestion.Seguimiento.ValorSeguimiento}}</strong>
      </p>
      <p>
        Valoración: <strong>{{RequestGestion.Seguimiento.ValoracionSeguimiento | uppercase}}</strong>
      </p>
    </div>
    <div>
      <p>
        Que hacer: <strong>{{RequestGestion.Seguimiento.QueHacer | uppercase}}</strong>
      </p>
    </div>
    <div>
      <h3>Observación - Remisión</h3>
      <div>
        <div class="form-group">
          <textarea rows="5" name="Observación" [(ngModel)]="RequestGestion.Seguimiento.Observacion" style="width: 100%;"></textarea>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row m-t-sm" *ngIf="RequestGestion && RequestGestion.Seguimiento.Secciones.length>0">
  <div class="col-lg-12">
    <div class="table-responsive">
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th class="text-center">Necesidades</th>
            <th class="text-center">Satisfactores</th>
            <th class="text-center">Respuesta</th>
            <th class="text-center">Observación</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let seccion of RequestGestion.Seguimiento.Secciones">
            <tr>
              <td [attr.rowspan]="seccion.Cuestionarios.length" style="vertical-align: middle;text-align: center;" class="text-left" placement="top">
                <strong>{{seccion.NombreSeguimiento}}</strong>
              </td>
              <td class="text-left" placement="top">
                {{seccion.Cuestionarios[0].NombrePregunta}}
              </td>
              <td class="text-left" placement="top">
                <div class="text-center checkbox checkbox-primary">
                  <input type="checkbox" ng-reflect-model="true" id="DinamycPregunta{{seccion.Cuestionarios[0].CodigoPregunta}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="seccion.Cuestionarios[0].Respuesta">
                  <label for="DinamycPregunta{{seccion.Cuestionarios[0].CodigoPregunta}}"></label>
                </div>
              </td>
              <td class="text-left" placement="top">
                <input type="text" class="form-control" placeholder="Observación" [(ngModel)]="seccion.Cuestionarios[0].Observacion">
              </td>
            </tr>
            <tr *ngFor="let cuestionario of seccion.Cuestionarios | slice:1">
              <td class="text-left" placement="top">
                {{cuestionario.NombrePregunta}}
              </td>
              <td class="text-left" placement="top">
                <div class="text-center checkbox checkbox-primary">
                  <input type="checkbox" ng-reflect-model="true" id="DinamycPregunta{{cuestionario.CodigoPregunta}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="cuestionario.Respuesta">
                  <label for="DinamycPregunta{{cuestionario.CodigoPregunta}}"></label>
                </div>
              </td>
              <td class="text-left" placement="top">
                <input type="text" class="form-control" [ngClass]="(cuestionario.Respuesta && cuestionario.SiEsTrueDebeEscribirObservacion)?'inputIluminado':''" (change)="ValidarSiTieneObservacionAndRespuestaDebeSerTrue(cuestionario)" placeholder="Observación" [(ngModel)]="cuestionario.Observacion">
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>


<ng-template #ModalRegistrarSeguimiento>
  <div class="modal-header bg-primary">
    <h4 class="modal-title pull-left">Registro de nuevo seguimiento</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefRegistroSeguimiento.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form ngNativeValidate #FormRegistrarSeguimiento="ngForm" (ngSubmit)="NuevoSeguimiento(FormRegistrarSeguimiento)">
    <div class="modal-body">
      <div class="row">
        <p>Estimado usuario por favor antes de registrar el seguimiento, verifique que la fecha no haya sido registrada.</p>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <div class="form-group">
            <label>Fecha</label>
            <input type="date" [(ngModel)]="RequestRegistroSeguimiento.Fecha" name="Fecha" required placeholder="Fecha" class="form-control">
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <div class="form-group">
              <label>Observación</label>
              <textarea rows="10" name="Descripcion" [(ngModel)]="RequestRegistroSeguimiento.Observacion" style="width: 100%;"></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary pull-left" type="submit"><i class="fa fa-check"></i>&nbsp;Registrar</button>
      <button type="button" class="btn btn-default pull-right" (click)="modalRefRegistroSeguimiento.hide()">Cerrar</button>
    </div>
  </form>
</ng-template>
