<div class="middle-box text-center loginscreen  animated fadeInDown">
  <div>
    <h3>Ingrese sus credenciales</h3>
    <form class="m-t" role="form" action="#">
      <div class="form-group">
        <input type="email" class="form-control" [(ngModel)]="login.username" placeholder="Username" name="Username" required="">
      </div>
      <div class="form-group">
        <input type="password" class="form-control" [(ngModel)]="login.password" placeholder="Password" name="Password" required="">
      </div>
      <button type="submit" (click)="Login()" class="btn btn-primary block full-width m-b" [disabled]="login.process"><span class="fa fa-circle-o-notch fa-spin text-white" *ngIf="login.process"></span> Login</button>

      <!--<a href="#"><small>Forgot password?</small></a>
      <p class="text-muted text-center"><small>Do not have an account?</small></p>-->
      <!--<a class="btn btn-sm btn-white btn-block" href="#">Create an account</a>-->
    </form>
    <p class="m-t">
      <small>&copy; Aníbal Guerra <br> 2018 - {{Year}}</small>
    </p>
  </div>
</div>
