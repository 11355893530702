import { Injectable } from '@angular/core';
import { HttpService } from '../../../http/http.service';
import { Observable } from 'rxjs';
import { UtilitiesAnibalService } from '../../../utilities-anibal';
import { FamiliaIndigenaProcesoRiesgo } from '../../../../entities/sipa/familiaindigena';
import { ApisRestCaracterizacionFamiliaIndigenaGestionProcesoRiesgo } from '../../../../system/apisrest';

@Injectable()
export class ProcesoRiesgoFamiliaIndigenaService {

  constructor(private _httpService: HttpService) { }

  public GetConsultarDatosFormulario(familiaIndigenaId: number): Observable<GestionProcesoRiesgoDatosFormularioResponse> {
    UtilitiesAnibalService.Console("Consultando ProcesoRiesgo de la familia (Datos formulario gestion)" + familiaIndigenaId);
    return this._httpService.get<GestionProcesoRiesgoDatosFormularioResponse>(ApisRestCaracterizacionFamiliaIndigenaGestionProcesoRiesgo.GetConsultarDatosFormulario(familiaIndigenaId));
  }

  public GestionProcesoRiesgo(request: GestionProcesoRiesgoRequest): Observable<GestionProcesoRiesgoResponse> {
    UtilitiesAnibalService.Console("Gestión ProcesoRiesgo de la familia" + request.FamiliaIndigenaId);
    return this._httpService.post<GestionProcesoRiesgoResponse>(ApisRestCaracterizacionFamiliaIndigenaGestionProcesoRiesgo.PostGestionProcesoRiesgo, request);
  }

}

export class GestionProcesoRiesgoResponse {
  public Mensaje: string;
}

export class GestionProcesoRiesgoRequest {
  public FamiliaIndigenaId: number;
  public ProcesosRiesgos: Array<FamiliaIndigenaProcesoRiesgo> = new Array<FamiliaIndigenaProcesoRiesgo>();
}

export class GestionProcesoRiesgoDatosFormularioResponse {
  public ProcesosRiesgos: Array<FamiliaIndigenaProcesoRiesgo> = new Array<FamiliaIndigenaProcesoRiesgo>();
}
