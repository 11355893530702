import { Injectable } from '@angular/core';
import { HttpService } from '../../../services/http/http.service';
import { UtilitiesAnibalService } from '../../../services/utilities-anibal';
import { ApisRestPublic } from '../../../system/apisrest';
import { Observable } from 'rxjs';
import { Departamento, Municipio, Asentamiento, Sector } from '../../../entities/general/departamento';

@Injectable()
export class PublicService {

    constructor(private _httpService: HttpService) { }

    public ConsultarDepartamentos(): Observable<ConsultarDepartamentosResponse> {
        UtilitiesAnibalService.Console("Consultando departamentos");
        return this._httpService.get<ConsultarDepartamentosResponse>(ApisRestPublic.GetDepartamentos);
    }

    public ConsultarMunicipios(departamentoId: number): Observable<ConsultarMunicipiosResponse> {
        UtilitiesAnibalService.Console("consultando municipios del departamento " + departamentoId);
        return this._httpService.get<ConsultarMunicipiosResponse>(ApisRestPublic.GetMunicipios(departamentoId));
    }

    public ConsultarAsentamientos(municipioId: number): Observable<ConsultarAsentamientosResponse> {
        UtilitiesAnibalService.Console("consultando asentamientos del municipio" + municipioId);
        return this._httpService.get<ConsultarAsentamientosResponse>(ApisRestPublic.GetAsentamientos(municipioId));
    }

    public ConsultarSectores(asentamientoId: number): Observable<ConsultarSectoresResponse> {
        UtilitiesAnibalService.Console("consultando sectores del municipio " + asentamientoId);
        return this._httpService.get<ConsultarSectoresResponse>(ApisRestPublic.GetSectores(asentamientoId));
    }

}

export class ConsultarDepartamentosResponse {
    public Departamentos: Array<Departamento>;
}

export class ConsultarMunicipiosResponse {
    public Municipios: Array<Municipio>;
}

export class ConsultarAsentamientosResponse {
    public Asentamientos: Array<Asentamiento>;
}

export class ConsultarSectoresResponse {
    public Sectores: Array<Sector>;
}
