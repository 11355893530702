<div *ngIf="Filas && columns && itemsPerPage " class="" [ngClass]="ShowLoading?'ibox-content sk-loading':''">
  <div class="row" style="margin-bottom:15px;" *ngIf="representativesSource">
    <div class="col-md-4">
      <!--<input *ngIf="config.filtering" placeholder="Buscar..."
           [ngTableFiltering]="config.filtering"
           class="form-control"
           (tableChanged)="onChangeTable(config)" />-->
    </div>
    <div class="col-md-8">
      <a class="btn btn-default pull-right" tabindex="0" (click)="ExportarPdf()" aria-controls="DataTables_Table_0" href="javascript:0;"><span>Pdf</span></a>
      <a class="btn btn-default pull-right" tabindex="0" (click)="ExportarExcel()" aria-controls="DataTables_Table_0" href="javascript:0;"><span>Excel</span></a>
    </div>
  </div>
  <div class="row" *ngIf="representativesSource">
    <div class="col-md-12">
      <div class="table-responsive " #TABLE #table>
        <div class="sk-spinner sk-spinner-double-bounce" style="z-index: 100;" *ngIf="ShowLoading">
          <div class="sk-double-bounce1"></div>
          <div class="sk-double-bounce2"></div>
        </div>
        <span style=" position: absolute; top: -20px; right: 13px;" placement="left" tooltip="{{itemsPerPage}} registros por pagina"><strong>Registros: {{GetTotal()}}</strong></span>
        <ng2-smart-table [settings]="settings" *ngIf="representativesSource" [source]="representativesSource"></ng2-smart-table>
        <!--<div class="text-center" *ngIf="!ShowLoading && Filas.length<=0">
          <h4>
            No se encontraron registros
          </h4>
        </div>-->
      </div>
    </div>
    <!--<div class="col-lg-12" >
      <select name="CountPage" class="SelectPaginacion pull-right" (change)="CambiarItemspPorPagina()" [(ngModel)]="itemsPerPage">
        <option value=15>15</option>
        <option value=30>30</option>
        <option value=40>40</option>
        <option value=50>50</option>
        <option value=80>80</option>
        <option value=100>100</option>
      </select>
    </div>-->
  </div>
</div>
