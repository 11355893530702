import { Injectable } from '@angular/core';
import { GetPermissionRequest, GetModulesResponse, GetPermissionResponse, GestionPermissionRequest, GestionPermissionResponse } from './entities/gestion-permisos';
import { HttpService } from '../../http/http.service';
import { GetUserResponse } from '../../../services/security/general/entities/get-user-response';
import { ApisRestGestionPermisos } from '../../../system/apisrest';
import { UtilitiesAnibalService } from '../../utilities-anibal';

import { Observable } from 'rxjs';

@Injectable()
export class GestionPermisosService {

  constructor(private _httpService: HttpService) { }

  public GetDatosUsuario(userName: string): Observable<GetUserResponse> {
    UtilitiesAnibalService.Console("Consultando Usuario");
    return this._httpService.get<GetUserResponse>(ApisRestGestionPermisos.GetUser + "/" + userName);
  }

  public GetModulos(): Observable<GetModulesResponse> {
    UtilitiesAnibalService.Console("Consultando Modulos");
    return this._httpService.get<GetModulesResponse>(ApisRestGestionPermisos.GetModules);
  }

  public GetPermission(_request: GetPermissionRequest): Observable<GetPermissionResponse> {
    UtilitiesAnibalService.Console("Consultando Permisos");
    return this._httpService.post<GetPermissionResponse>(ApisRestGestionPermisos.PostGetPermission, _request);
  }

  public GuardarPermisos(_request: GestionPermissionRequest): Observable<GestionPermissionResponse> {
    UtilitiesAnibalService.Console("Guardando Permisos");
    return this._httpService.post<GestionPermissionResponse>(ApisRestGestionPermisos.PostGestionPermission, _request);
  }

}
