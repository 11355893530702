<!-- Wrapper-->
<div id="wrapper">

  <!-- Left navigation bar -->
  <navigation></navigation>

  <!-- Main page wrapper -->
  <div id="page-wrapper" class="gray-bg">

    <!-- Top navigation -->
    <topnavbar></topnavbar>

    <!-- Main view/routes wrapper-->
    <router-outlet></router-outlet>

    <!-- Footer -->
    <footer></footer>

  </div>
  <!-- End page wrapper-->

</div>
<!-- End wrapper-->
