<nav class="navbar-default navbar-static-side" role="navigation">
  <div class="sidebar-collapse">
    <ul class="nav metismenu" id="side-menu">
      <li class="nav-header">
        <div class="profile-element" dropdown>
          <img alt="image" class="img-circle" src="favicon.ico" width="45" height="45">
          <a dropdownToggle>
            <span class="block m-t-xs"> <strong class="font-bold">{{NombreTercero}}</strong> </span>
            <span class="text-muted text-xs block">{{UserName}} <b class="caret"></b> </span>
          </a>
          <ul class="animated fadeInRight dropdown-menu" *dropdownMenu>
            <li><a href="javascript:0;" (click)="OpenModal(ModalCambiarPassword)">Cambiar Contraseña</a></li>
            <li class="divider"></li>
            <li (click)="Logout()"><a href="#">Cerrar Sesión</a></li>
          </ul>
        </div>
        <div class="logo-element">
          <img alt="image" class="img-circle" height="45" src="favicon.ico" width="45">
        </div>
      </li>
      <!--<accordion [isAnimated]="true" [closeOthers]="true">
        <accordion-group *ngFor="let modulo of Modulos" [ngClass]="{active: activeRoute(modulo.Modulo)}" class="active">
          <button class="btn btn-link btn-block clearfix" accordion-heading type="button">
            <i class="{{modulo.Icono}}"></i> <span class="nav-label">{{modulo.Titulo}}</span> <span class="fa arrow"></span>
          </button>
          <span class="badge badge-secondary center">
            <button *ngFor="let submenu of modulo.SubMenu" [ngClass]="{active: activeRoute(submenu.Modulo)}"><a [routerLink]="[submenu.Url]" (click)="CambiarTitle(submenu)">{{submenu.Titulo}}</a></button>
          </span>
          <span class="nav nav-second-level collapse" [ngClass]="{in: activeRoute(modulo.Modulo)}">
            <button *ngFor="let submenu of modulo.SubMenu" [ngClass]="{active: activeRoute(submenu.Modulo)}"><a [routerLink]="[submenu.Url]" (click)="CambiarTitle(submenu)">{{submenu.Titulo}}</a></button>
          </span>
        </accordion-group>
      </accordion>-->
      <li *ngFor="let modulo of Modulos" [ngClass]="{active: activeRoute(modulo.Modulo)}" id="LiModulo-{{modulo.Modulo}}">
        <a  (click)="AbrirMenu(modulo)"><i class="{{modulo.Icono}}"></i> <span class="nav-label">{{modulo.Titulo}}</span> <span class="fa arrow"></span> </a>
        <ul class="nav nav-second-level collapse" id="UlModulo-{{modulo.Modulo}}" [ngClass]="{in: activeRoute(modulo.Modulo)}">
          <li *ngFor="let submenu of modulo.SubMenu" [ngClass]="{active: activeRoute(submenu.Modulo)}"><a [routerLink]="[submenu.Url]" (click)="CambiarTitle(submenu)">{{submenu.Titulo}}</a></li>
        </ul>
      </li>
    </ul>

  </div>
</nav>
<ng-template #ModalCambiarPassword>
  <div class="modal-header bg-primary">
    <h4 class="modal-title pull-left">Cambiar Contraseña</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <app-change-password [Usuario]="Usuario" [ApiPostChangePassword]="ApiPostChangePasswordForUser"></app-change-password>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default pull-right" (click)="modalRef.hide()">Cerrar</button>
  </div>
</ng-template>

<div class="theme-config-screenshots" (click)="OpenModalCapturaPantalla(ModalSoporteTecnico)">
  <div class="theme-config-box-screenshots">
    <div class="spin-icon-screenshots" tooltip="Soporte técnico." placement="left">
      <i class="fa fa-camera-retro"></i>
    </div>
  </div>
</div>

<ng-template #ModalSoporteTecnico>
  <div class="modal-header bg-primary">
    <h4 class="modal-title pull-left">Soporte técnico</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <app-soporte-tecnico></app-soporte-tecnico>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default pull-right" (click)="modalRef.hide()">Cerrar</button>
  </div>
</ng-template>

<div class="theme-config">
  <div class="theme-config-box">
    <div class="spin-icon" tooltip="Configuración." placement="left">
      <i class="fa fa-cogs fa-spin"></i>
    </div>
    <div class="skin-settings">
      <div class="title">
        Configuración <br>
        <small style="text-transform: none;font-weight: 400">
          Cuadro de configuración diseñado.
        </small>
      </div>
      <div class="setings-item">
        <span>
          Contraer menú
        </span>

        <div class="switch">
          <div class="onoffswitch">
            <input type="checkbox" name="collapsemenu" class="onoffswitch-checkbox" id="collapsemenu">
            <label class="onoffswitch-label" for="collapsemenu">
              <span class="onoffswitch-inner"></span>
              <span class="onoffswitch-switch"></span>
            </label>
          </div>
        </div>
      </div>
      <div class="setings-item">
        <span>
          Barra lateral fija
        </span>

        <div class="switch">
          <div class="onoffswitch">
            <input type="checkbox" name="fixedsidebar" class="onoffswitch-checkbox" id="fixedsidebar">
            <label class="onoffswitch-label" for="fixedsidebar">
              <span class="onoffswitch-inner"></span>
              <span class="onoffswitch-switch"></span>
            </label>
          </div>
        </div>
      </div>
      <div class="setings-item">
        <span>
          Navbar superior
        </span>

        <div class="switch">
          <div class="onoffswitch">
            <input type="checkbox" name="fixednavbar" class="onoffswitch-checkbox" id="fixednavbar">
            <label class="onoffswitch-label" for="fixednavbar">
              <span class="onoffswitch-inner"></span>
              <span class="onoffswitch-switch"></span>
            </label>
          </div>
        </div>
      </div>
      <!--<div class="setings-item">
        <span>
          Top navbar v.2
          <br>
          <small>*Primary layout</small>
        </span>

        <div class="switch">
          <div class="onoffswitch">
            <input type="checkbox" name="fixednavbar2" class="onoffswitch-checkbox" id="fixednavbar2">
            <label class="onoffswitch-label" for="fixednavbar2">
              <span class="onoffswitch-inner"></span>
              <span class="onoffswitch-switch"></span>
            </label>
          </div>
        </div>
      </div>-->
      <div class="setings-item">
        <span>
          Diseño en caja
        </span>

        <div class="switch">
          <div class="onoffswitch">
            <input type="checkbox" name="boxedlayout" class="onoffswitch-checkbox" id="boxedlayout">
            <label class="onoffswitch-label" for="boxedlayout">
              <span class="onoffswitch-inner"></span>
              <span class="onoffswitch-switch"></span>
            </label>
          </div>
        </div>
      </div>
      <!--<div class="setings-item">
        <span>
          Fixed footer
        </span>

        <div class="switch">
          <div class="onoffswitch">
            <input type="checkbox" name="fixedfooter" class="onoffswitch-checkbox" id="fixedfooter">
            <label class="onoffswitch-label" for="fixedfooter">
              <span class="onoffswitch-inner"></span>
              <span class="onoffswitch-switch"></span>
            </label>
          </div>
        </div>
      </div>-->
      <!--<div class="title">Skins</div>
      <div class="setings-item default-skin">
        <span class="skin-name ">
          <a href="#" class="s-skin-0">
            Default
          </a>
        </span>
      </div>
      <div class="setings-item blue-skin">
        <span class="skin-name ">
          <a href="#" class="s-skin-1">
            Blue light
          </a>
        </span>
      </div>
      <div class="setings-item yellow-skin">
        <span class="skin-name ">
          <a href="#" class="s-skin-3">
            Yellow/Purple
          </a>
        </span>
      </div>-->
    </div>
  </div>
</div>
