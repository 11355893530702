import { Component, Input, OnInit } from '@angular/core';
import { InformeAsentamientosFamiliasCaracterizadas } from '../../../../services/sipa/informes/informes.service';
import { from } from "linq-to-typescript"

@Component({
  selector: 'app-chart-caracterizacion',
  templateUrl: './chart-caracterizacion.component.html',
  styleUrls: ['./chart-caracterizacion.component.scss']
})
export class ChartCaracterizacionComponent implements OnInit {


  ngOnInit(): void {
  }

  public multi: any[];
  view: any[] = [1000, 500];

  // options
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  gradient: boolean = true;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  xAxisLabel: string = 'Asentamientos';
  showYAxisLabel: boolean = true;
  yAxisLabel: string = 'Números';
  legendTitle: string = 'Caracterización';


  private _InformeCaracterizacion: Array<InformeAsentamientosFamiliasCaracterizadas> = new Array<InformeAsentamientosFamiliasCaracterizadas>();
  @Input()
  set InformeCaracterizacion(value: Array<InformeAsentamientosFamiliasCaracterizadas>) {
    this._InformeCaracterizacion = value;
    this.ProcesarInforme();
  }

  get InformeCaracterizacion(): Array<InformeAsentamientosFamiliasCaracterizadas> {
    return this._InformeCaracterizacion;
  }

  constructor() {
  }

  private ProcesarInforme(): void {
    this.multi = from<InformeAsentamientosFamiliasCaracterizadas>(this._InformeCaracterizacion)
      .select(
        x => (
          {
            name: x.Nombre,
            series: [
              { name: "Familias", value: x.CantidadFamilias },
              { name: "Gestantes", value: x.Gestantes },
              { name: "Desnutrición", value: x.DesnutridosOEnRiesgo },
              { name: "Capacitaciones", value: x.Capacitaciones },
              { name: "Seguimientos", value: x.Seguimientos }
            ]
          }
        )
      )
      .toArray();
  }

}
