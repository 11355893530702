import { Injectable } from '@angular/core';
import { HttpService } from '../../http/http.service';
import { ApisRestGestionPqr } from '../../../system/apisrest';

import { Observable } from 'rxjs';
import { Pqrs } from '../../../entities/pqr/pqr';
import { UtilitiesAnibalService } from '../../utilities-anibal';

@Injectable()
export class GestionPqrsService {

    constructor(private _httpService: HttpService) { }
    
    public ConsultarPQRS(request: ConsultarPqrsRequest): Observable<ConsultarPqrsResponse> {
        UtilitiesAnibalService.Console("Consultando pqrs sistema");
        return this._httpService.post<ConsultarPqrsResponse>(ApisRestGestionPqr.PostConsultaParametrizada, request);
    }

}

export class ConsultarPqrsRequest {
    public Estado: string;
    public FechaFinal: Date;
    public FechaInicial: Date;
    public IdentificacionSolicitante: string;
    public NombreSolicitante: string;
    public NumeroRadicado: string;
    public TipoPqrs: string;
}

export class ConsultarPqrsResponse {
    public ListaPqrs: Array<PqrsTable>;
}

export class PqrsTable extends Pqrs {
    public FechaPipe: string;
    public Identificacion: string;
    public NombreSolicitante: string;
    public Telefono: number;
    public EstadoBadge: string;
    public TipoPqrBadge: string;
    public Responder: string;
}



