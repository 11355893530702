<div class="row wrapper border-bottom white-bg page-heading">
  <div class="row m-b-lg m-t-lg" style="margin-bottom: 0;">
    <div class="col-md-5">
      <div class="profile-image">
        <img src="assets/images/application/icon-familia-indigena.png" class="rounded-circle circle-border m-b-md" alt="profile">
      </div>
      <div class="profile-info">
        <div class="">
          <div>
            <h2 class="no-margins">
              FAMILIA NÚMERO: <strong class="colorPrimary">{{FamiliaIndigena.Numero}}</strong>
            </h2>
            <h5>Id: {{FamiliaIndigena.Id}}</h5>
            <p style="text-align:justify;">
              <small>
                Estimado usuario recuerde que aquí podra consultar toda la información actualizada de la familia número <strong>{{FamiliaIndigena.Numero}}</strong>, ademas podra registrar cualquier caracteristica que sea necesaria para tener actualizada el historial de salud de la familia en cuestión, tenga en cuenta que todas las acciones que realice estan auditadas y podran ser rastreadas en cualquier momento, por tal motivo recuerde que la información diligenciada aquí debe ser totalmente clara, precisa y fidedigna.
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-7">
      <table class="table small m-b-xs">
        <tbody>
          <tr>
            <td>
              Municipio: <strong>{{FamiliaIndigena.Municipio | uppercase}}</strong>
            </td>
            <td>
              Asentamiento: <strong>{{FamiliaIndigena.Asentamiento | uppercase}}</strong>
            </td>
          </tr>
          <tr>
            <td>
              Sector: <strong>{{FamiliaIndigena.Sector | uppercase}}</strong>
            </td>
            <td>
              Fecha registro: <strong>{{FamiliaIndigena.Fecha | date}}</strong>
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <h3>Observación</h3>
        <div class="well">
          {{FamiliaIndigena.Observacion}}
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div [ngClass]="IsCollapsedNovedades?'col-lg-12':'col-lg-9'">
    <div class="wrapper wrapper-content animated fadeInUp">
      <div class="ibox">
        <div class="ibox-content">
          <div class="row">
            <div class="col-lg-12">
              <div class="m-b-md">
                <h2>HISTORIAL DE SALUD FAMILIAR</h2>
              </div>
            </div>
          </div>
          <div class="row m-t-sm">
            <div class="col-lg-12">
              <div class="panel blank-panel">
                <div class="panel-heading">
                  <div class="panel-options">
                    <ul class="nav nav-tabs">
                      <li>
                        <a class="nav-link" [ngClass]="TabActivo=='Identificacion'?'active show':''" (click)="CambiarTab('Identificacion')" href="javascript:void(0);" data-toggle="tab">
                          <i class="fa fa-group (alias)"></i> Identificación <span class="fa fa-circle-o-notch fa-spin text-navy" *ngIf="NotificacionGestionIntegrantes.EstaConsultando"></span> <span class="badge badge-info" *ngIf="!NotificacionGestionIntegrantes.EstaConsultando"> {{NotificacionGestionIntegrantes.CantidadIntegrantes}}</span>
                        </a>
                      </li>
                      <li>
                        <a class="nav-link" [ngClass]="TabActivo=='DiagnosticoFamiliar'?'active show':''" (click)="CambiarTab('DiagnosticoFamiliar')" href="javascript:void(0);" data-toggle="tab"><i class="fa fa-chain-broken"></i> Diagnóstico familiar</a>
                      </li>
                      <li><a class="nav-link" [ngClass]="TabActivo=='NutricionYCapacitacion'?'active show':''" (click)="CambiarTab('NutricionYCapacitacion')" href="javascript:void(0);" data-toggle="tab"><i class="fa fa-user-md"></i> Nutrición y capacitación</a></li>
                      <li>
                        <a class="nav-link" [ngClass]="TabActivo=='Seguimiento'?'active show':''" (click)="CambiarTab('Seguimiento')" href="javascript:void(0);" data-toggle="tab">
                          <i class="fa fa-calendar"></i> Seguimiento <span class="fa fa-circle-o-notch fa-spin text-navy" *ngIf="NotificacionGestionSeguimiento.EstaConsultando"></span> <span class="badge badge-info" *ngIf="!NotificacionGestionSeguimiento.EstaConsultando"> {{NotificacionGestionSeguimiento.CantidadSeguimientos}}</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="panel-body">
                  <div class="tab-content">
                    <div class="tab-pane" [ngClass]="TabActivo=='Identificacion'?'active show':''">
                      <app-gestionintegrantes [FamiliaIndigena]="FamiliaIndigena" (NotificacionComponente)="EventNotificacionGestionIntegrantes($event)"></app-gestionintegrantes>
                    </div>
                    <div class="tab-pane" [ngClass]="TabActivo=='DiagnosticoFamiliar'?'active show':''">
                      <div>
                        <tabset>
                          <tab>
                            <ng-template tabHeading>
                              <i class="fa fa-ticket"></i> Clasificación familiar <span class="fa fa-circle-o-notch fa-spin text-navy" *ngIf="NotificacionGestionClasificacionFamiliar.EstaConsultando"></span>
                            </ng-template>
                            <app-clasificacionfamiliar [FamiliaIndigena]="FamiliaIndigena" (NotificacionComponente)="EventNotificacionGestionClasificacionFamiliar($event)"></app-clasificacionfamiliar>
                          </tab>
                          <tab>
                            <ng-template tabHeading>
                              <i class="fa fa-home"></i> Apgar <span class="fa fa-circle-o-notch fa-spin text-navy" *ngIf="NotificacionGestionApgar.EstaConsultando"></span>
                            </ng-template>
                            <app-apgar [FamiliaIndigena]="FamiliaIndigena" (NotificacionComponente)="EventNotificacionGestionApgar($event)"></app-apgar>
                          </tab>
                          <tab>
                            <ng-template tabHeading>
                              <i class="fa fa-yelp"></i> Ecomapa <span class="fa fa-circle-o-notch fa-spin text-navy" *ngIf="NotificacionGestionEcomapa.EstaConsultando"></span>
                              <i class="fa fa-warning (alias) text-warning" *ngIf="NotificacionGestionEcomapa.NoHaRegistrado"></i>
                            </ng-template>
                            <app-ecomapa [FamiliaIndigena]="FamiliaIndigena" (NotificacionComponente)="EventNotificacionGestionEcomapa($event)"></app-ecomapa>
                          </tab>
                          <tab>
                            <ng-template tabHeading>
                              <i class="fa fa-cab (alias)"></i> Remisiones <span class="fa fa-circle-o-notch fa-spin text-navy" *ngIf="NotificacionGestionRemisiones.EstaConsultando"></span> <span class="badge badge-info" *ngIf="!NotificacionGestionRemisiones.EstaConsultando"> {{NotificacionGestionRemisiones.CantidadRemisiones}}</span>
                            </ng-template>
                            <app-remisiones [FamiliaIndigena]="FamiliaIndigena" (NotificacionComponente)="EventNotificacionGestionRemisiones($event)"></app-remisiones>
                          </tab>
                          <tab>
                            <ng-template tabHeading>
                              <i class="fa fa-child"></i> Factores protectores <span class="fa fa-circle-o-notch fa-spin text-navy" *ngIf="NotificacionGestionFactorProtector.EstaConsultando"></span>
                              <i class="fa fa-warning (alias) text-warning" *ngIf="NotificacionGestionFactorProtector.NoHaRegistrado"></i>
                            </ng-template>
                            <app-factorprotector [FamiliaIndigena]="FamiliaIndigena" (NotificacionComponente)="EventNotificacionGestionFactorProtector($event)"></app-factorprotector>
                          </tab>
                          <tab>
                            <ng-template tabHeading>
                              <i class="fa fa-bomb"></i> Procesos de riesgos <span class="fa fa-circle-o-notch fa-spin text-navy" *ngIf="NotificacionGestionProcesoRiesgo.EstaConsultando"></span>
                              <i class="fa fa-warning (alias) text-warning" *ngIf="NotificacionGestionProcesoRiesgo.NoHaRegistrado"></i>
                            </ng-template>
                            <app-procesoriesgo [FamiliaIndigena]="FamiliaIndigena" (NotificacionComponente)="EventNotificacionGestionProcesoRiesgo($event)"></app-procesoriesgo>
                          </tab>
                        </tabset>
                      </div>
                    </div>
                    <div class="tab-pane" [ngClass]="TabActivo=='NutricionYCapacitacion'?'active show':''">
                      <div>
                        <tabset>
                          <tab>
                            <ng-template tabHeading>
                              <i class="fa fa-pagelines"></i> Nutriciones <span class="fa fa-circle-o-notch fa-spin text-navy" *ngIf="NotificacionGestionNutricion.EstaConsultando"></span> <span class="badge badge-info" *ngIf="!NotificacionGestionNutricion.EstaConsultando"> {{NotificacionGestionNutricion.CantidadNutriciones}}</span>
                            </ng-template>
                            <app-nutricion [FamiliaIndigena]="FamiliaIndigena" (NotificacionComponente)="EventNotificacionGestionNutricion($event)"></app-nutricion>
                          </tab>
                          <tab>
                            <ng-template tabHeading>
                              <i class="fa fa-slideshare"></i> Capacitaciones <span class="fa fa-circle-o-notch fa-spin text-navy" *ngIf="NotificacionGestionCapacitacion.EstaConsultando"></span> <span class="badge badge-info" *ngIf="!NotificacionGestionCapacitacion.EstaConsultando"> {{NotificacionGestionCapacitacion.CantidadCapacitaciones}}</span>
                            </ng-template>
                            <app-capacitacion [FamiliaIndigena]="FamiliaIndigena" (NotificacionComponente)="EventNotificacionGestionCapacitacion($event)"></app-capacitacion>
                          </tab>
                        </tabset>
                      </div>
                    </div>
                    <div class="tab-pane" [ngClass]="TabActivo=='Seguimiento'?'active show':''">
                      <div class="row">
                        <div class="col-lg-12">
                          <div>
                            <app-gestionseguimientos [FamiliaIndigena]="FamiliaIndigena" (NotificacionComponente)="EventNotificacionGestionSeguimiento($event)"></app-gestionseguimientos>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-3" style="padding: 0;position: absolute;right: 0;">
    <div style=" z-index: 10000;" tooltip="Novedades." placement="left" class=" navy-bg" [ngClass]="IsCollapsedNovedades?'spin-icon-novedades-right':'spin-icon-novedades-left'" (click)="IsCollapsedNovedades = !IsCollapsedNovedades" [attr.aria-expanded]="!IsCollapsedNovedades" aria-controls="collapseBasic">
      <i class="fa " [ngClass]="IsCollapsedNovedades?'fa-angle-double-left':'fa-angle-double-right'" style="font-size: 20px;margin-left: 3px;"></i>
    </div>
    <div class="wrapper wrapper-content project-manager" id="collapseBasic" [collapse]="IsCollapsedNovedades">
      <div class="AltoLineaTiempo">
        <h2 class="TituloNovedades">
          Novedades
        </h2>
        <div id="vertical-timeline" class="vertical-container light-timeline no-margins">
          <div class="vertical-timeline-block" *ngFor="let novedad of FamiliaIndigena.Novedades">
            <div class="vertical-timeline-icon" [ngClass]="novedad.Importancia=='Importante'?'red-bg':novedad.Importancia=='Relevante'?'navy-bg':' blue-bg'">
              <i class="fa " [ngClass]="novedad.Importancia=='Importante'?'fa-star':novedad.Importancia=='Relevante'?'fa-star-half-empty (alias)':' fa-star-o'"></i>
            </div>
            <div class="vertical-timeline-content">
              <h2>{{novedad.Tipo}}</h2>
              <p>
                <small>{{novedad.Fecha | date}}</small>
              </p>
              <p>
                {{novedad.Observacion}}
              </p>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
