<!-- Wrapper-->
<div id="wrapper">

  <!-- Main page wrapper -->
  <div id="page-wrapper" class="gray-bg">

    <!-- Top navigation -->
    <topnavigationnavbar></topnavigationnavbar>

    <!-- Main view/routes wrapper-->
    <router-outlet></router-outlet>

    <!-- Footer -->
    <footer></footer>

  </div>
  <!-- End page wrapper-->

</div>
<!-- End wrapper-->
