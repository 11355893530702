import { Injectable } from '@angular/core';
import { HttpService } from '../../http/http.service';
import { ApisRestGestionParametros } from '../../../system/apisrest';

import { Observable } from 'rxjs';
import { ParametroBasic, Parametro } from '../../../entities/configuracion/parametro';
import { EnumerationObject } from '../../../entities/general/enumeration-object';
import { UtilitiesAnibalService } from '../../utilities-anibal';

@Injectable()
export class GestionParametrosService {

  constructor(private _httpService: HttpService) { }

  public ConsultarDatosFormulario(): Observable<ConsultarDatosFormularioGestionParametrosResponse> {
    UtilitiesAnibalService.Console("Consultando datos del formulario");
    return this._httpService.get<ConsultarDatosFormularioGestionParametrosResponse>(ApisRestGestionParametros.GetDatosFormulario);
  }

  public ConsultarParametrosRegistrados(request: ConsultaParametrizadaParametrosRequest): Observable<ConsultaParametrizadaParametrosResponse> {
    UtilitiesAnibalService.Console("Consultando parametros registrados sistema");
    return this._httpService.post<ConsultaParametrizadaParametrosResponse>(ApisRestGestionParametros.PostConsultaParametrizada, request);
  }

  public RegistrarParametro(request: ParametroBasic): Observable<RegistrarParametroResponse> {
    UtilitiesAnibalService.Console("registrando parametro");
    return this._httpService.post<RegistrarParametroResponse>(ApisRestGestionParametros.PostRegistrarParametro, request);
  }

  public ConsultarDetallesValidosFromParametro(abreviatura: string): Observable<ConsultarDetallesValidosFromParametroResponse> {
    UtilitiesAnibalService.Console("Consultar detalles validos del parametro " + abreviatura);
    return this._httpService.get<ConsultarDetallesValidosFromParametroResponse>(ApisRestGestionParametros.GetConsultarDetallesFromParametro(abreviatura));
  }

  public ModificarParametro(request: ModificarParametroRequest): Observable<ModificarParametroResponse> {
    UtilitiesAnibalService.Console("Modificando parametro");
    return this._httpService.post<ModificarParametroResponse>(ApisRestGestionParametros.PostModificarParametro, request);
  }

}

export class ModificarParametroResponse {
  public Mensaje: string;
}

export class ModificarParametroRequest {
  public ParametroId: number;
  public Nombre: string;
  public Descripcion: string;
  public Estado: string;
  public Detalles: Array<DetalleParametroModificacionRequest>;
}

export class DetalleParametroModificacionRequest {
  public Id: any;
  public Nombre: string;
  public Abreviatura: string;
  public Valor: string;
  public TipoDato: string;
  public Descripcion: string;
  public FechaInicial: any;
  public FechaFinal: any;
  public Estado: string;
}

export class ConsultarDetallesValidosFromParametroResponse {
  public DetallesValidos: Array<DetallesValidosParametro>;
  public Parametro: ModificarParametroRequest;
}

export class DetallesValidosParametro {
  public Nombre: string;
  public Abreviatura: string;
  public Valor: string;
  public TipoDato: string;
  public Descripcion: string;
  public Estado: string;
}

export class ConsultarDatosFormularioGestionParametrosResponse {
  public Modulos: Array<EnumerationObject>;
  public TiposDatos: Array<EnumerationObject>;
  public Estados: Array<EnumerationObject>;
  public ParametrosNoRegistrados: Array<ParametroBasic>;
}

export class ConsultaParametrizadaParametrosRequest {
  public Modulo: string;
}

export class ConsultaParametrizadaParametrosResponse {
  public ListaParametros: Array<ParametroTable>;
}

export class ParametroTable extends ParametroBasic {
  public EstadoBadge: string;
  public BotonEditarParametro: string;
  public CantidadDetallesBadge: string;
}

export class RegistrarParametroResponse {
  public Mensaje: string;
}
