import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RegistrousuarioComponent } from './registrousuario/registrousuario.component';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { GestionPermisosComponent } from './registrousuario/components/gestion-permisos/gestion-permisos.component';
import { ForzeEmailConfirmComponent } from './registrousuario/components/forze-email-confirm/forze-email-confirm.component';
import { ModificarUsuarioComponent } from './registrousuario/components/modificar-usuario/modificar-usuario.component';

import { AnibalModulesModule } from '../anibalmodules/anibalmodules.module';
import { AsignarPaginaInicioComponent } from './registrousuario/components/asignar-pagina-inicio/asignar-pagina-inicio.component';
import { AuditoriaComponent } from './auditoria/auditoria.component';
import { ChangePasswordAdminComponent } from './registrousuario/components/change-password-admin/change-password-admin.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HealthapplicationComponent } from './healthapplication/healthapplication.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AnibalModulesModule,
        PaginationModule.forRoot(),
        BrowserAnimationsModule,
        TooltipModule.forRoot(),
        TabsModule.forRoot(),
        ModalModule.forRoot(),
        BsDatepickerModule.forRoot()
    ],
    declarations: [RegistrousuarioComponent, GestionPermisosComponent, ForzeEmailConfirmComponent, ModificarUsuarioComponent, AsignarPaginaInicioComponent, AuditoriaComponent, ChangePasswordAdminComponent, HealthapplicationComponent]//, DataTableGenericComponent]
})
export class SecurityModule { }
