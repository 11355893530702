import { Component, OnInit, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FamiliaIndigena, FamiliaIndigenaProcesoRiesgoCuestionario } from '../../../../../entities/sipa/familiaindigena';
import { UtilitiesAnibalService } from '../../../../../services/utilities-anibal';
import { EventEmitter } from '@angular/core';
import { ProcesoRiesgoFamiliaIndigenaService, GestionProcesoRiesgoRequest } from '../../../../../services/sipa/caracterizacion/diagnosticofamiliar/proceso-riesgo-familia-indigena.service';

@Component({
  selector: 'app-procesoriesgo',
  templateUrl: './procesoriesgo.component.html',
  styleUrls: ['./procesoriesgo.component.css'],
  providers: [ToastrService, ProcesoRiesgoFamiliaIndigenaService]
})
export class ProcesoriesgoComponent implements OnInit {

  constructor(
    private _toastr: ToastrService,
    private _ProcesoRiesgoFamiliaIndigenaService: ProcesoRiesgoFamiliaIndigenaService,
  ) { }

  ngOnInit() {
  }

  @Output() public NotificacionComponente: EventEmitter<GestionProcesoRiesgoComponentEventResponse> = new EventEmitter();
  private ObjectNotificacion: GestionProcesoRiesgoComponentEventResponse = new GestionProcesoRiesgoComponentEventResponse();

  private _FamiliaIndigena: FamiliaIndigena;
  @Input()
  set FamiliaIndigena(familia: FamiliaIndigena) {
    this._FamiliaIndigena = familia;
    if (familia && familia.Id) {
      this.RequestGestion.FamiliaIndigenaId = familia.Id;
      this.ConsultarDatosFormulario();
    }
  }

  get FamiliaIndigena(): FamiliaIndigena {
    return this._FamiliaIndigena;
  }

  public RequestGestion: GestionProcesoRiesgoRequest = new GestionProcesoRiesgoRequest();

  public Guardar(): void {
    UtilitiesAnibalService.ShowLoading();
    for (let ecomapa of this.RequestGestion.ProcesosRiesgos) {
      for (let cuestionario of ecomapa.Cuestionarios) {
        this.ValidarSiTieneObservacionAndRespuestaDebeSerTrue(cuestionario);
      }
    }
    this._ProcesoRiesgoFamiliaIndigenaService.GestionProcesoRiesgo(this.RequestGestion).subscribe(response => {
      UtilitiesAnibalService.HideLoading();
      this.ConsultarDatosFormulario();
      this._toastr.success(response.Mensaje, "Correcto!");
    }, err => {
      UtilitiesAnibalService.HideLoading();
    });
  }

  public ConsultarDatosFormulario(): void {
    this.ObjectNotificacion.EstaConsultando = true;
    this.NotificacionComponente.emit(this.ObjectNotificacion);
    this._ProcesoRiesgoFamiliaIndigenaService.GetConsultarDatosFormulario(this._FamiliaIndigena.Id).subscribe(response => {
      this.ObjectNotificacion.EstaConsultando = false;
      this.RequestGestion.ProcesosRiesgos = response.ProcesosRiesgos;
      this.ObjectNotificacion.NoHaRegistrado = this.RequestGestion.ProcesosRiesgos.some(function (e) { return e.Id == 0; })
      this.NotificacionComponente.emit(this.ObjectNotificacion);
    }, err => {
    });
  }

  public ValidarSiTieneObservacionAndRespuestaDebeSerTrue(cuestionario: FamiliaIndigenaProcesoRiesgoCuestionario) {
    if (cuestionario.SiEsTrueDebeEscribirObservacion) {
      if (cuestionario.Observacion) {
        cuestionario.Respuesta = true;
      } else {
        cuestionario.Respuesta = false;
      }
    }
  }
}

export class GestionProcesoRiesgoComponentEventResponse {
  public EstaConsultando: boolean;
  public NoHaRegistrado: boolean;
}
