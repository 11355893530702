import { Injectable } from '@angular/core';
import { FamiliaIndigena, FamiliaIndigenaNovedad } from '../../../entities/sipa/familiaindigena';
import { UtilitiesAnibalService } from '../../utilities-anibal';
import { ApisRestGestionFamiliasIndigenas, ApisRestCaracterizacionFamiliaIndigena } from '../../../system/apisrest';
import { Observable } from 'rxjs';
import { HttpService } from '../../http/http.service';

@Injectable()
export class CaracterizacionFamiliaIndigenaService {

  constructor(private _httpService: HttpService) { }

  public ConsultarFamiliaIndigenaPorNumero(numeroFamilia: string): Observable<ConsultaFamiliaIndigenaParaCaracterizacionResponse> {
    UtilitiesAnibalService.Console("Consultando familia indigena número " + numeroFamilia);
    return this._httpService.get<ConsultaFamiliaIndigenaParaCaracterizacionResponse>(ApisRestCaracterizacionFamiliaIndigena.GetConsultaFamiliaIndigena(numeroFamilia));
  }

}

export class ConsultaFamiliaIndigenaParaCaracterizacionResponse {
  public FamiliaIndigena: FamiliaIndigenaParaCaracterizacion;
}

export class FamiliaIndigenaParaCaracterizacion extends FamiliaIndigena {
  public Novedades: Array<FamiliaIndigenaNovedad> = new Array<FamiliaIndigenaNovedad>();
}
