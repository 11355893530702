<div class="" [ngClass]="ShowLoading?'sk-loading ZoonProgress':''">
    <div class="spiner-example">
        <div class="sk-spinner sk-spinner-cube-grid">
            <div class="sk-cube"></div>
            <div class="sk-cube"></div>
            <div class="sk-cube"></div>
            <div class="sk-cube"></div>
            <div class="sk-cube"></div>
            <div class="sk-cube"></div>
            <div class="sk-cube"></div>
            <div class="sk-cube"></div>
            <div class="sk-cube"></div>
        </div>
    </div>
</div>

<iframe (load)="CargoIframe()" id="IframeIndex" src="" style="position:fixed; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:2000;"></iframe>

<div id="small-chat" class="ng-scope" style="z-index:2010" (click)="OpenModal(ModalRegistroUsuario)">

    <span class="badge badge-warning pull-right" style="margin-top: 13px;margin-right: 45px;">Petición, queja, reclamo o solicitud</span>
    <a class="open-small-chat">
        <i class="fa fa-comments"></i>

    </a>
</div>
<!--No borrar este boton si no no abre le modal, son cosas como del diablo-->
<button class="btn btn-primary " style="opacity: 0.04;" (click)="OpenModal(ModalRegistroUsuario)" type="button"><i class="fa fa-paste"></i>&nbsp;Nuevo</button>

<ng-template #ModalRegistroUsuario style="z-index:999999">
    <div class="modal-header">
        <h4 class="modal-title pull-left">Registro de P.Q.R.S</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <app-pqrs></app-pqrs>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" (click)="modalRef.hide()">Cerrar</button>
    </div>
</ng-template>

<div id="small-chat" class="ng-scope" style="z-index:2010;bottom: 61px;" (click)="OpenModalConsultaPqrs(ModalConsultaPqrs)">
    <span class="badge badge-warning pull-right" style="margin-top: 13px;margin-right: 45px;">Consultar estado P.Q.R.S</span>
    <a class="open-small-chat">
        <i class="fa fa-search"></i>
    </a>
</div>

<!--No borrar este boton si no no abre le modal, son cosas como del diablo-->
<button class="btn btn-primary " style="opacity: 0.04;" (click)="OpenModalConsultaPqrs(ModalConsultaPqrs)" type="button"><i class="fa fa-paste"></i>&nbsp;Consulta</button>

<ng-template #ModalConsultaPqrs style="z-index:999999">
    <div class="modal-header">
        <h4 class="modal-title pull-left">Consulta de P.Q.R.S</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <app-consultapqrs></app-consultapqrs>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" (click)="modalRef.hide()">Cerrar</button>
    </div>
</ng-template>


<div class="theme-config" style="z-index: 999997;">
    <div class="theme-config-box" style="width:415px;">
        <div class="spin-icon">
            <i class="fa fa-key fa-spin"></i>
        </div>
        <div class="skin-settings" style="width:100%;background: #f3f3f3cf;">
            <div class="setings-item" style="padding-right: 70px;">
                <login></login>
            </div>
        </div>
    </div>
</div>
