import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// RECOMMENDED

import { AnibalModulesModule } from '../anibalmodules/anibalmodules.module';

import { GestionfamiliaindigenaComponent } from './gestionfamiliaindigena/gestionfamiliaindigena.component';
import { CaracterizacionComponent } from './caracterizacion/caracterizacion.component';
import { GestionintegrantesComponent } from './caracterizacion/identificacion/gestionintegrantes/gestionintegrantes.component';
import { ClasificacionfamiliarComponent } from './caracterizacion/diagnosticofamiliar/clasificacionfamiliar/clasificacionfamiliar.component';
import { ApgarComponent } from './caracterizacion/diagnosticofamiliar/apgar/apgar.component';
import { EcomapaComponent } from './caracterizacion/diagnosticofamiliar/ecomapa/ecomapa.component';
import { FactorprotectorComponent } from './caracterizacion/diagnosticofamiliar/factorprotector/factorprotector.component';
import { RemisionesComponent } from './caracterizacion/diagnosticofamiliar/remisiones/remisiones.component';
import { ProcesoriesgoComponent } from './caracterizacion/diagnosticofamiliar/procesoriesgo/procesoriesgo.component';
import { NutricionComponent } from './caracterizacion/nutricioncapacitacion/nutricion/nutricion.component';
import { CapacitacionComponent } from './caracterizacion/nutricioncapacitacion/capacitacion/capacitacion.component';
import { GestionseguimientosComponent } from './caracterizacion/seguimiento/gestionseguimientos/gestionseguimientos.component';
import { InformesComponent } from './informes/informes.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartCaracterizacionComponent } from './informes/chart-caracterizacion/chart-caracterizacion.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    AnibalModulesModule,
    NgxChartsModule,
    BrowserAnimationsModule,
    TooltipModule.forRoot(),
    CollapseModule.forRoot(),
    TabsModule.forRoot(),
    PaginationModule.forRoot(),
    ModalModule.forRoot(),
    BsDatepickerModule.forRoot()
  ],
  declarations: [GestionfamiliaindigenaComponent, CaracterizacionComponent, GestionintegrantesComponent, ClasificacionfamiliarComponent, ApgarComponent, EcomapaComponent, FactorprotectorComponent, RemisionesComponent, ProcesoriesgoComponent, NutricionComponent, CapacitacionComponent, GestionseguimientosComponent, InformesComponent, ChartCaracterizacionComponent]
})
export class SipaModule { }
