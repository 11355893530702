import { Routes } from "@angular/router";

import { NgxPermissionsGuard } from 'ngx-permissions';
import { BasicLayoutComponent } from "../components/common/layouts/basicLayout.component";

import { GestionpqrsComponent } from '../views/pqrs/gestionpqrs/gestionpqrs.component';
import { ResponderpqrsComponent } from '../views/pqrs/responderpqrs/responderpqrs.component';

export const PQRS: any = {
    path: 'Pqrs', component: BasicLayoutComponent,
    children: [
        {
            path: 'Gestion', component: GestionpqrsComponent, canActivate: [NgxPermissionsGuard],
            data: {
                permissions: {
                  only: ['Pqrs.GestionPqrs'],
                    redirectTo: 'Home'
                }
            }
        },
        {
            path: 'Responder/:pqrsId', component: ResponderpqrsComponent, canActivate: [NgxPermissionsGuard],
            data: {
                permissions: {
                  only: ['Pqrs.ResponderPqrs'],
                    redirectTo: 'PQRS/Gestion'
                }
            }
        },
    ]
};
