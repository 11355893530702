import { Component, OnInit, Input, Output, ViewChild, EventEmitter, SimpleChanges } from '@angular/core';
import { User } from '../../../../../entities/security/user';
import { ChangePasswordService } from '../../../../../services/security/changepassword/change-password.service';
import { Modulos, Permission } from '../../../../../entities/security/modulos';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-password-admin',
  templateUrl: './change-password-admin.component.html',
  styleUrls: ['./change-password-admin.component.css'],
  providers: [ToastrService, ChangePasswordService]
})
export class ChangePasswordAdminComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private _toastr: ToastrService,
    private _changePasswordService: ChangePasswordService
  ) { }

  public tableChanged: EventEmitter<any> = new EventEmitter();

  public UserName: User;

  public ApiPostChangePasswordForAdmin: string;

  ngOnInit() {
    this._changePasswordService.GetDatosUsuario(this.route.snapshot.paramMap.get('userName'))
            .subscribe(response => {
                if (!response.Error) {
                  this.UserName = response.Usuario;
                } else {
                    this._toastr.error(response.Mensaje, "Error!!");
                }
      });

    this.ApiPostChangePasswordForAdmin = this._changePasswordService.GetApiPostChangePasswordForAdmin();
  }

}
