import { Injectable } from '@angular/core';
import { ConsultaUserAndTerceroResponse, ModificarUsuarioAndTerceroRequest, ModificarUsuarioAndTerceroResponse } from './entities/modificar-usuario';
import { HttpService } from '../../http/http.service';
import { UtilitiesAnibalService } from '../../utilities-anibal';
import { ApisRestModificarUserAndTercero } from '../../../system/apisrest';

import { Observable } from 'rxjs';

@Injectable()
export class ModificarUsuarioService {

  constructor(private _httpService: HttpService) { }

  public ConsultarTerceroyUsuario(TerceroId: string): Observable<ConsultaUserAndTerceroResponse> {
    UtilitiesAnibalService.Console("Consultando tercero and usuario");
    return this._httpService.get<ConsultaUserAndTerceroResponse>(ApisRestModificarUserAndTercero.GetTerceroAndUser + "/" + TerceroId);
  }

  public ModificarTercero(Request: ModificarUsuarioAndTerceroRequest): Observable<ModificarUsuarioAndTerceroResponse> {
    UtilitiesAnibalService.Console("Modificando tercero");
    return this._httpService.post<ModificarUsuarioAndTerceroResponse>(ApisRestModificarUserAndTercero.PostModificarTercero, Request);
  }
}
