<div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5 >Consulta de auditoria</h5>
                </div>
                <div class="ibox-content ">
                    <div class="row">
                        <div class="col-lg-2 col-sm-12 col-md-2">
                            <div class="form-group">
                                <label>Modúlo</label>
                                <select class="form-control" [(ngModel)]="Request.Modulo" name="Modulo">
                                    <option value="">Todos</option>
                                    <option *ngFor="let modulo of Modulos" [value]="modulo">
                                        {{modulo}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-12 col-md-2">
                            <div class="form-group">
                                <label>Acciones</label>
                                <select class="form-control"  [(ngModel)]="Request.Interactor" name="Acciones">
                                    <option value="">Todas</option>
                                    <option *ngFor="let accion of Acciones" [value]="accion">
                                        {{accion}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-12 col-md-2">
                            <div class="form-group">
                                <label>Fecha</label>
                                <input class="form-control" #drp="bsDaterangepicker" bsDaterangepicker [(ngModel)]="RangoFecha"  [bsConfig]="{dateInputFormat: 'YYYY-MM-DD' , containerClass: 'theme-blue' }">
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-12 col-md-2">
                            <div class="form-group">
                                <label>Registro Id</label>
                                <input type="text" [(ngModel)]="Request.Id" name="RegistroId" required placeholder="Registro Id" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-12 col-md-2">
                            <div class="form-group">
                                <label>Tablas</label>
                                <select class="form-control" [(ngModel)]="Request.Tabla" name="Tablas">
                                    <option value="">Todas</option>
                                    <option *ngFor="let tabla of Tablas" [value]="tabla">
                                        {{tabla}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-12 col-md-2">
                            <div class="form-group">
                                <label>Usuario</label>
                                <input type="text" [(ngModel)]="Request.Usuario" name="Usuario" required placeholder="Usuario" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-12 col-md-2">
                            <button class="btn btn-primary " (click)="ConsultarAuditoria()" type="button"><i class="fa fa-search"></i>&nbsp;Consultar</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-sm-12 col-md-12">
                            <app-data-table-generic (CellClicked)="ClickFila($event)" [Filas]="Auditorias" [ShowLoading]="CargandoTablaAuditoria" [columns]="Columnas"></app-data-table-generic>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <ng-template #ModalVerDetalles>
        <div class="modal-header bg-primary">
            <h4 class="modal-title pull-left">Detalles del objeto {{DetalleAuditoria.NombreTabla}} - {{DetalleAuditoria.RegistroId}}</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
            <div class="modal-body">
                <div class="hr-line-dashed"></div>
                <div class="row">
                    <div class="row">
                        <div class="col-lg-3 col-md-4 col-sm-4 col-xs-6" *ngFor="let key of objectKeys(DetalleAuditoria.Detalles)">
                            <div>
                                <b>{{key}}</b><br />
                                <span>{{DetalleAuditoria.Detalles[key]}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default pull-right" (click)="modalRef.hide()">Cerrar</button>
            </div>
    </ng-template>
</div>
