import { Component, OnInit, Input, Output, ViewChild, EventEmitter, SimpleChanges } from '@angular/core';
import { User } from '../../../../../entities/security/user';
import { Tercero } from '../../../../../entities/general/tercero';
import { AsignarPaginaInicioService } from '../../../../../services/security/asignarpaginainicio/asignar-pagina-inicio.service';
import { ConsultaUserTerceroAndPermissionResponse, AsignarPaginaInicialRequest } from '../../../../../services/security/asignarpaginainicio/entities/asignar-pagina-inicio';
import { Permission } from '../../../../../entities/security/modulos';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-asignar-pagina-inicio',
  templateUrl: './asignar-pagina-inicio.component.html',
  styleUrls: ['./asignar-pagina-inicio.component.css'],
  providers: [ToastrService, AsignarPaginaInicioService]
})
export class AsignarPaginaInicioComponent implements OnInit {

  public Usuario: User;
  public Tercero: Tercero;
  public Permisos: Array<Permission>;
  public PermisoInicial: string = "";

  public disabledButton: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private _toastr: ToastrService,
    private _asignarPaginaInicioService: AsignarPaginaInicioService
  ) { }

  ngOnInit() {
    this._asignarPaginaInicioService.ConsultarTerceroyPermisos(this.route.snapshot.paramMap.get('terceroId'))
      .subscribe(response => {
        if (!response.Error) {
          this.Tercero = response.Tercero;
          this.Usuario = response.Usuario;
          this.Permisos = response.Permisos;
          if (this.Permisos.length <= 0) {
            this._toastr.warning("El usuario " + this.Tercero.NombreCompleto + " No posee permisos asociados", "Asvertencia!!");
            window.history.back();
          }
          if (response.PermisoInicial) {
            this.PermisoInicial = response.PermisoInicial.Rol;
          }
        } else {
          this._toastr.error(response.Mensaje, "Error!!");
        }
      });
  }

  public GuardarPermiso(): void {
    this.disabledButton = true;
    var request: AsignarPaginaInicialRequest = { Permiso: this.PermisoInicial, Usuario: this.Usuario.UserName };
    this._asignarPaginaInicioService.RegistrarPermisoInicial(request).subscribe(response => {
      this.disabledButton = false;
      window.history.back();
      this._toastr.success(response.Mensaje, "Correcto!");
    }, err => {
      this.disabledButton = false;
    });
  }
}
