<ngx-charts-bar-vertical-2d [view]="view"
                            [scheme]="'vivid'"
                            [results]="multi"
                            [xAxis]="showXAxis"
                            [yAxis]="showYAxis"
                            [legend]="showLegend"
                            [showXAxisLabel]="showXAxisLabel"
                            [showYAxisLabel]="showYAxisLabel"
                            [xAxisLabel]="xAxisLabel"
                            [yAxisLabel]="yAxisLabel"
                            [legendTitle]="legendTitle">
</ngx-charts-bar-vertical-2d>
