<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <button class="btn btn-primary " (click)="OpenModal(ModalRegistroNoticia)" type="button"><i class="fa fa-paste"></i>&nbsp;Nuevo</button>
          <span class="label label-primary pull-right MarginLabel">Noticias</span>
        </div>
        <div class="ibox-content ">
          <div class="row">
            <div class="col-lg-2 col-sm-12 col-md-2">
              <div class="form-group">
                <label>Título</label>
                <input type="text" [(ngModel)]="Request.Titulo" name="Titulo" required placeholder="Título" class="form-control">
              </div>
            </div>
            <div class="col-lg-2 col-sm-12 col-md-2">
              <div class="form-group">
                <label>Subtítulo</label>
                <input type="text" [(ngModel)]="Request.Subtitulo" name="SubTitulo" placeholder="Subtítulo" class="form-control">
              </div>
            </div>
            <div class="col-lg-2 col-sm-12 col-md-2">
              <div class="form-group">
                <label>Descripcion</label>
                <input type="text" [(ngModel)]="Request.Descripcion" name="Descripcion" placeholder="Descripción" class="form-control">
              </div>
            </div>
            <div class="col-lg-2 col-sm-12 col-md-2">
              <div class="form-group">
                <label>Fecha</label>
                <input class="form-control" #drp="bsDaterangepicker" bsDaterangepicker [(ngModel)]="RangoFecha" [bsConfig]="{dateInputFormat: 'YYYY-MM-DD' , containerClass: 'theme-blue' }">
              </div>
            </div>
            <div class="col-lg-2 col-sm-12 col-md-2">
              <div class="form-group">
                <label>Estado</label>
                <select class="form-control" [(ngModel)]="Request.Estado" name="Estado">
                  <option value="">Todos</option>
                  <option value="Activo">Activo</option>
                  <option value="Inactivo">Inactivo</option>
                </select>
              </div>
            </div>
            <div class="col-lg-2 col-sm-12 col-md-2">
              <div class="form-group">
                <label>Principal</label>
                <select class="form-control" [(ngModel)]="RequestPrincipalCombo" name="Estado">
                  <option value="">Todas</option>
                  <option value="Principal">Principal</option>
                  <option value="NoPrincipal">Normal</option>
                </select>
              </div>
            </div>
            <div class="col-lg-12 col-sm-12 col-md-12">
              <button class="btn btn-primary " (click)="ConsultarNoticia()" type="button"><i class="fa fa-search"></i>&nbsp;Consultar</button>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-sm-12 col-md-12">
              <app-data-table-generic (CellClicked)="ClickFila($event)" [Filas]="Noticias" [ShowLoading]="CargandoTablaNoticia" [columns]="Columnas"></app-data-table-generic>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #ModalRegistroNoticia>
  <div class="modal-header bg-primary">
    <h4 class="modal-title pull-left">Registro de noticias</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form ngNativeValidate #FormRegistroNoticia="ngForm" (ngSubmit)="RegistrarNoticia(FormRegistroNoticia)">
    <div class="modal-body">
      <div class="row">
        <div class="col-lg-4 col-sm-12 col-md-4">
          <div class="form-group">
            <label>Título</label>
            <input type="text" id="Titulo" [(ngModel)]="RequestNoticia.Titulo" name="Titulo" required placeholder="Título" class="form-control">
          </div>
        </div>
        <div class="col-lg-4 col-sm-12 col-md-4">
          <div class="form-group">
            <label>Subítulo</label>
            <input type="text" id="subtitulo" [(ngModel)]="RequestNoticia.Subtitulo" name="unputonombre" placeholder="Subtítulo" class="form-control">
          </div>
        </div>
        <div class="col-lg-4 col-sm-12 col-md-4" style="margin-top: 32px;">
          <div class="text-center checkbox checkbox-primary">
            <input id="checkprincipal" type="checkbox" name="RequestNoticiaPrincipal" [(ngModel)]="RequestNoticia.Principal">
            <label for="checkprincipal">Noticia principal?</label>
          </div>
        </div>
        <div class="col-lg-6 col-sm-12 col-md-6">
          <div class="form-group">
            <label>Imagen</label>
            <input type="file" class="form-control" name="documents" (change)="onChangeImagen($event)" [(ngModel)]="ImagenNoticia" #documents="ngModel">
          </div>
        </div>
        <div class="col-lg-6 col-sm-12 col-md-6" *ngIf="RequestNoticia.Principal">
          <label>Recomendación:</label><br />
          <span class="label label-info">La resolución ideal es 1900 x 500 px;</span>
        </div>
      </div>
      <div class="hr-line-dashed"></div>
      <div class="row">
        <div class="col-lg-6">
          <div class="col-lg-12">
            <div class="form-group">
              <label class="checkbox checkbox-primary">
                <input id="checkYoutube" type="checkbox" name="RequestNoticiaConVideoYoutube" [(ngModel)]="RequestNoticia.PoseeVideoYoutube">
                <label for="checkYoutube">Video youtube?</label>
              </label>
              <input *ngIf="RequestNoticia.PoseeVideoYoutube" type="url" name="url" id="url"
                     placeholder="Formato: https://www.youtube.com/watch?v=..."
                     pattern="http://www\.youtube\.com\/(.+)|https://www\.youtube\.com\/(.+)"
                     [(ngModel)]="RequestNoticia.UrlVideoYoutube"
                     class="form-control"
                     required>
            </div>
          </div>
        </div>
      </div>
      <div class="hr-line-dashed"></div>
      <div class="row">
        <div class="col-lg-12 col-sm-12 col-md-12">
          <div class="form-group">
            <label>Descripción</label>
            <textarea rows="10" required name="Descripcion" [(ngModel)]="RequestNoticia.Descripcion" style="width: 100%;"></textarea>
          </div>
        </div>
        <div class="col-lg-6 col-sm-12 col-md-6">
          <div class="form-group">
            <label>Documento</label>
            <input type="file" class="form-control" name="documents" (change)="onChange($event)" [(ngModel)]="DocumentoSoporteNoticia" #documents="ngModel">
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary pull-left" type="submit" [disabled]="disabledButton"><i class="fa fa-check"></i>&nbsp;Registrar</button>
      <button type="button" class="btn btn-default pull-right" (click)="modalRef.hide()">Cerrar</button>
    </div>
  </form>
</ng-template>
