<div class="row">
  <div class="col-lg-4">

  </div>
  <div class="col-lg-4 text-center">
    <h1>Clasificación familiar</h1>
  </div>
  <div class="col-lg-4">
    <button type="button" class="btn btn-primary btn-sm pull-right" (click)="Guardar()"><i class="fa fa-save (alias)"></i> Guardar</button>
  </div>
</div>
<div class="hr-line-dashed"></div>
<div class="row">
  <div class="col-lg-6">
    <div class="form-group">
      <label>Tipo de familia</label>
      <select class="form-control" [(ngModel)]="RequestGestion.ClasificacionFamiliar.TipoFamilia" name="TipoFamilia">
        <option value="">Seleccione una opción</option>
        <option value="NUCLEAR">NUCLEAR</option>
        <option value="EXTENSA">EXTENSA</option>
        <option value="AMPLIADA">AMPLIADA</option>
        <option value="MONOPARENTAL">MONOPARENTAL</option>
        <option value="DIADA CONYUGAL">DIADA CONYUGAL</option>
        <option value="SIMUILTANEA, SUPERPUESTA O RECONSTRUIDA (D)">SIMUILTANEA, SUPERPUESTA O RECONSTRUIDA (D)</option>
      </select>
    </div>
  </div>
  <div class="col-lg-6">
    <div class="form-group">
      <label>Ciclo vital de la familia</label>
      <select class="form-control" [(ngModel)]="RequestGestion.ClasificacionFamiliar.CicloVitalDeLaFamilia" name="CicloVitalDeLaFamilia">
        <option value="">Seleccione una opción</option>
        <option value="FORMACIÓN">FORMACIÓN</option>
        <option value="EXPANSIÓN (DE 2 A 5 AÑOS)">EXPANSIÓN (DE 2 A 5 AÑOS)</option>
        <option value="CONSOLIDACIÓN (DE 6 A 13 AÑOS) (DE 14 A 20 AÑOS)">CONSOLIDACIÓN (DE 6 A 13 AÑOS) (DE 14 A 20 AÑOS)</option>
        <option value="APERTURA (CUANDO LOS HIJOS EMPIEZAN A SALIR DE LA FAMILIA)">APERTURA (CUANDO LOS HIJOS EMPIEZAN A SALIR DE LA FAMILIA)</option>
        <option value="POSTPARENTAL">POSTPARENTAL</option>
        <option value="DISOLUCIÓN (MUERTE DE ALGUNO DE LOS CÓNYUGES)">DISOLUCIÓN (MUERTE DE ALGUNO DE LOS CÓNYUGES)</option>
      </select>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-lg-4">
    <div class="form-group">
      <label>Situación actual</label>
      <select class="form-control" [(ngModel)]="RequestGestion.ClasificacionFamiliar.TipoSituacionActual" name="TipoSituacionActual">
        <option value="">Seleccione una opción</option>
        <option value="FUNCIONAL">FUNCIONAL</option>
        <option value="DISFUNCIONAL">DISFUNCIONAL</option>
      </select>
    </div>
  </div>
  <div class="col-lg-4">
    <div class="form-group">
      <label>Ubicación</label>
      <select class="form-control" [(ngModel)]="RequestGestion.ClasificacionFamiliar.TipoUbicacion" name="TipoUbicacion">
        <option value="">Seleccione una opción</option>
        <option value="URBANA">URBANA</option>
        <option value="RURAL">RURAL</option>
      </select>
    </div>
  </div>
  <div class="col-lg-4">
    <div class="form-group">
      <label>Condición actual</label>
      <select class="form-control" [(ngModel)]="RequestGestion.ClasificacionFamiliar.CondicionActual" name="CondicionActual">
        <option value="">Seleccione una opción</option>
        <option value="REUBICACIÓN">REUBICACIÓN</option>
        <option value="CONDICIÓN ESTABLE">CONDICIÓN ESTABLE</option>
      </select>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-lg-12">
    <div class="form-group">
      <label>Observación</label>
      <textarea rows="10" required [(ngModel)]="RequestGestion.ClasificacionFamiliar.Observacion" name="ClasificacionFamiliarObservacion" style="width: 100%;"></textarea>
    </div>
  </div>
</div>
