import { Component, OnInit, Input, Output, ViewChild, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { GestionintegrantesService, GestionintegrantesRequest, FamiliaIndigenaIntegranteParaVerificarExistencia } from '../../../../../services/sipa/caracterizacion/identificacion/gestionintegrantes.service';
import { FamiliaIndigena, FamiliaIndigenaIntegrante } from '../../../../../entities/sipa/familiaindigena';
import { UtilitiesAnibalService } from '../../../../../services/utilities-anibal';
import { EventEmitter } from '@angular/core';
import { EnumerationObject } from '../../../../../entities/general/enumeration-object';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CellClickedTableGeneric } from '../../../../../components/anibal/data-table-generic/data-table-generic.component';

@Component({
  selector: 'app-gestionintegrantes',
  templateUrl: './gestionintegrantes.component.html',
  styleUrls: ['./gestionintegrantes.component.css'],
  providers: [ToastrService, GestionintegrantesService]
})
export class GestionintegrantesComponent implements OnInit {

  constructor(
    private _toastr: ToastrService,
    private _gestionintegrantesService: GestionintegrantesService,
    private _modalService: BsModalService,
  ) { }

  ngOnInit() {
  }

  public Parentescos: Array<EnumerationObject> = new Array<EnumerationObject>();
  public TiposIdentificacion: Array<EnumerationObject> = new Array<EnumerationObject>();

  @Output() public NotificacionComponente: EventEmitter<GestionIntegrantesComponentEventResponse> = new EventEmitter();
  private ObjectNotificacion: GestionIntegrantesComponentEventResponse = new GestionIntegrantesComponentEventResponse();

  private _FamiliaIndigena: FamiliaIndigena;
  @Input()
  set FamiliaIndigena(familia: FamiliaIndigena) {
    this._FamiliaIndigena = familia;
    if (familia && familia.Id) {
      this.RequestGestion.FamiliaIndigenaId = familia.Id;
      this.ConsultarDatosFormulario();
    }
  }

  get FamiliaIndigena(): FamiliaIndigena {
    return this._FamiliaIndigena;
  }

  public RequestGestion: GestionintegrantesRequest = new GestionintegrantesRequest();

  public NuevoIntegrante(): void {
    let objeto = new FamiliaIndigenaIntegrante();
    objeto.EstadoCivil = "";
    objeto.Sexo = "";
    objeto.TipoIdentificacion = "";
    objeto.TipoEdad = "";
    objeto.TipoMetodoPlanificacionFamiliar = "";
    objeto.Numero = this.RequestGestion.Integrantes.length + 1;
    this.RequestGestion.Integrantes.push(objeto);
  }

  public Guardar(): void {
    UtilitiesAnibalService.ShowLoading();
    this._gestionintegrantesService.GestionIntegrantesFamilia(this.RequestGestion).subscribe(response => {
      UtilitiesAnibalService.HideLoading();
      this.ConsultarDatosFormulario();
      this._toastr.success(response.Mensaje, "Correcto!");
    }, err => {
      UtilitiesAnibalService.HideLoading();
    });
  }

  public ConsultarDatosFormulario(): void {
    this.ObjectNotificacion.EstaConsultando = true;
    this.NotificacionComponente.emit(this.ObjectNotificacion);
    this._gestionintegrantesService.GetConsultarDatosFormulario(this._FamiliaIndigena.Id).subscribe(response => {
      this.ObjectNotificacion.EstaConsultando = false;
      this.RequestGestion.Integrantes = response.Integrantes;
      for (let integrante of this.RequestGestion.Integrantes) {
        integrante.FechaNacimiento = moment(integrante.FechaNacimiento).format("YYYY-MM-DD");
      }
      this.TiposIdentificacion = response.TiposIdentificacion;
      this.Parentescos = response.Parentescos;
      this.ObjectNotificacion.CantidadIntegrantes = response.Integrantes.length;
      this.NotificacionComponente.emit(this.ObjectNotificacion);
    }, err => {
    });
  }

  public ColumnasIntegrantes: Array<any> = [
    { title: 'Identificación', name: 'Identificacion' },
    { title: 'Nombre completo', name: 'NombreCompleto' },
    { title: 'Sexo', name: 'Sexo' },
    { title: 'Número familia', name: 'NumeroFamilia' },
    { title: 'Parentesco', name: 'Parentesco' },
    { title: 'Estado civil', name: 'EstadoCivil' },
    { title: '', name: 'BotonSeleccionarIntegrante', hideInExport: true }
  ];

  public Integrantes: Array<FamiliaIndigenaIntegranteParaVerificarExistencia> = new Array<FamiliaIndigenaIntegranteParaVerificarExistencia>();

  private modalRefConsultaIntegrantes: BsModalRef;
  @ViewChild('ModalConsultaIntegrantes') public templateRefModalConsultaIntegrantes: TemplateRef<any>;

  public OpenModalConsultaIntegrantes() {
    this.modalRefConsultaIntegrantes = this._modalService.show(this.templateRefModalConsultaIntegrantes, { class: 'modal-lg' });
  }

  public ConsultarIntegrantes(integrante: FamiliaIndigenaIntegrante): void {
    if (integrante.Identificacion) {
      UtilitiesAnibalService.ShowLoading();
      this._gestionintegrantesService.GetIntegrantes(integrante.Identificacion).subscribe(response => {
        UtilitiesAnibalService.HideLoading();
        this.Integrantes = response.Integrantes;
        for (let integrante of this.Integrantes) {
          integrante.BotonSeleccionarIntegrante = '<a href="javascript:0;" title="Seleccionar" class="btn btn-xs btn-success"><i class="fa fa-edit (alias)"> Seleccionar</i></a>';
        }
        if (response.Integrantes.length > 1) {
          this.OpenModalConsultaIntegrantes();
        } else {
          this.AsignarIntegrante(response.Integrantes[0]);
        }
      }, err => {
        UtilitiesAnibalService.HideLoading();
      });
    }
  }

  public ClickFilaIntegrante(data: CellClickedTableGeneric): any {
    if (data.Columna === "BotonSeleccionarIntegrante") {
      if (data.Fila.BotonSeleccionarIntegrante) {
        let IntegranteSeleccionado = data.Fila as FamiliaIndigenaIntegrante;
        this.AsignarIntegrante(IntegranteSeleccionado);
      } else {
      }
    }
  }

  private AsignarIntegrante(IntegranteSeleccionado: FamiliaIndigenaIntegrante): void {
    let integranteEnRequest = this.RequestGestion.Integrantes.filter(function (integrante, index) {
      return integrante.Identificacion == IntegranteSeleccionado.Identificacion;
    })[0];
    this.MapearIntegrante(IntegranteSeleccionado, integranteEnRequest);
    this.modalRefConsultaIntegrantes.hide();
  }

  private MapearIntegrante(integranteOrigen: FamiliaIndigenaIntegrante, integranteFinal: FamiliaIndigenaIntegrante): void {
    integranteFinal.Identificacion = integranteOrigen.Identificacion;
    integranteFinal.TipoIdentificacion = integranteOrigen.TipoIdentificacion;
    integranteFinal.NombreCompleto = integranteOrigen.NombreCompleto;
    integranteFinal.Sexo = integranteOrigen.Sexo;
    integranteFinal.Aseguramiento = integranteOrigen.Aseguramiento;
    integranteFinal.Parentesco = integranteOrigen.Parentesco;
    integranteFinal.EstadoCivil = integranteOrigen.EstadoCivil;
    integranteFinal.FechaNacimiento = integranteOrigen.FechaNacimiento;
    integranteFinal.EdadValor = integranteOrigen.EdadValor;
    integranteFinal.TipoEdad = integranteOrigen.TipoEdad;
    integranteFinal.EdadConBaseFecha = integranteOrigen.EdadConBaseFecha;
    integranteFinal.Escolaridad = integranteOrigen.Escolaridad;
    integranteFinal.ConocimientosAncestrales = integranteOrigen.ConocimientosAncestrales;
    integranteFinal.Ocupacion = integranteOrigen.Ocupacion;
    integranteFinal.EsGestante = integranteOrigen.EsGestante;
    integranteFinal.CuidadosDelEmbarazoAncestral = integranteOrigen.CuidadosDelEmbarazoAncestral;
    integranteFinal.CuidadosDelEmbarazoOccidental = integranteOrigen.CuidadosDelEmbarazoOccidental;
    integranteFinal.AsisteCuidadoDeLosMenoresDe10Year = integranteOrigen.AsisteCuidadoDeLosMenoresDe10Year;
    integranteFinal.EstadoNutricional = integranteOrigen.EstadoNutricional;
    integranteFinal.PoseeVacunasEsquemaCompleto = integranteOrigen.PoseeVacunasEsquemaCompleto;
    integranteFinal.PoseeVacunasEsquemaIncompleto = integranteOrigen.PoseeVacunasEsquemaIncompleto;
    integranteFinal.PoseeVacunasEsquemaAdecuado = integranteOrigen.PoseeVacunasEsquemaAdecuado;
    integranteFinal.PoseeVacunasEsquemaInadecuado = integranteOrigen.PoseeVacunasEsquemaInadecuado;
    integranteFinal.PoseeVacunasEmbarazadaRenuente = integranteOrigen.PoseeVacunasEmbarazadaRenuente;
    integranteFinal.PoseeVacunasMujeresEnEdadFertil = integranteOrigen.PoseeVacunasMujeresEnEdadFertil;
    integranteFinal.PoseeVacunasAdultoJoven = integranteOrigen.PoseeVacunasAdultoJoven;
    integranteFinal.PoseeVacunasTerceraEdad = integranteOrigen.PoseeVacunasTerceraEdad;
    integranteFinal.AsisteAlCuidadoDeAdultoMayor = integranteOrigen.AsisteAlCuidadoDeAdultoMayor;
    integranteFinal.AsisteAlCuidadoDeAdultoJoven = integranteOrigen.AsisteAlCuidadoDeAdultoJoven;
    integranteFinal.AsisteAlCuidadoDePlanificacionFamiliar = integranteOrigen.AsisteAlCuidadoDePlanificacionFamiliar;
    integranteFinal.TipoMetodoPlanificacionFamiliar = integranteOrigen.TipoMetodoPlanificacionFamiliar;
    integranteFinal.SeHaRealizadoAlgunaVezCitologiaVaginal = integranteOrigen.SeHaRealizadoAlgunaVezCitologiaVaginal;
    integranteFinal.Discapacidad = integranteOrigen.Discapacidad;
    integranteFinal.ConMalformacionesCongenitas = integranteOrigen.ConMalformacionesCongenitas;
    integranteFinal.OtraEnfermedad = integranteOrigen.OtraEnfermedad;
    integranteFinal.TomaDePresionArterial = integranteOrigen.TomaDePresionArterial;
    integranteFinal.CausalFallecimiento = integranteOrigen.CausalFallecimiento;
  }
}

export class GestionIntegrantesComponentEventResponse {
  public CantidadIntegrantes: number;
  public EstaConsultando: boolean;
}
