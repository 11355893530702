import { Routes } from "@angular/router";

import { NgxPermissionsGuard } from 'ngx-permissions';
import { BasicLayoutComponent } from "../components/common/layouts/basicLayout.component";

import { GestionnoticiaComponent } from '../views/configuracion/gestionnoticia/gestionnoticia.component';
import { GestionparametrosComponent } from '../views/configuracion/gestionparametros/gestionparametros.component';
import { GestionvigenciasComponent } from '../views/configuracion/gestionvigencias/gestionvigencias.component';

export const Configuracion: any = {
    path: 'Configuracion', component: BasicLayoutComponent,
    children: [
        {
            path: 'Gestion/Noticias', component: GestionnoticiaComponent, canActivate: [NgxPermissionsGuard],
            data: {
                permissions: {
                  only: ['Configuracion.GestionNoticia'],
                    redirectTo: 'Home'
                }
            }
      },
      {
        path: 'Gestion/Parametros', component: GestionparametrosComponent, canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['Configuracion.GestionParametros'],
            redirectTo: 'Home'
          }
        }
      },
      {
        path: 'Gestion/Vigencias', component: GestionvigenciasComponent, canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            only: ['Configuracion.GestionVigencias'],
            redirectTo: 'Home'
          }
        }
      }
    ]
};
