import { Tercero } from '../../../../entities/general/tercero';

export class RegistroUsuarioRequest {
    constructor() {
        this.Tercero = new Tercero();
        this.Usuario = new UsuarioRequest();
    }
    Tercero: Tercero;
    Usuario: UsuarioRequest;
}

export class UsuarioRequest {
    Email: string;
    FechaDesactivacion: Date;
    Password: string;
    PasswordConfirm: string;
    UserName: string;
}
