import { Injectable } from '@angular/core';
import { FamiliaIndigenaCapacitacion } from '../../../../entities/sipa/familiaindigena';
import { Observable } from 'rxjs';
import { HttpService } from '../../../http/http.service';
import { UtilitiesAnibalService } from '../../../utilities-anibal';
import { ApisRestCaracterizacionFamiliaIndigenaGestionCapacitaciones } from '../../../../system/apisrest';


@Injectable()
export class CapacitacionFamiliaIndigenaService {

  constructor(private _httpService: HttpService) { }

  public GetConsultarDatosFormulario(familiaIndigenaId: number): Observable<GestioncapacitacionesDatosFormularioResponse> {
    UtilitiesAnibalService.Console("Consultando capacitación de la familia (Datos formulario gestion)" + familiaIndigenaId);
    return this._httpService.get<GestioncapacitacionesDatosFormularioResponse>(ApisRestCaracterizacionFamiliaIndigenaGestionCapacitaciones.GetConsultarDatosFormulario(familiaIndigenaId));
  }

  public GestionCapacitacionFamilia(request: GestioncapacitacionesRequest): Observable<GestioncapacitacionesResponse> {
    UtilitiesAnibalService.Console("Gestión capacitación de la familia" + request.FamiliaIndigenaId);
    return this._httpService.post<GestioncapacitacionesResponse>(ApisRestCaracterizacionFamiliaIndigenaGestionCapacitaciones.PostGestionCapacitacion, request);
  }
}

export class GestioncapacitacionesResponse {
  public Mensaje: string;
}

export class GestioncapacitacionesRequest {
  public FamiliaIndigenaId: number;
  public Capacitacion: FamiliaIndigenaCapacitacion = new FamiliaIndigenaCapacitacion();
}

export class GestioncapacitacionesDatosFormularioResponse {
  public Capacitaciones: Array<FamiliaIndigenaCapacitacionTable> = new Array<FamiliaIndigenaCapacitacionTable>();
  public CapacitacionesParaRegistrar: Array<CapacitacionParaRegistro> = new Array<CapacitacionParaRegistro>();
}

export class FamiliaIndigenaCapacitacionTable extends FamiliaIndigenaCapacitacion {
  public FechaTable: string;
}

export class CapacitacionParaRegistro {
  public Id: number;
  public Codigo: string;
  public Nombre: string;
}
