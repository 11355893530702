<div *ngIf="Usuario">
  <div class="panel panel-primary">
    <div class="panel-heading">
      {{Usuario.NombreCompleto}} - {{Usuario.Identificacion}}
    </div>
    <div class="panel-body">
      <p class="PaddingMensaje">Seleccione los permisos a los cuales tendra acceso el usuario {{Usuario.UserName}}</p>
      <div class="col-lg-6 col-sm-12 col-md-6">
        <div class="panel panel-success">
          <div class="panel-heading">
            Modulos
          </div>
          <div class="panel-body">
            <div class="form-group">
              <label>Modulos</label>
              <select class="form-control" #selectModulos name="Modulos" (change)="GetPermission(selectModulos.value)">
                <option *ngFor="let modulo of Modulos" [value]="modulo.MenuId">
                  {{modulo.Titulo}}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12 col-md-6">
        <div class="panel panel-info">
          <div class="panel-heading">
            <button class="btn btn-primary " (click)="GuardarPermisos()" type="button"><i class="fa fa-save"></i>&nbsp;Guardar</button>
            <span class="pull-right MarginLabel">Permisos</span>
          </div>
          <div class="panel-body">
            <div class="table-responsive">
              <table class="table table-striped table-bordered">
                <thead>
                  <tr>
                    <th>
                      <div class="text-center checkbox checkbox-success">
                        <input id="seleccionartodos" type="checkbox" [(ngModel)]="selectedAllPermission " (change)="ActualizarPermisos()">
                        <label for="seleccionartodos"></label>
                      </div>
                    </th>
                    <th class="text-center">Permiso</th>
                    <th class="text-center">Titulo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let permiso of Permisos">
                    <td>
                      <div class="text-center checkbox checkbox-primary">
                        <input id="{{permiso.Rol}}" type="checkbox"  [(ngModel)]="permiso.hasRol">
                        <label for="{{permiso.Rol}}"></label>
                      </div>
                    </td>
                    <td>{{permiso.MenuId}}</td>
                    <td  tooltip="{{permiso.Descripcion}}" placement="bottom" >{{permiso.Titulo}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
