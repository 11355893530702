import { Component, OnInit, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FamiliaIndigena, FamiliaIndigenaEcomapaCuestionario } from '../../../../../entities/sipa/familiaindigena';
import { UtilitiesAnibalService } from '../../../../../services/utilities-anibal';
import { EventEmitter } from '@angular/core';
import { EcomapaFamiliaIndigenaService, GestionEcomapaRequest } from '../../../../../services/sipa/caracterizacion/diagnosticofamiliar/Ecomapa-familia-indigena.service';

@Component({
  selector: 'app-ecomapa',
  templateUrl: './ecomapa.component.html',
  styleUrls: ['./ecomapa.component.css'],
  providers: [ToastrService, EcomapaFamiliaIndigenaService]
})
export class EcomapaComponent implements OnInit {

  constructor(
    private _toastr: ToastrService,
    private _EcomapaFamiliaIndigenaService: EcomapaFamiliaIndigenaService,
  ) { }

  ngOnInit() {
  }

  @Output() public NotificacionComponente: EventEmitter<GestionEcomapaComponentEventResponse> = new EventEmitter();
  private ObjectNotificacion: GestionEcomapaComponentEventResponse = new GestionEcomapaComponentEventResponse();

  private _FamiliaIndigena: FamiliaIndigena;
  @Input()
  set FamiliaIndigena(familia: FamiliaIndigena) {
    this._FamiliaIndigena = familia;
    if (familia && familia.Id) {
      this.RequestGestion.FamiliaIndigenaId = familia.Id;
      this.ConsultarDatosFormulario();
    }
  }

  get FamiliaIndigena(): FamiliaIndigena {
    return this._FamiliaIndigena;
  }

  public RequestGestion: GestionEcomapaRequest = new GestionEcomapaRequest();

  public Guardar(): void {
    UtilitiesAnibalService.ShowLoading();
    for (let ecomapa of this.RequestGestion.Ecomapas) {
      for (let cuestionario of ecomapa.Cuestionarios) {
        this.ValidarSiTieneObservacionAndRespuestaDebeSerTrue(cuestionario);
      }
    }
    this._EcomapaFamiliaIndigenaService.GestionEcomapa(this.RequestGestion).subscribe(response => {
      UtilitiesAnibalService.HideLoading();
      this.ConsultarDatosFormulario();
      this._toastr.success(response.Mensaje, "Correcto!");
    }, err => {
      UtilitiesAnibalService.HideLoading();
    });
  }

  public ConsultarDatosFormulario(): void {
    this.ObjectNotificacion.EstaConsultando = true;
    this.NotificacionComponente.emit(this.ObjectNotificacion);
    this._EcomapaFamiliaIndigenaService.GetConsultarDatosFormulario(this._FamiliaIndigena.Id).subscribe(response => {
      this.ObjectNotificacion.EstaConsultando = false;
      this.RequestGestion.Ecomapas = response.Ecomapas;
      this.ObjectNotificacion.NoHaRegistrado = this.RequestGestion.Ecomapas.some(function (e) { return e.Id == 0; })
      this.NotificacionComponente.emit(this.ObjectNotificacion);
    }, err => {
    });
  }

  public ValidarSiTieneObservacionAndRespuestaDebeSerTrue(cuestionario: FamiliaIndigenaEcomapaCuestionario) {
    if (cuestionario.SiEsTrueDebeEscribirObservacion) {
      if (cuestionario.Observacion) {
        cuestionario.Respuesta = true;
      } else {
        cuestionario.Respuesta = false;
      }
    }
  }
}

export class GestionEcomapaComponentEventResponse {
  public EstaConsultando: boolean;
  public NoHaRegistrado: boolean;
}
