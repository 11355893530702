import { Component, OnInit, TemplateRef } from '@angular/core';
import { AuthService } from '../../../services/security/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UtilitiesAnibalService } from '../../../services/utilities-anibal';
import { IndexService } from '../../../services/public/index/index.service';

declare var jQuery: any;

@Component({
    selector: 'app-index',
    templateUrl: './index.component.html',
    styleUrls: ['./index.component.css'],
    providers: [ToastrService, IndexService]
})
export class IndexComponent implements OnInit {

    public UrlBaseServer: string = UtilitiesAnibalService.DomainServer;

    public isMenuCollapse: boolean = true;

    private modalRef: BsModalRef;

    public ShowLoading: boolean = true;

    constructor(private _modalService: BsModalService,
        private _toastr: ToastrService,
        private _service: AuthService,
        private _router: Router,
        private _indexService: IndexService) {
        if (_service.UserLogged()) { this._router.navigate(['/Home']); }
    }

    ngOnInit() {
    }

    public OpenModal(template: TemplateRef<any>) {
        this.modalRef = this._modalService.show(template, { class: 'modal-lg' });
    }

    public OpenModalConsultaPqrs(template: TemplateRef<any>) {
        this.modalRef = this._modalService.show(template, { class: 'modal-lg' });
    }

    ngAfterViewInit() {

        this.ConsultarPaginaInicio();

        jQuery('#side-menu').metisMenu();

        if (jQuery("body").hasClass('fixed-sidebar')) {
            jQuery('.sidebar-collapse').slimscroll({
                height: '100%'
            })
        }

        this.CosasNuevas();
    }

    private ConsultarPaginaInicio(): void {
        UtilitiesAnibalService.ShowLoading();
        this._indexService.GetPaginaPrincipal()
            .subscribe(response => {
                UtilitiesAnibalService.HideLoading();
                jQuery("#IframeIndex").attr('src', response.UrlPaginaPrincipal);
            }, error => {
                UtilitiesAnibalService.HideLoading();
                this._toastr.warning(error.error.Message);
            });
    }

    private CosasNuevas() {
        // SKIN Select
        jQuery('.spin-icon').click(function () {
            jQuery(".theme-config-box").toggleClass("show");
        });
    }

    public CargoIframe(): void {
        this.ShowLoading = false;
    }
}
