import { Injectable } from '@angular/core';
import { HttpService } from '../../http/http.service';
import { ApisRestGestionFamiliasIndigenas } from '../../../system/apisrest';
import { Observable } from 'rxjs';
import { UtilitiesAnibalService } from '../../utilities-anibal';
import { EnumerationObject } from '../../../entities/general/enumeration-object';
import { FamiliaIndigena, FamiliaIndigenaIntegrante } from '../../../entities/sipa/familiaindigena';
import { Municipio } from '../../../entities/general/departamento';

@Injectable()
export class GestionFamiliaIndigenaService {

    constructor(private _httpService: HttpService) { }

    public GetConsultarDatosFormulario(): Observable<GestionFamiliaIndigenaDatosFormularioResponse> {
        UtilitiesAnibalService.Console("Consultando municipios con asentamientos (Datos formulario gestion familia indigena)");
        return this._httpService.get<GestionFamiliaIndigenaDatosFormularioResponse>(ApisRestGestionFamiliasIndigenas.GetDatosFormulario);
    }

    public RegistrarFamiliaIndigena(request: RegistrarFamiliaIndigenaRequest): Observable<RegistrarFamiliaIndigenaResponse> {
        UtilitiesAnibalService.Console("registrando familia indigena");
        return this._httpService.post<RegistrarFamiliaIndigenaResponse>(ApisRestGestionFamiliasIndigenas.PostRegistrarFamilia, request);
    }

    public ConsultarTodasLasVigencias(request: ConsultaParametrizadaFamiliaIndigenaRequest): Observable<ConsultaParametrizadaFamiliaIndigenaResponse> {
        UtilitiesAnibalService.Console("Consultando familias indigenas del sistema");
        return this._httpService.post<ConsultaParametrizadaFamiliaIndigenaResponse>(ApisRestGestionFamiliasIndigenas.PostConsultaParametrizada, request);
    }

    public GetIntegrantes(identificacion: string): Observable<ConsultaIntegranteParaVerificar> {
        UtilitiesAnibalService.Console("Consultando integrante para verificar la familia" + identificacion);
        return this._httpService.get<ConsultaIntegranteParaVerificar>(ApisRestGestionFamiliasIndigenas.GetIntegrantesFamilia(identificacion));
    }

}


export class GestionFamiliaIndigenaDatosFormularioResponse {
    public Municipios: Array<Municipio> = new Array<Municipio>();
}


/**
 * A pesar que es una lista debe devolver uno solo ya que es por familia
 * */
export class ConsultaIntegranteParaVerificar {
    public Integrantes: Array<IntegranteParaVerificarEnQueFamiliaEsta> = new Array<IntegranteParaVerificarEnQueFamiliaEsta>();
}

export class IntegranteParaVerificarEnQueFamiliaEsta extends FamiliaIndigenaIntegrante {
    public BotonIdDetalle: string;
}

export class RegistrarFamiliaIndigenaRequest {
    public MunicipioId: number;
    public AsentamientoId: number;
    public SectorId: number;
    public Observacion: string;
}

export class RegistrarFamiliaIndigenaResponse {
    public Mensaje: string;
    public NumeroFamilia: string;
}

export class ConsultaDatosFormularioResponse {
}

export class ConsultaParametrizadaFamiliaIndigenaRequest {
    public Municipio: string;
    public Asentamiento: string;
    public Numero: string;
    public Sector: string;
}

export class ConsultaParametrizadaFamiliaIndigenaResponse {
    public FamiliaIndigenas: Array<FamiliaIndigenaTable>;
}

export class FamiliaIndigenaTable extends FamiliaIndigena {
    public FechaPipe: string;
    public CantidadIntegrantes: number;
    public CantidadSeguimientos: number;
    public BotonCaracterizacion: string;
    public CantidadIntegrantesBadge: string;
    public CantidadSeguimientosBadge: string;
}

