import { Component, OnInit, Input, Output, ViewChild, EventEmitter, SimpleChanges, TemplateRef } from '@angular/core';
import { Pqrs } from '../../../entities/pqr/pqr';
import { Tercero } from '../../../entities/general/tercero';
import { ResponderPqrsService, ConsultaPqrsByIdResponse, ResponderPqrsRequest, DocumentoSoporteRespuestaPqr } from '../../../services/pqrs/responderpqrs/responder-pqrs.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { UtilitiesAnibalService } from '../../../services/utilities-anibal';
import { ApisRestPublic } from '../../../system/apisrest';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-responderpqrs',
  templateUrl: './responderpqrs.component.html',
  styleUrls: ['./responderpqrs.component.css'],
  providers: [ResponderPqrsService, ToastrService]
})
export class ResponderpqrsComponent implements OnInit {

  public Pqrs: Pqrs;
  private modalRef: BsModalRef;
  public fileTypes = UtilitiesAnibalService.ExtensionesArchivosSoportados;

  public UrlApiDownloadDocumentoSoportePqr: string = ApisRestPublic.GetDownloadDocumentoSoportePqrsById;
  public UrlApiDownloadDocumentoSoporteRespuestaPqr: string = ApisRestPublic.GetDownloadDocumentoSoporteRespuestaPqrsById;
  public Request: ResponderPqrsRequest = this.InicializarRequest();

  public disabledButton: boolean = false;

  constructor(private route: ActivatedRoute,
    private location: Location,
    private _toastr: ToastrService,
    private _responderPqrsService: ResponderPqrsService,
    private _modalService: BsModalService
  ) { }

  private InicializarRequest(): ResponderPqrsRequest {
    return { Descripcion: "", DocumentosSoporte: null, PqrsId: 0 };
  }

  ngOnInit() {
    this._responderPqrsService.GetPqrsById(this.route.snapshot.paramMap.get('pqrsId'))
      .subscribe(response => {
        this.Pqrs = response.Pqrs;
        this.Request.PqrsId = this.Pqrs.Id;
      }, err => {
        window.history.back();
      });
  }

  private ConsultarPqrs(PqrsId: string) {
    this._responderPqrsService.GetPqrsById(PqrsId)
      .subscribe(response => {
        this.Pqrs = response.Pqrs;
        this.Request.PqrsId = this.Pqrs.Id;
      }, err => {
        window.history.back();
      });
  }

  onChange(event) {
    if (event.srcElement.files && event.srcElement.files[0]) {
      var extension = event.srcElement.files[0].name.split('.').pop().toLowerCase();
      var nombreDocumento = event.srcElement.files[0].name;
      var isSuccess = this.fileTypes.indexOf(extension) > -1;  //is extension in acceptable types
      if (isSuccess) { //yes
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.Request.DocumentosSoporte = new Array<DocumentoSoporteRespuestaPqr>();
          this.Request.DocumentosSoporte[0] = {
            Documento: event.target.result,
            ExtensionDocumento: extension,
            NombreDocumento: nombreDocumento
          };
        };

        reader.readAsDataURL(event.srcElement.files[0]);
      }
      else { //no
        this._toastr.warning("La extension " + extension + " No esta soportada");
      }
    }
  }

  public OpenModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template);
  }

  public RegistrarRespuestaPqr(formPqr: any): void {
    this.disabledButton = true;
    this._responderPqrsService.RegistrarRespuesta(this.Request).subscribe(response => {
      this.disabledButton = false;
      this.ConsultarPqrs(this.Pqrs.Id+"");
      this.Request = this.InicializarRequest();
      this.modalRef.hide();
        this._toastr.success(response.Mensaje, "Correcto!!");
    }, err => {
      this.disabledButton = false;
    });
  }

}
