<div class="row wrapper border-bottom white-bg page-heading">
  <div class="col-lg-10">
    <h2>{{AppName}}</h2>
    <ol class="breadcrumb">
      <li class="breadcrumb-item active">
        <strong>Informes del modulo de SIPA</strong>
      </li>
    </ol>
  </div>
</div>
<div class="wrapper wrapper-content animated fadeInRight ecommerce">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox">
        <div class="ibox-content">
          <div class="tab-pane">
            <div>
              <tabset>
                <tab>
                  <ng-template tabHeading>
                    <i class="fa fa-group (alias)"></i> Caracterización
                  </ng-template>
                  <tabset>
                    <tab>
                      <ng-template tabHeading>
                        <i class="fa fa-area-chart"></i> Informe grafico
                      </ng-template>
                      <div class="row">
                        <app-chart-caracterizacion [InformeCaracterizacion]="InformeCaracterizacion"></app-chart-caracterizacion>
                      </div>
                    </tab>
                    <tab>
                      <ng-template tabHeading>
                        <i class="fa fa-list-alt"></i> Informe detallado
                      </ng-template>
                      <app-data-table-generic [Filas]="InformeCaracterizacionWithTotal" [NameReport]="'Familias Caracterizadas'" [ShowLoading]="CargandoTablaCaracterizacion" [columns]="ColumnasInformeCaracterizacion"></app-data-table-generic>
                    </tab>
                  </tabset>

                </tab>
                <tab>
                  <ng-template tabHeading>
                    <i class="fa fa-yelp"></i> Ecomapa
                  </ng-template>
                  <div class="row">
                    <div class="col-lg-4">
                      <div class="form-group">
                        <label>Ecomapa</label>
                        <select class="form-control" id="EcomapaSelect" [(ngModel)]="EcomapaSeleccionado" name="EcomapaSelect" (change)="ConsultarInformeEcomapa()">
                          <option value="{{Ecomapa.Value}}" *ngFor="let Ecomapa of Ecomapas">{{Ecomapa.DisplayName}}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-12">
                      <app-data-table-generic [Filas]="InformeEcomapas" [ShowLoading]="CargandoTablaEcomapa" [columns]="ColumnasInformeEcomapa"></app-data-table-generic>
                    </div>
                  </div>
                </tab>
              </tabset>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
