import { Injectable } from '@angular/core';
import { HttpService } from '../../http/http.service';
import { ApisRestGestionTerceros } from '../../../system/apisrest';
import { Observable } from 'rxjs';
import { UtilitiesAnibalService } from '../../utilities-anibal';
import { Tercero } from '../../../entities/general/tercero';
import { EnumerationObject } from '../../../entities/general/enumeration-object';

@Injectable()
export class GestionTercerosService {

  constructor(private _httpService: HttpService) { }

  public ConsultarDatosFormulario(): Observable<ConsultaDatosFormularioResponse> {
    UtilitiesAnibalService.Console("Consultando datos del formulario de consulta de terceros");
    return this._httpService.get<ConsultaDatosFormularioResponse>(ApisRestGestionTerceros.GetDatosFormulario);
  }

  public ConsultarTodasLasVigencias(request: ConsultaParametrizadaTerceroRequest): Observable<ConsultaParametrizadaTerceroResponse> {
    UtilitiesAnibalService.Console("Consultando terceros del sistema");
    return this._httpService.post<ConsultaParametrizadaTerceroResponse>(ApisRestGestionTerceros.PostConsultaParametrizada,request);
  }

}

export class ConsultaDatosFormularioResponse {
  public TiposTerceros: Array<EnumerationObject>;
}

export class ConsultaParametrizadaTerceroRequest {
  public TipoTercero: string;
  public Identificacion: string;
  public NombreCompleto: string;
  public Movil1: string;
  public Movil2: string;
  public CorreoElectronico: string;
  public Direccion: string;
}

export class ConsultaParametrizadaTerceroResponse {
  public Terceros: Array<TerceroTable>;
}

export class TerceroTable extends Tercero {
  public Estado: string;
  public EstadoBadge: string;
}
