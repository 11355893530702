import { Tercero } from '../general/tercero';

export class Pqrs {
    public Id: number;
    public TipoPqr: string;
    public Fecha: Date;
    public NumeroRadicado: string;
    public Descripcion: string;
    public CorreoElectronico: string;
    public Estado: string;
    public TerceroId: number;
    public Tercero: Tercero;
    public DocumentosSoporte: Array<DocumentoSoportePqrs>;
}

export class DocumentoSoportePqrs {
    public Id: number;
    public DocumentoSoporte: string;
    public ExtensionDocumento: string;
    public NombreDocumento: string;
    public Size: number;
    public PqrId: number;
}
