import { Routes } from "@angular/router";

import { StarterViewComponent } from "./views/appviews/starterview.component";
import { LoginComponent } from "./views/appviews/login.component";

import { BlankLayoutComponent } from "./components/common/layouts/blankLayout.component";
import { BasicLayoutComponent } from "./components/common/layouts/basicLayout.component";

import { Seguridad } from './routes/seguridad';
import { Public } from './routes/public';
import { PQRS } from './routes/pqrs';
import { Configuracion } from './routes/configuracion';
import { DatosBasicos } from './routes/datosbasicos';
import { SIPA } from "./routes/sipa";

export const ROUTES: Routes = [
  // Main redirect
  { path: '', redirectTo: 'Inicio', pathMatch: 'full' },
  // Modulo Cnfiguracion
  Configuracion,
  // Modulo Public
  Public,
  // Modulo Seguridad
  Seguridad,
  //Modulo PQRS
  PQRS,
  //Modulo Datos basicos
  DatosBasicos,
  //Modulo SIPA
  SIPA,
  {
    path: '', component: BasicLayoutComponent,
    children: [
      { path: 'Home', component: StarterViewComponent }
    ]
  },
  {
    path: '', component: BlankLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
    ]
  },
  // Handle all other routes
  { path: '**', redirectTo: 'Home' }
];
