import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CaracterizacionFamiliaIndigenaService, FamiliaIndigenaParaCaracterizacion } from '../../../services/sipa/caracterizacion/caracterizacion-familia-indigena.service';
import { UtilitiesAnibalService } from '../../../services/utilities-anibal';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { GestionIntegrantesComponentEventResponse } from './identificacion/gestionintegrantes/gestionintegrantes.component';
import { GestionClasificacionFamiliarComponentEventResponse } from './diagnosticofamiliar/clasificacionfamiliar/clasificacionfamiliar.component';
import { GestionApgarComponentEventResponse } from './diagnosticofamiliar/apgar/apgar.component';
import { GestionEcomapaComponentEventResponse } from './diagnosticofamiliar/ecomapa/ecomapa.component';
import { GestionFactorProtectorComponentEventResponse } from './diagnosticofamiliar/factorprotector/factorprotector.component';
import { GestionRemisionesComponentEventResponse } from './diagnosticofamiliar/remisiones/remisiones.component';
import { GestionProcesoRiesgoComponentEventResponse } from './diagnosticofamiliar/procesoriesgo/procesoriesgo.component';
import { GestionNutricionesComponentEventResponse } from './nutricioncapacitacion/nutricion/nutricion.component';
import { GestionCapacitacionesComponentEventResponse } from './nutricioncapacitacion/capacitacion/capacitacion.component';
import { GestionSeguimientoesComponentEventResponse } from './seguimiento/gestionseguimientos/gestionseguimientos.component';

@Component({
  selector: 'app-caracterizacion',
  templateUrl: './caracterizacion.component.html',
  styleUrls: ['./caracterizacion.component.css'],
  providers: [ToastrService, CaracterizacionFamiliaIndigenaService]
})
export class CaracterizacionComponent implements OnInit {

  constructor(
    private _toastr: ToastrService,
    private _caracterizacionFamiliaIndigenaService: CaracterizacionFamiliaIndigenaService,
    private route: ActivatedRoute,
  ) { }

  public TabActivo: string = "Identificacion";
  public IsCollapsedNovedades = true;
  public CambiarTab(nuevoTabActivo: string) {
    this.TabActivo = nuevoTabActivo;
  }

  public FamiliaIndigena: FamiliaIndigenaParaCaracterizacion = new FamiliaIndigenaParaCaracterizacion();
  public NotificacionGestionIntegrantes: GestionIntegrantesComponentEventResponse = new GestionIntegrantesComponentEventResponse();
  public NotificacionGestionClasificacionFamiliar: GestionClasificacionFamiliarComponentEventResponse = new GestionClasificacionFamiliarComponentEventResponse();
  public NotificacionGestionApgar: GestionApgarComponentEventResponse = new GestionApgarComponentEventResponse()
  public NotificacionGestionEcomapa: GestionEcomapaComponentEventResponse = new GestionEcomapaComponentEventResponse()
  public NotificacionGestionFactorProtector: GestionFactorProtectorComponentEventResponse = new GestionFactorProtectorComponentEventResponse()
  public NotificacionGestionRemisiones: GestionRemisionesComponentEventResponse = new GestionRemisionesComponentEventResponse();
  public NotificacionGestionProcesoRiesgo: GestionProcesoRiesgoComponentEventResponse = new GestionProcesoRiesgoComponentEventResponse();
  public NotificacionGestionNutricion: GestionNutricionesComponentEventResponse = new GestionNutricionesComponentEventResponse();
  public NotificacionGestionCapacitacion: GestionCapacitacionesComponentEventResponse = new GestionCapacitacionesComponentEventResponse();
  public NotificacionGestionSeguimiento: GestionSeguimientoesComponentEventResponse = new GestionSeguimientoesComponentEventResponse();

  ngOnInit() {
    UtilitiesAnibalService.ShowLoading();
    this._caracterizacionFamiliaIndigenaService.ConsultarFamiliaIndigenaPorNumero(this.route.snapshot.paramMap.get('numeroFamiliaIndigena'))
      .subscribe(response => {
        UtilitiesAnibalService.HideLoading();
        this.FamiliaIndigena = response.FamiliaIndigena;
      }, err => {
        window.history.back();
        UtilitiesAnibalService.HideLoading();
      });
  }

  public EventNotificacionGestionIntegrantes(notificacionGestionIntegrantes: GestionIntegrantesComponentEventResponse): void {
    this.NotificacionGestionIntegrantes = notificacionGestionIntegrantes;
  }

  public EventNotificacionGestionClasificacionFamiliar(notificacionGestionClasificacionFamiliar: GestionClasificacionFamiliarComponentEventResponse): void {
    this.NotificacionGestionClasificacionFamiliar = notificacionGestionClasificacionFamiliar;
  }

  public EventNotificacionGestionApgar(notificacionGestionApgar: GestionApgarComponentEventResponse): void {
    this.NotificacionGestionApgar = notificacionGestionApgar;
  }

  public EventNotificacionGestionEcomapa(notificacionGestionEcomapa: GestionEcomapaComponentEventResponse): void {
    this.NotificacionGestionEcomapa = notificacionGestionEcomapa;
  }

  public EventNotificacionGestionFactorProtector(notificacionGestionFactorProtector: GestionFactorProtectorComponentEventResponse): void {
    this.NotificacionGestionFactorProtector = notificacionGestionFactorProtector;
  }

  public EventNotificacionGestionRemisiones(notificacionGestionRemisiones: GestionRemisionesComponentEventResponse): void {
    this.NotificacionGestionRemisiones = notificacionGestionRemisiones;
  }

  public EventNotificacionGestionProcesoRiesgo(notificacionGestionProcesoRiesgo: GestionProcesoRiesgoComponentEventResponse): void {
    this.NotificacionGestionProcesoRiesgo = notificacionGestionProcesoRiesgo;
  }

  public EventNotificacionGestionNutricion(notificacionGestionNutricion: GestionNutricionesComponentEventResponse): void {
    this.NotificacionGestionNutricion = notificacionGestionNutricion;
  }

  public EventNotificacionGestionCapacitacion(notificacionGestionCapacitacion: GestionCapacitacionesComponentEventResponse): void {
    this.NotificacionGestionCapacitacion = notificacionGestionCapacitacion;
  }

  public EventNotificacionGestionSeguimiento(notificacionGestionSeguimiento: GestionSeguimientoesComponentEventResponse): void {
    this.NotificacionGestionSeguimiento = notificacionGestionSeguimiento;
  }
}
