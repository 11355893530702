import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  GestionNoticiaService,
  ConsultarNoticiaRequest,
  ConsultarNoticiaResponse,
  NoticiaTable,
  RegistrarNoticiaRequest,
  DocumentoNoticiaRequest,
  ImagenNoticiaRequest,
  CambiarEstadoNoticiaRequest,
  EstablecerNoticiaPrincipalRequest
} from '../../../services/configuracion/gestionnoticia/gestion-noticia.service';
import { ToastrService } from 'ngx-toastr';
import { CellClickedTableGeneric } from '../../../components/anibal/data-table-generic/data-table-generic.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Noticia } from '../../../entities/configuracion/noticia';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UtilitiesAnibalService } from '../../../services/utilities-anibal';
import { ApisRestPublic } from '../../../system/apisrest';

@Component({
  selector: 'app-gestionnoticia',
  templateUrl: './gestionnoticia.component.html',
  styleUrls: ['./gestionnoticia.component.css'],
  providers: [GestionNoticiaService, ToastrService]
})
export class GestionnoticiaComponent implements OnInit {

  public fileTypes = UtilitiesAnibalService.ExtensionesArchivosSoportados;
  public imageTypes = UtilitiesAnibalService.ExtensionesImagenesSoportadas;

  private FechaInicial = new Date();
  public RangoFecha: Date[];
  private FechaFinal = new Date();

  constructor(
    private _gestionNoticiaService: GestionNoticiaService,
    private _toastr: ToastrService,
    private _modalService: BsModalService,
    private _router: Router) {
    this.FechaFinal = new Date(this.FechaFinal.setMonth(this.FechaFinal.getMonth() + 1));
    this.FechaInicial = new Date(this.FechaInicial.setDate(-1));
    //this.RangoFecha = [this.FechaInicial, this.FechaFinal];
    this.ConsultarNoticia();
  }

  ngOnInit() {

  }
  public disabledButton: boolean = false;
  private modalRef: BsModalRef;

  public CargandoTablaNoticia: boolean = true;

  public Request: ConsultarNoticiaRequest = { Estado: "Activo", FechaFinal: null, FechaInicial: null, Descripcion: "", Principal: null, Titulo: "" };
  public RequestPrincipalCombo: string;

  public RequestNoticia: RegistrarNoticiaRequest = this.InicializarRequestNoticia();

  public Noticias: Array<NoticiaTable>;

  public DocumentoSoporteNoticia: any;
  public ImagenNoticia: any;
  public Columnas: Array<any> = [
    { title: 'Id', name: 'Id' },
    { title: 'Principal', name: 'BadgePrincipal', classNameRow: 'text-center', replaceNameInExportFor: 'Principal' },
    { title: 'Título', name: 'Titulo', classNameRow: 'cortar-texto-3' },
    { title: 'Subtítulo', name: 'Subtitulo', classNameRow: 'cortar-texto-3' },
    { title: 'Descripción', name: 'Descripcion', classNameRow: 'cortar-texto-3' },
    { title: 'Fecha', name: 'FechaPipe' },
    { title: 'Estado', name: 'EstadoBadge', classNameRow: 'text-center', replaceNameInExportFor: 'Estado' },
    { title: 'Documento', name: 'ApiDocumentoNoticia', classNameRow: 'text-center', hideInExport: true },
    { title: 'Imagen', name: 'ApiImagenNoticia', classNameRow: 'text-center', hideInExport: true },
    { title: '', name: 'BotonCambiarEstadoNoticia', classNameRow: 'text-center', hideInExport: true },
    { title: '', name: 'BotonEstablecerComoPrincipal', classNameRow: 'text-center', hideInExport: true },
  ];

  private InicializarRequestNoticia(): RegistrarNoticiaRequest {
    return { Descripcion: "", Documentos: null, Imagenes: null, Subtitulo: "", Titulo: "", PoseeVideoYoutube: false, Principal: false, UrlVideoYoutube:"" };
  }

  public ConsultarNoticia(): void {
    this.CargandoTablaNoticia = true;
    this.Noticias = [];
    if (this.RangoFecha) {
      this.Request.FechaInicial = this.RangoFecha[0];
      this.Request.FechaFinal = this.RangoFecha[1];
    }
    if (this.RequestPrincipalCombo && this.RequestPrincipalCombo == "Principal") {
      this.Request.Principal = true;
    } else if (this.RequestPrincipalCombo && this.RequestPrincipalCombo == "NoPrincipal") {
      this.Request.Principal = false;
    } else {
      this.Request.Principal = null;
    }
    this._gestionNoticiaService.ConsultarNoticias(this.Request).subscribe(response => {
      this.CargandoTablaNoticia = false;
      this.Noticias = response.ListaNoticia;
      for (let noticia of this.Noticias) {
        noticia.FechaPipe = new DatePipe("es-CO").transform(noticia.Fecha, 'dd/MM/yyyy');
        if (noticia.Estado == "Activo") {
          noticia.EstadoBadge = '<span class="badge badge-success">' + noticia.Estado + '</span>';
        } else {
          noticia.EstadoBadge = '<span class="badge badge-danger">' + noticia.Estado + '</span>';
        }
        if (noticia.Documentos.length > 0) {
          noticia.ApiDocumentoNoticia = '<a href="' + ApisRestPublic.GetDownloadDocumentoSoporteNoticiaById + noticia.Documentos[0].Id + '" target="_blank" class="btn btn-xs btn-white"><i class="fa fa-download"></i> Descargar</a>';
        } else {
          noticia.ApiDocumentoNoticia = "";
        }
        if (noticia.Imagenes.length > 0) {
          noticia.ApiImagenNoticia = '<img src="' + ApisRestPublic.GetDownloadImagenNoticiaById + noticia.Imagenes[0].Id + '" class="img-sm">';
        } else {
          noticia.ApiImagenNoticia = "";
        }
        if (noticia.Estado == "Activo") {
          noticia.BotonCambiarEstadoNoticia = '<a href="javascript:0;" title="Inactivar Noticia ' + noticia.Titulo + '"  class="btn btn-xs btn-danger"><i class="fa fa-times-circle"></i> Inactivar</a>';
        } else {
          noticia.BotonCambiarEstadoNoticia = '<a href="javascript:0;" title="Activar Noticia ' + noticia.Titulo + '" class="btn btn-xs btn-primary"><i class="fa fa-check-circle"></i> Activar</a>';
        }
        if (noticia.Principal) {
          noticia.BadgePrincipal = '<span class="badge badge-primary"> Principal </span>';
          noticia.BotonEstablecerComoPrincipal = '<a href="javascript:0;" title="Establecer Noticia ' + noticia.Titulo + ' Como Normal" class="btn btn-xs btn-success"><i class="fa fa-eye-slash"></i> Normal</a>';
        } else {
          noticia.BadgePrincipal = '';
          noticia.BotonEstablecerComoPrincipal = '<a href="javascript:0;" title="Establecer Noticia ' + noticia.Titulo + ' Como Principal" class="btn btn-xs btn-primary"><i class="fa fa-eye"></i> Principal</a>';
        }
      }
    }, err => {
      this.CargandoTablaNoticia = false;
    });
  }


  onChange(event) {
    if (event.srcElement.files && event.srcElement.files[0]) {
      var extension = event.srcElement.files[0].name.split('.').pop().toLowerCase();
      var nombreDocumento = event.srcElement.files[0].name;
      var isSuccess = this.fileTypes.indexOf(extension) > -1;  //is extension in acceptable types
      if (isSuccess) { //yes
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.RequestNoticia.Documentos = new Array<DocumentoNoticiaRequest>();
          this.RequestNoticia.Documentos[0] = {
            Documento: event.target.result,
            ExtensionDocumento: extension,
            NombreDocumento: nombreDocumento
          };
        };

        reader.readAsDataURL(event.srcElement.files[0]);
      }
      else { //no
        this.DocumentoSoporteNoticia = null;
        this._toastr.warning("La extension " + extension + " No esta soportada");
      }
    }
  }

  onChangeImagen(event) {
    if (event.srcElement.files && event.srcElement.files[0]) {
      var extension = event.srcElement.files[0].name.split('.').pop().toLowerCase();
      var nombreDocumento = event.srcElement.files[0].name;
      var isSuccess = this.imageTypes.indexOf(extension) > -1;  //is extension in acceptable types
      if (isSuccess) { //yes
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.RequestNoticia.Imagenes = new Array<DocumentoNoticiaRequest>();
          this.RequestNoticia.Imagenes[0] = {
            Documento: event.target.result,
            ExtensionDocumento: extension,
            NombreDocumento: nombreDocumento
          };
        };

        reader.readAsDataURL(event.srcElement.files[0]);
      }
      else { //no
        this.ImagenNoticia = null;
        this._toastr.warning("La extension " + extension + " No esta soportada");
      }
    }
  }

  public OpenModal(template: TemplateRef<any>) {
    this.disabledButton = false;
    this.modalRef = this._modalService.show(template);
  }

  public RegistrarNoticia(user): void {
    this.disabledButton = true;
    this._gestionNoticiaService.RegistrarNoticias(this.RequestNoticia).subscribe(response => {
      this.disabledButton = false;
      this.modalRef.hide();
      this.RequestNoticia = this.InicializarRequestNoticia();
      this._toastr.success(response.Mensaje, "Correcto!");
      this.ConsultarNoticia();
    }, err => {
      this.disabledButton = false;
    });
  }


  public ClickFila(data: CellClickedTableGeneric): any {
    if (data.Columna === "BotonCambiarEstadoNoticia") {
      if (data.Fila.Estado == "Activo") {
        this.CambiarEstadoNoticia(data.Fila.Id, "Inactivo");
      } else {
        this.CambiarEstadoNoticia(data.Fila.Id, "Activo");
      }
    } else if (data.Columna === "BotonEstablecerComoPrincipal") {
      if (data.Fila.Principal) {
        this.EstablecerComoPrincipalNoticia(data.Fila.Id, false);
      } else {
        this.EstablecerComoPrincipalNoticia(data.Fila.Id, true);
      }
    }
  }

  private peticionCambiarEstadoEnProceso = false;

  private CambiarEstadoNoticia(NoticiaId: number, Estado: string): void {
    if (!this.peticionCambiarEstadoEnProceso) {
      this.peticionCambiarEstadoEnProceso = true;
      let requestCambiarEstado: CambiarEstadoNoticiaRequest = { Estado: Estado, NoticiaId: NoticiaId };
      this._gestionNoticiaService.CambiarEstadoNoticia(requestCambiarEstado).subscribe(response => {
        this.peticionCambiarEstadoEnProceso = false;
        this._toastr.success(response.Mensaje, "Correcto!");
        this.ConsultarNoticia();
      }, err => {
        this.peticionCambiarEstadoEnProceso = false;
      });
    } else {
      this._toastr.success("Hay una petición en cola... por favor espere!!", "Advertencia!");
    }
  }

  private peticionEstablecerPrincipalEnProceso = false;

  private EstablecerComoPrincipalNoticia(NoticiaId: number, Principal: boolean): void {
    if (!this.peticionCambiarEstadoEnProceso) {
      this.peticionEstablecerPrincipalEnProceso = true;
      let requestEstablecerPrincipal: EstablecerNoticiaPrincipalRequest = { Principal: Principal, NoticiaId: NoticiaId };
      this._gestionNoticiaService.EstablecerComoPrincipalNoticia(requestEstablecerPrincipal).subscribe(response => {
        this.peticionEstablecerPrincipalEnProceso = false;
        this._toastr.success(response.Mensaje, "Correcto!");
        this.ConsultarNoticia();
      }, err => {
        this.peticionEstablecerPrincipalEnProceso = false;
      });
    } else {
      this._toastr.success("Hay una petición en cola... por favor espere!!", "Advertencia!");
    }
  }
}
