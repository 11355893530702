import { Injectable } from '@angular/core';
import { GetAllUsersResponse } from './entities/get-all-users-response';
import { HttpService } from '../../http/http.service';
import { ApisRestGestionUsers } from '../../../system/apisrest';
import { UtilitiesAnibalService } from '../../utilities-anibal';

import { Observable } from 'rxjs';
import { RegistroUsuarioRequest } from '../../../views/security/registrousuario/entities/registro-usuario';

@Injectable()
export class GestionUsersService {

  constructor(private _httpService: HttpService) { }

  public GetAllUsuarios(): Observable<GetAllUsersResponse> {
    UtilitiesAnibalService.Console("Consultando Usuarios Registrados");
    return this._httpService.get<GetAllUsersResponse>(ApisRestGestionUsers.GetAllUsers);
  }

  public RegistrarUsuario(request: RegistroUsuarioRequest): Observable<any> {
    UtilitiesAnibalService.Console("Registrando usuario");
    return this._httpService.post<any>(ApisRestGestionUsers.PostRegisterUser, request);
  }

}
