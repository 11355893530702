import { Injectable } from '@angular/core';
import { HttpService } from '../../http/http.service';
import { UtilitiesAnibalService } from '../../utilities-anibal';
import { Observable } from 'rxjs';
import { ApisRestInformesSipa } from '../../../system/apisrest';
import { EnumerationObject } from '../../../entities/general/enumeration-object';

@Injectable({
  providedIn: 'root'
})
export class InformesService {

  constructor(private _httpService: HttpService) { }

  public GetDatosFormulario(): Observable<InformesDatosFormulario> {
    UtilitiesAnibalService.Console("Consultando datos del formulario de informes");
    return this._httpService.get<InformesDatosFormulario>(ApisRestInformesSipa.GetDatosFormulario);
  }

  public GetInformeCaracterizacion(): Observable<InformeCaracterizacionPorCadaAsentamientoResponse> {
    UtilitiesAnibalService.Console("Consulta el informe de caracterización por cada asentamiento");
    return this._httpService.get<InformeCaracterizacionPorCadaAsentamientoResponse>(ApisRestInformesSipa.GetInformeCaracterizacion);
  }

  public GetInformeEcomapa(CodigoEcomapa: string): Observable<InformeEcomapaPorAsentamientosResponse> {
    UtilitiesAnibalService.Console("Consulta el informe de ecomapa " + CodigoEcomapa + " por cada asentamiento");
    return this._httpService.get<InformeEcomapaPorAsentamientosResponse>(ApisRestInformesSipa.GetInformeEcomapa(CodigoEcomapa));
  }
}

export class InformesDatosFormulario {
  public Ecomapas: Array<EnumerationObject>;
}

export class InformeCaracterizacionPorCadaAsentamientoResponse {
  public InformeAsentamientos: Array<InformeAsentamientosFamiliasCaracterizadas>;
}

export class InformeAsentamientosFamiliasCaracterizadas {
  public Nombre: string;
  public CantidadFamilias: number;
  public Gestantes: number;
  public DesnutridosOEnRiesgo: number;
  public Capacitaciones: number;
  public Seguimientos: number;
}


export class InformeEcomapaPorAsentamientosResponse {
  public InformeAsentamientos: Array<InformeEcomapaPorAsentamientos>;
}

export class InformeEcomapaPorAsentamientos {
  public AsentamientoNombre: string;
  public NombrePregunta: string;
  public Cantidad: number;
}
