import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { PqrsService } from '../../../services/public/pqrs/pqrs.service';
import { RegistroPqrsRequest, DocumentoSoportePqr, RegistroPqrsResponse } from '../../../services/public/pqrs/entities/pqrs';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UtilitiesAnibalService } from '../../../services/utilities-anibal';

@Component({
  selector: 'app-pqrs',
  templateUrl: './pqrs.component.html',
  styleUrls: ['./pqrs.component.css'],
  providers: [PqrsService, ToastrService, FormBuilder]
})
export class PqrsComponent implements OnInit {

  public fileTypes = UtilitiesAnibalService.ExtensionesArchivosSoportados;

  constructor(
    private _pqrService: PqrsService,
    private _toastr: ToastrService,
    private _modalService: BsModalService,
    private _router: Router,
    private _formBuilder: FormBuilder) {
  }

  public disabledButton: boolean = false;

  public Request: RegistroPqrsRequest;

  public NumeroRadicado: string;
  public TipoPqrsParaMensaje: string;

  @ViewChild('ModalNumeroRadicado') public templateref: TemplateRef<any>;
  private modalRef: BsModalRef;

  private InicializarRequest(): RegistroPqrsRequest {
    return {
      Descripcion: "",
      TipoPqr: "",
      Tercero: {
        Apellidos: "",
        Direccion: "",
        Id: 0,
        Identificacion: "",
        Nombres: "",
        Sexo: "M",
        Telefono: null,
        TipoIdentificacion: "",
        CorreoElectronico: "",
        NombreCompleto: "",
        Ciudad: "",
        Departamento: "",
        FechaExpedicion: null,
        Movil1: null,
        Pais: "Colombia",
        RazonSocial: "",
      },
      DocumentosSoporte: null
    };
  }

  ngOnInit() {
    this.Request = this.InicializarRequest();
  }

  public DocumentoSoporte: any;

  onChange(event) {
    if (event.srcElement.files && event.srcElement.files[0]) {
      var extension = event.srcElement.files[0].name.split('.').pop().toLowerCase();
      var nombreDocumento = event.srcElement.files[0].name;
      var isSuccess = this.fileTypes.indexOf(extension) > -1;  //is extension in acceptable types
      if (isSuccess) { //yes
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.Request.DocumentosSoporte = new Array<DocumentoSoportePqr>();
          this.Request.DocumentosSoporte[0] = {
            Documento: event.target.result,
            ExtensionDocumento: extension,
            NombreDocumento: nombreDocumento
          };
        };

        reader.readAsDataURL(event.srcElement.files[0]);
      }
      else { //no
        this.DocumentoSoporte = null;
        this._toastr.warning("La extension " + extension + " No esta soportada");
      }
    }
  }


  public RegistroPqr(formPqr: any): void {
    this.disabledButton = true;
    this._pqrService.RegistroPqr(this.Request).subscribe(response => {
      this.disabledButton = false;
      this.TipoPqrsParaMensaje = this.Request.TipoPqr;
      this.Request = this.InicializarRequest();
      this.NumeroRadicado = response.NumeroRadicado;
      //this._modalService.config.class = "modal-dialog modal-sm";
      this.modalRef = this._modalService.show(this.templateref, { class: "modal-sm" });
      this._toastr.success(response.Mensaje, "Correcto!!");
    }, err => {
      this.disabledButton = false;
    });
  }

}
