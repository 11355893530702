import { Injectable } from '@angular/core';
import { HttpServiceAllonimous, ShowMensajeErrorHttp } from '../../http/http.service';
import { ApisRestConsultaPqr } from '../../../system/apisrest';

import { Observable } from 'rxjs';
import { Pqrs } from '../../../entities/pqr/pqr';
import { UtilitiesAnibalService } from '../../utilities-anibal';

@Injectable()
export class ConsultaPqrsService extends ShowMensajeErrorHttp{

    constructor(private _httpServiceAllonimous: HttpServiceAllonimous) {
        super();
    }

    public GetPqrsByNumeroRadicado(numeroRadicado: string): Observable<ConsultaPqrsByRadicadoResponse> {
        UtilitiesAnibalService.Console("Consultando pqrs " + numeroRadicado + "sistema Public");
        return this._httpServiceAllonimous.get<ConsultaPqrsByRadicadoResponse>(ApisRestConsultaPqr.GetAllonimousPqrsByNumeroRadicado + "/" + numeroRadicado);
    }
}

export class ConsultaPqrsByRadicadoResponse {
    public Pqrs: Pqrs;
}
