import { Component, OnInit, Input, Output, ViewChild, EventEmitter, SimpleChanges } from '@angular/core';
import { PublicService } from '../../../services/general/public/public.service';
import { Tercero } from '../../../entities/general/tercero';
import { Departamento, Municipio } from '../../../entities/general/departamento';
import * as moment from 'moment';

@Component({
  selector: 'app-detalle-tercero',
  templateUrl: './detalle-tercero.component.html',
  styleUrls: ['./detalle-tercero.component.css'],
  providers: [PublicService]
})
export class DetalleTerceroComponent implements OnInit {

  public Departamentos: Array<Departamento>;
  public Municipios: Array<Municipio>;

  constructor(private _publicService: PublicService) {
  }

  ngOnInit() {
  }

  private _Tercero: Tercero;
  @Input()
  set Tercero(tercero: Tercero) {
    this._Tercero = tercero;
    if (tercero && tercero.Departamento) {
      this.ConsultarDepartamentos(true);
    } else {
      this.ConsultarDepartamentos(false);
    }
    if (this._Tercero.FechaExpedicion) {
      this._Tercero.FechaExpedicion = moment(this._Tercero.FechaExpedicion).format("YYYY-MM-DD");
    }
  }

  get Tercero(): Tercero {
    return this._Tercero;
  }

  private _DeshabilitarInputs: boolean;
  @Input()
  set DeshabilitarInputs(deshabilitar: boolean) {
    this._DeshabilitarInputs = deshabilitar;
  }

  get DeshabilitarInputs(): boolean {
    return this._DeshabilitarInputs;
  }

  private _Identificacion: string;
  @Input()
  set Identificacion(identificacion: string) {
    if (identificacion) {
      this._Identificacion = identificacion;
    }
  }

  get Identificacion(): string {
    return this._Identificacion;
  }

  @Output() public GetTercero: EventEmitter<Tercero> = new EventEmitter();

  @Output() public ChangeIdentificacion = new EventEmitter();

  public CambioIdentificacion($event): void {
    this.ChangeIdentificacion.emit(this.Tercero.Identificacion);
  }

  @Output() public ChangeCorreoElectronico = new EventEmitter();

  public CambioCorreoElectronico($event): void {
    this.ChangeCorreoElectronico.emit(this.Tercero.CorreoElectronico);
  }

  private ConsultarDepartamentos(consultarMunicipios: boolean): void {
    this._publicService.ConsultarDepartamentos().subscribe(response => {
      this.Departamentos = response.Departamentos;
      if (consultarMunicipios) {
        this.ConsultarMunicipios();
      }
    }, err => {
      this.Departamentos = null;
    });
  }

  public ConsultarMunicipios(): void {
    let DepartamentoSeleccionado = this.Departamentos.filter(departamento => departamento.NombreDepartamento == this.Tercero.Departamento);
    this._publicService.ConsultarMunicipios(DepartamentoSeleccionado[0].Id).subscribe(response => {
      this.Municipios = response.Municipios;
    }, err => {
      this.Departamentos = null;
    });
  }
}
