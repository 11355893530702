<div class="row">
  <div class="col-lg-4">
    <button type="button" class="btn btn-info btn-sm pull-left" (click)="NuevoIntegrante()"><i class="fa fa-plus-square-o"></i> Nueva remisión</button>
  </div>
  <div class="col-lg-4 text-center">
    <h1>Remisiones de la familia</h1>
  </div>
  <div class="col-lg-4">
    <button type="button" class="btn btn-primary btn-sm pull-right" (click)="Guardar()"><i class="fa fa-save (alias)"></i> Guardar</button>
  </div>
</div>
<div class="hr-line-dashed"></div>
<div class="row">
  <div class="col-lg-12">
    <div class="table-responsive">
      <table class="table table-striped table-bordered">
        <thead>
          <tr role="row">
            <th class="text-center-cell static" style="height: 125px;padding: 54px;">Nombre completo</th>
            <th class="text-center-cell first-col">Identificación</th>
            <th class="text-center-cell">Sexo</th>
            <th class="text-center-cell">Mamu</th>
            <th class="text-center-cell">Autoridad</th>
            <th class="text-center-cell">Cuidado de butizaku</th>
            <th class="text-center-cell">Sobandero</th>
            <th class="text-center-cell">Botanico</th>
            <th class="text-center-cell">Otra medicina tradicional</th>
            <th class="text-center-cell">Nutrición</th>
            <th class="text-center-cell">Consulta Medica</th>
            <th class="text-center-cell">Consulta Odontologica</th>
            <th class="text-center-cell">Vacunacion</th>
            <th class="text-center-cell">Control Prenatal</th>
            <th class="text-center-cell">Crecimiento Desarrollo</th>
            <th class="text-center-cell">Adulto Joven</th>
            <th class="text-center-cell">Planificacion Familiar</th>
            <th class="text-center-cell">Citologia</th>
            <th class="text-center-cell">Adulto mayor</th>
            <th class="text-center-cell">Otro</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let remision of RequestGestion.Remisiones">
            <td class="static">
              <input type="text" style="height: 64px;" disabled class="form-control" placeholder="Nombre completo" [(ngModel)]="remision.NombreCompletoIntegrante">
            </td>
            <td class="first-col" title="{{remision.FamiliaIndigenaIntegranteId}}">
              <input type="text" class="form-control ancho-3" (blur)="ConsultarIntegranteParaRemision(remision)" placeholder="Identificación" [(ngModel)]="remision.IdentificacionIntegrante">
            </td>
            <td>
              {{remision.SexoIntegrante}}
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="remision.Mamu{{remision.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="remision.Mamu">
                <label for="remision.Mamu{{remision.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="remision.Autoridad{{remision.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="remision.Autoridad">
                <label for="remision.Autoridad{{remision.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="remision.CuidadoraDeButizaku{{remision.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="remision.CuidadoraDeButizaku">
                <label for="remision.CuidadoraDeButizaku{{remision.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="remision.Sobandero{{remision.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="remision.Sobandero">
                <label for="remision.Sobandero{{remision.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="remision.Botanico{{remision.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="remision.Botanico">
                <label for="remision.Botanico{{remision.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <textarea rows="3" class="ancho-4" placeholder="Otra medicina tradicional" [(ngModel)]="remision.OtraMedicinaTradicional"></textarea>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="remision.Nutricion{{remision.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="remision.Nutricion">
                <label for="remision.Nutricion{{remision.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="remision.ConsultaMedica{{remision.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="remision.ConsultaMedica">
                <label for="remision.ConsultaMedica{{remision.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="remision.ConsultaOdontologica{{remision.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="remision.ConsultaOdontologica">
                <label for="remision.ConsultaOdontologica{{remision.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="remision.Vacunacion{{remision.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="remision.Vacunacion">
                <label for="remision.Vacunacion{{remision.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="remision.ControlPrenatal{{remision.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="remision.ControlPrenatal">
                <label for="remision.ControlPrenatal{{remision.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="remision.CrecimientoDesarrollo{{remision.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="remision.CrecimientoDesarrollo">
                <label for="remision.CrecimientoDesarrollo{{remision.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="remision.AdultoJoven{{remision.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="remision.AdultoJoven">
                <label for="remision.AdultoJoven{{remision.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="remision.PlanificacionFamiliar{{remision.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="remision.PlanificacionFamiliar">
                <label for="remision.PlanificacionFamiliar{{remision.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" [disabled]="remision.SexoIntegrante=='M'" id="remision.Citologia{{remision.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="remision.Citologia">
                <label for="remision.Citologia{{remision.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="remision.AdultoMayor{{remision.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="remision.AdultoMayor">
                <label for="remision.AdultoMayor{{remision.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <textarea rows="3" class="ancho-4" placeholder="Otro " [(ngModel)]="remision.Otro"></textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
