<div class="row">
  <div class="col-lg-4">
    <button type="button" class="btn btn-info btn-sm pull-left" (click)="NuevoIntegrante()"><i class="fa fa-plus-square-o"></i> Nuevo integrante</button>
  </div>
  <div class="col-lg-4 text-center">
    <h1>Integrantes de la familia</h1>
  </div>
  <div class="col-lg-4">
    <button type="button" class="btn btn-primary btn-sm pull-right" (click)="Guardar()"><i class="fa fa-save (alias)"></i> Guardar</button>
  </div>
</div>
<div class="hr-line-dashed"></div>
<div class="row">
  <div class="col-lg-12">
    <div class="table-responsive">
      <table class="table table-striped table-bordered">
        <thead>
          <tr role="row">
            <th class="text-center-cell static" style="height: 125px;padding: 54px;">Nombre completo</th>
            <th class="text-center-cell first-col">Identificación</th>
            <th class="text-center-cell ancho-1">Tipo identificación</th>
            <th class="text-center-cell ancho-2">Sexo</th>
            <th class="text-center-cell ancho-3">Aseguramiento</th>
            <th class="text-center-cell ancho-1">Parentesco</th>
            <th class="text-center-cell">Estado civil</th>
            <th class="text-center-cell">Fecha de nacimiento</th>
            <th class="text-center-cell">Edad</th>
            <th class="text-center-cell">Tipo edad</th>
            <th class="text-center-cell">Escolaridad</th>
            <th class="text-center-cell">Victima del Conflicto Armado</th>
            <th class="text-center-cell">Ocupación</th>
            <th class="text-center-cell">Es gestante</th>
            <th class="text-center-cell">Cuidado del embarazo (Ancestral)</th>
            <th class="text-center-cell">Cuidado del embarazo (Occidental)</th>
            <th class="text-center-cell">Asiste al cuidado de los menores de 10 años</th>
            <th class="text-center-cell">Estado nutricional</th>
            <th class="text-center-cell"><span tooltip="Solo para mayores de 1 año" placement="right">Vacunas esquema completo</span></th>
            <th class="text-center-cell"><span tooltip="Solo para mayores de 1 año" placement="right">Vacunas esquema incompleto</span></th>
            <th class="text-center-cell"><span tooltip="Solo para menores de 1 año" placement="right">Vacunas esquema adecuado</span></th>
            <th class="text-center-cell"><span tooltip="Solo para menores de 1 año" placement="right">Vacunas esquema inadecuado</span></th>
            <th class="text-center-cell">Vacunas a embarazadas</th>
            <th class="text-center-cell">Vacunas mujeres edad fertil</th>
            <th class="text-center-cell">Vacunas adulto joven</th>
            <th class="text-center-cell">Vacunas tercera edad</th>
            <th class="text-center-cell">Asiste al cuidado de adulto mayor</th>
            <th class="text-center-cell">Asiste al cuidado de adulto joven</th>
            <th class="text-center-cell">Asiste al cuidado de planificación familiar</th>
            <th class="text-center-cell">Tipo planitificación familiar</th>
            <th class="text-center-cell">Se ha realizado alguna citologia</th>
            <th class="text-center-cell">Discapacidad</th>
            <th class="text-center-cell">Malformaciones congenitas</th>
            <th class="text-center-cell">Otra enfermedad</th>
            <th class="text-center-cell">Toma de presión arterial</th>
            <th class="text-center-cell">Causal de fallecimiento de algun familiar</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let integrante of RequestGestion.Integrantes">
            <td class="static">
              <input type="text" style="height: 64px;" class="form-control" placeholder="Nombre completo" [(ngModel)]="integrante.NombreCompleto">
            </td>
            <td class="first-col">
              <input type="text" class="form-control ancho-3" placeholder="Identificación" (blur)="ConsultarIntegrantes(integrante)" [(ngModel)]="integrante.Identificacion">
            </td>
            <td>
              <select class="form-control" [(ngModel)]="integrante.TipoIdentificacion">
                <option value="">Seleccione una opción</option>
                <option value="{{tipoIdentificacion.Value}}" *ngFor="let tipoIdentificacion of TiposIdentificacion">{{tipoIdentificacion.Value}}</option>
              </select>
            </td>
            <td>
              <select class="form-control ancho-1" [(ngModel)]="integrante.Sexo">
                <option value="">Seleccione una opción</option>
                <option value="F">F</option>
                <option value="M">M</option>
              </select>
            </td>
            <td>
              <!--<input type="text" class="form-control" placeholder="Aseguramiento" [(ngModel)]="integrante.Aseguramiento">-->
              <textarea rows="3" class="ancho-4" placeholder="Aseguramiento" [(ngModel)]="integrante.Aseguramiento"></textarea>
            </td>
            <td>
              <select class="form-control ancho-3" [(ngModel)]="integrante.Parentesco">
                <option value="">Seleccione una opción</option>
                <option value="{{parentesco.Value}}" *ngFor="let parentesco of Parentescos">{{parentesco.DisplayName}}</option>
              </select>
            </td>
            <td>
              <select class="form-control ancho-2" [(ngModel)]="integrante.EstadoCivil">
                <option value="">Seleccione una opcion</option>
                <option value="Soltero">Soltero</option>
                <option value="Casado">Casado</option>
                <option value="Separado">Separado</option>
                <option value="UnionLibre">Union libre</option>
                <option value="Viudo">Viudo(a)</option>
              </select>
            </td>
            <td>
              <input type="date" class="form-control" placeholder="Fecha de nacimiento" [(ngModel)]="integrante.FechaNacimiento">
            </td>
            <td>
              <input type="number" class="form-control ancho-1" placeholder="Edad" [(ngModel)]="integrante.EdadValor">
            </td>
            <td>
              <select class="form-control ancho-2" [(ngModel)]="integrante.TipoEdad">
                <option value="">Seleccione una opcion</option>
                <option value="Años">Años</option>
                <option value="Meses">Meses</option>
                <option value="Dias">Dias</option>
              </select>
            </td>
            <td>
              <!--<input type="text" class="form-control" placeholder="Escolaridad" [(ngModel)]="integrante.Escolaridad">-->
              <textarea rows="3" class="ancho-4" placeholder="Escolaridad" [(ngModel)]="integrante.Escolaridad"></textarea>
            </td>
            <td>
              <!--<input type="text" class="form-control" placeholder="Victima del Conflicto Armado" [(ngModel)]="integrante.ConocimientosAncestrales">-->
              <textarea rows="3" class="ancho-4" placeholder="Victima del Conflicto Armado" [(ngModel)]="integrante.ConocimientosAncestrales"></textarea>
            </td>
            <td>
              <!--<input type="text" class="form-control" placeholder="Ocupación" [(ngModel)]="integrante.Ocupacion">-->
              <textarea rows="3" class="ancho-4" placeholder="Ocupación" [(ngModel)]="integrante.Ocupacion"></textarea>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" [disabled]="integrante.Sexo=='M'" id="integrante.EsGestante{{integrante.Numero}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="integrante.EsGestante">
                <label for="integrante.EsGestante{{integrante.Numero}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" [disabled]="integrante.Sexo=='M'" id="integrante.CuidadosDelEmbarazoAncestral{{integrante.Numero}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="integrante.CuidadosDelEmbarazoAncestral">
                <label for="integrante.CuidadosDelEmbarazoAncestral{{integrante.Numero}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" [disabled]="integrante.Sexo=='M'" id="integrante.CuidadosDelEmbarazoOccidental{{integrante.Numero}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="integrante.CuidadosDelEmbarazoOccidental">
                <label for="integrante.CuidadosDelEmbarazoOccidental{{integrante.Numero}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="integrante.AsisteCuidadoDeLosMenoresDe10Year{{integrante.Numero}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="integrante.AsisteCuidadoDeLosMenoresDe10Year">
                <label for="integrante.AsisteCuidadoDeLosMenoresDe10Year{{integrante.Numero}}"></label>
              </div>
            </td>
            <td>
              <select class="form-control ancho-3" [(ngModel)]="integrante.EstadoNutricional">
                <option value="">Seleccione una opcion</option>
                <option value="Adecuado">Adecuado</option>
                <option value="EnRiesgo">En riesgo</option>
                <option value="Desnutrido">Desnutrido</option>
              </select>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="integrante.PoseeVacunasEsquemaCompleto{{integrante.Numero}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="integrante.PoseeVacunasEsquemaCompleto">
                <label for="integrante.PoseeVacunasEsquemaCompleto{{integrante.Numero}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="integrante.PoseeVacunasEsquemaIncompleto{{integrante.Numero}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="integrante.PoseeVacunasEsquemaIncompleto">
                <label for="integrante.PoseeVacunasEsquemaIncompleto{{integrante.Numero}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="integrante.PoseeVacunasEsquemaAdecuado{{integrante.Numero}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="integrante.PoseeVacunasEsquemaAdecuado">
                <label for="integrante.PoseeVacunasEsquemaAdecuado{{integrante.Numero}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="integrante.PoseeVacunasEsquemaInadecuado{{integrante.Numero}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="integrante.PoseeVacunasEsquemaInadecuado">
                <label for="integrante.PoseeVacunasEsquemaInadecuado{{integrante.Numero}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" [disabled]="integrante.Sexo=='M'" id="integrante.PoseeVacunasEmbarazadaRenuente{{integrante.Numero}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="integrante.PoseeVacunasEmbarazadaRenuente">
                <label for="integrante.PoseeVacunasEmbarazadaRenuente{{integrante.Numero}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" [disabled]="integrante.Sexo=='M'" id="integrante.PoseeVacunasMujeresEnEdadFertil{{integrante.Numero}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="integrante.PoseeVacunasMujeresEnEdadFertil">
                <label for="integrante.PoseeVacunasMujeresEnEdadFertil{{integrante.Numero}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="integrante.PoseeVacunasAdultoJoven{{integrante.Numero}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="integrante.PoseeVacunasAdultoJoven">
                <label for="integrante.PoseeVacunasAdultoJoven{{integrante.Numero}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="integrante.PoseeVacunasTerceraEdad{{integrante.Numero}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="integrante.PoseeVacunasTerceraEdad">
                <label for="integrante.PoseeVacunasTerceraEdad{{integrante.Numero}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="integrante.AsisteAlCuidadoDeAdultoMayor{{integrante.Numero}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="integrante.AsisteAlCuidadoDeAdultoMayor">
                <label for="integrante.AsisteAlCuidadoDeAdultoMayor{{integrante.Numero}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="integrante.AsisteAlCuidadoDeAdultoJoven{{integrante.Numero}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="integrante.AsisteAlCuidadoDeAdultoJoven">
                <label for="integrante.AsisteAlCuidadoDeAdultoJoven{{integrante.Numero}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" [disabled]="integrante.Sexo=='M'" id="integrante.AsisteAlCuidadoDePlanificacionFamiliar{{integrante.Numero}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="integrante.AsisteAlCuidadoDePlanificacionFamiliar">
                <label for="integrante.AsisteAlCuidadoDePlanificacionFamiliar{{integrante.Numero}}"></label>
              </div>
            </td>
            <td>
              <select class="form-control ancho-3" [disabled]="!integrante.AsisteAlCuidadoDePlanificacionFamiliar" [(ngModel)]="integrante.TipoMetodoPlanificacionFamiliar">
                <option value="">Seleccione una opcion</option>
                <option value="Inyectable">Inyectable</option>
                <option value="Diu">Diu</option>
                <option value="Pildora">Pildora</option>
                <option value="Ritmo">Ritmo</option>
                <option value="Implante">Implante</option>
                <option value="Tradicional">Tradicional</option>
                <option value="Preservativo">Preservativo</option>
              </select>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" [disabled]="integrante.Sexo=='M'" id="integrante.SeHaRealizadoAlgunaVezCitologiaVaginal{{integrante.Numero}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="integrante.SeHaRealizadoAlgunaVezCitologiaVaginal">
                <label for="integrante.SeHaRealizadoAlgunaVezCitologiaVaginal{{integrante.Numero}}"></label>
              </div>
            </td>
            <td>
              <!--<input type="text" class="form-control" placeholder="Discapacidad" [(ngModel)]="integrante.Discapacidad">-->
              <textarea rows="3" class="ancho-4" placeholder="Discapacidad" [(ngModel)]="integrante.Discapacidad"></textarea>
            </td>
            <td>
              <!--<input type="text" class="form-control" placeholder="Malformaciones congenitas" [(ngModel)]="integrante.ConMalformacionesCongenitas">-->
              <textarea rows="3" class="ancho-4" placeholder="Malformaciones congenitas" [(ngModel)]="integrante.ConMalformacionesCongenitas"></textarea>
            </td>
            <td>
              <!--<input type="text" class="form-control" placeholder="Enfermedad" [(ngModel)]="integrante.OtraEnfermedad">-->
              <textarea rows="3" class="ancho-4" placeholder="Otra enfermedad" [(ngModel)]="integrante.OtraEnfermedad"></textarea>
            </td>
            <td>
              <!--<input type="text" class="form-control" placeholder="Toma de presión arterial" [(ngModel)]="integrante.TomaDePresionArterial">-->
              <textarea rows="3" class="ancho-4" placeholder="Toma de presión arterial" [(ngModel)]="integrante.TomaDePresionArterial"></textarea>
            </td>
            <td>
              <!--<input type="text" class="form-control" placeholder="Causal de Fallecimiento" [(ngModel)]="integrante.CausalFallecimiento">-->
              <textarea rows="3" class="ancho-4" placeholder="Causal de fallecimiento" [(ngModel)]="integrante.CausalFallecimiento"></textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


<ng-template #ModalConsultaIntegrantes>
  <div class="modal-header bg-primary">
    <h4 class="modal-title pull-left">Consulta de integrante</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefConsultaIntegrantes.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <app-data-table-generic [Filas]="Integrantes"  [ShowLoading]="CargandoTablaFamiliasIndigenas" (CellClicked)="ClickFilaIntegrante($event)" [columns]="ColumnasIntegrantes"></app-data-table-generic>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default pull-right" (click)="modalRefConsultaIntegrantes.hide()">Cerrar</button>
  </div>
</ng-template>

