import { Component, OnInit, TemplateRef } from '@angular/core';
import { GestionTercerosService, TerceroTable, ConsultaParametrizadaTerceroRequest } from '../../../services/datosbasicos/gestionterceros/gestion-terceros.service';
import { EnumerationObject } from '../../../entities/general/enumeration-object';
import { ToastrService } from 'ngx-toastr';
import { DataTableGenericComponent, CellClickedTableGeneric } from '../../../components/anibal/data-table-generic/data-table-generic.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gestionterceros',
  templateUrl: './gestionterceros.component.html',
  styleUrls: ['./gestionterceros.component.css'],
  providers: [GestionTercerosService, ToastrService]
})
export class GestiontercerosComponent implements OnInit {

  constructor(
    private _gestionTercerosService: GestionTercerosService,
    private _toastr: ToastrService,
    private _modalService: BsModalService,
    private _router: Router) {

  }

  ngOnInit() {
    this.ConsultarDatosFormulario();
  }

  public Terceros: TerceroTable[];
  public CargandoTablaTerceros: boolean = true;
  public RequestConsultaParametrizada: ConsultaParametrizadaTerceroRequest = { CorreoElectronico: "", Direccion: "", Identificacion: "", Movil1: "", Movil2: "", NombreCompleto: "", TipoTercero: "" }


  public Columnas: Array<any> = [
    { title: 'Id', name: 'Id', classNameRow: 'text-right' },
    { title: 'Tipo Identificación', className: 'text-center', classNameRow: 'text-right', name: 'TipoIdentificacion' },
    { title: 'Identificación', className: 'text-center', classNameRow: 'text-right', name: 'Identificacion' },
    { title: 'Fecha expedición', className: 'text-center', classNameRow: 'text-right', name: 'FechaExpedicion' },
    { title: 'Nombre Completo', name: 'NombreCompleto', },
    { title: 'Email', name: 'CorreoElectronico' },
    { title: 'Departamento', name: 'Departamento' },
    { title: 'Ciudad', name: 'Ciudad' },
    { title: 'Dirección', name: 'Direccion' },
    { title: 'Movil o telefono', name: 'Telefono', classNameRow: 'text-center' },
    { title: 'Tipo', name: 'Tipo', classNameRow: 'text-center', hideInExport: true },
    { title: 'Estado', name: 'EstadoBadge', classNameRow: 'text-center', replaceNameInExportFor: 'Estado' }
  ];

  public ConsultarTerceros(): void {
    this.CargandoTablaTerceros = true;
    this._gestionTercerosService.ConsultarTodasLasVigencias(this.RequestConsultaParametrizada).subscribe(response => {
      this.CargandoTablaTerceros = false;
      this.Terceros = response.Terceros;
      for (let tercero of this.Terceros) {
        if (tercero.Estado == "Activo") {
          tercero.EstadoBadge = '<span class="badge badge-success">' + tercero.Estado + '</span>';
        } else {
          tercero.EstadoBadge = '<span class="badge badge-danger">' + tercero.Estado + '</span>';
        }
        tercero.FechaExpedicion = new DatePipe("es-CO").transform(tercero.FechaExpedicion, 'yyyy-MM-dd');
      }
    }, err => {
      this.CargandoTablaTerceros = false;
    });
  }

  public TiposTerceros: Array<EnumerationObject> = [];

  private ConsultarDatosFormulario(): void {
    this._gestionTercerosService.ConsultarDatosFormulario().subscribe(response => {
      this.TiposTerceros = response.TiposTerceros;
      this.ConsultarTerceros();
    }, err => {
      this.CargandoTablaTerceros = false;
    });
  }
}
