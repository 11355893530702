<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h5>Familias indigenas <small class="m-l-sm"> Consulta parametrizada</small></h5>
        </div>
        <div class="ibox-content ">
          <div class="row">
            <div class="col-lg-8">
              <div class="col-lg-3">
                <div class="form-group">
                  <label>Número</label>
                  <input type="text" [(ngModel)]="RequestConsultaParametrizada.Numero" name="Numero" required placeholder="Numero" class="form-control">
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label>Municipio</label>
                  <input type="text" [(ngModel)]="RequestConsultaParametrizada.Municipio" name="Municipio" required placeholder="Municipio" class="form-control">
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label>Asentamiento</label>
                  <input type="text" [(ngModel)]="RequestConsultaParametrizada.Asentamiento" name="Asentamiento" required placeholder="Asentamiento" class="form-control">
                </div>
              </div>
              <div class="col-lg-3">
                <div class="form-group">
                  <label>Sector</label>
                  <input type="text" [(ngModel)]="RequestConsultaParametrizada.Sector" name="Sector" required placeholder="Sector" class="form-control">
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="col-lg-4 MarginTopForBottom">
                <button class="btn btn-primary " (click)="ConsultarFamiliasIndigenas()" type="button"><i class="fa fa-search"></i>&nbsp;Consultar</button>
              </div>
              <div class="col-lg-4 MarginTopForBottom">
                <button class="btn btn-warning" (click)="OpenModalConsultaIntegrantes()" type="button"><i class="fa fa-sitemap"></i>&nbsp;Verificar</button>
              </div>
              <div class="col-lg-4 MarginTopForBottom">
                <button class="btn btn-info" (click)="OpenModalRegistroFamiliaIndigena()" type="button"><i class="fa fa-paste"></i>&nbsp;Nuevo</button>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-data-table-generic [NameReport]="'Informe de familias indigenas registradas en el sistema'" (CellClicked)="ClickFila($event)" [Filas]="FamiliasIndigenas" [ShowLoading]="CargandoTablaFamiliasIndigenas" [columns]="Columnas"></app-data-table-generic>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #ModalRegistrarFamiliaIndigena>
  <div class="modal-header bg-primary">
    <h4 class="modal-title pull-left">Registro de familia indigena</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefRegistroFamiliaIndigena.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form ngNativeValidate #FormRegistrarFamiliaIndigena="ngForm" (ngSubmit)="RegistrarFamiliaIndigena(FormRegistrarFamiliaIndigena)">
    <div class="modal-body">
      <div class="row">
        <p>Estimado usuario por favor antes de registrar verifique que la familia que desea registrar no exista.</p>
      </div>
      <div class="row">
        <div class="col-lg-4">
            <div class="form-group">
              <label>Municipio</label>
              <select class="form-control" id="MunicipioSelect" [(ngModel)]="RequestRegistroFamiliaIndigena.MunicipioId" name="MunicipioSelect" required (change)="ConsultarAsentamientos()">
                <option value="{{municipio.Id}}" *ngFor="let municipio of MunicipiosConAsentamientos">{{municipio.NombreMunicipio}}</option>
              </select>
            </div>
        </div>
        <div class="col-lg-4">
          <div class="form-group">
            <label>Asentamiento</label>
            <select class="form-control" id="AsentamientoSelect" [(ngModel)]="RequestRegistroFamiliaIndigena.AsentamientoId" name="AsentamientoSelect" required (change)="ConsultarSectores()"> 
              <option value="{{asentamiento.Id}}" *ngFor="let asentamiento of Asentamientos">{{asentamiento.Nombre}}</option>
            </select>
          </div>
        </div>
        <div class="col-lg-4">
          <div class="form-group">
            <label>Sector</label>
            <select class="form-control" id="SectorSelect" [(ngModel)]="RequestRegistroFamiliaIndigena.SectorId" name="SectorSelect" required>
              <option value="{{sector.Id}}" *ngFor="let sector of Sectores">{{sector.Nombre}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="form-group">
            <label>Observación</label>
            <textarea rows="10" name="Descripcion" [(ngModel)]="RequestRegistroFamiliaIndigena.Observacion" style="width: 100%;"></textarea>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="UltimaFamiliaRegistrada">
        <p class="m-l-sm">
          Número de la ultima familia registrada: <strong class="text-navy" style="cursor:pointer;" (click)="AbrirCaracterizacionFamiliaDesdeRegistro(UltimaFamiliaRegistrada)">{{UltimaFamiliaRegistrada}}</strong>
        </p>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary pull-left" type="submit" [disabled]="disabledButtonRegistroFamiliaIndigena"><i class="fa fa-check"></i>&nbsp;Registrar</button>
      <button type="button" class="btn btn-default pull-right" (click)="modalRefRegistroFamiliaIndigena.hide()">Cerrar</button>
    </div>
  </form>
</ng-template>


<ng-template #ModalConsultaIntegrantes>
  <div class="modal-header bg-primary">
    <h4 class="modal-title pull-left">Consulta de integrante</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefConsultaIntegrantes.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form ngNativeValidate #FormConsultarIntegrantes="ngForm" (ngSubmit)="ConsultarIntegrantes(IdentificacionIntegranteParaVerificar)">
    <div class="modal-body">
      <div class="row">
        <p>Estimado usuario aquí podra consultar cualquier persona, y rastrear en que familia(s) se encuentra registrado.</p>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="form-group">
            <label>Identificación</label>
            <input type="text" [(ngModel)]="IdentificacionIntegranteParaVerificar" name="IdentificacionIntegranteParaVerificar" required placeholder="Identificación" class="form-control">
          </div>
        </div>
        <div class="col-lg-6 MarginTopForBottom">
          <button class="btn btn-primary pull-left" type="submit"><i class="fa fa-search"></i>&nbsp;Consultar</button>
        </div>
      </div>
      <div class="row" *ngIf="Integrantes.length>0">
        <app-data-table-generic [Filas]="Integrantes" [ShowLoading]="CargandoTablaFamiliasIndigenas" (CellClicked)="ClickFilaIntegrantes ($event)"[columns]="ColumnasIntegrantes"></app-data-table-generic>
      </div>
    </div>
  </form>
  <div class="modal-footer">
    <button type="button" class="btn btn-default pull-right" (click)="modalRefConsultaIntegrantes.hide()">Cerrar</button>
  </div>
</ng-template>
