import { Injectable } from '@angular/core';
import { ChangePasswordRequest, ChangePasswordResponse } from './entities/change-password';
import { HttpService } from '../../http/http.service';
import { ApisRestChangePassword } from '../../../system/apisrest';

import { Observable } from 'rxjs';
import { UtilitiesAnibalService } from '../../utilities-anibal';
import { GetUserResponse } from '../../../services/security/general/entities/get-user-response';

@Injectable()
export class ChangePasswordService {

  constructor(private _httpService: HttpService) { }

  public CambiarPassword(_request: ChangePasswordRequest, apiPostChangePassword:string): Observable<ChangePasswordResponse> {
    UtilitiesAnibalService.Console("Cambiando Contraseña");
    return this._httpService.post<ChangePasswordResponse>(apiPostChangePassword, _request);
  }

  public GetDatosUsuario(userName: string): Observable<GetUserResponse> {
    UtilitiesAnibalService.Console("Consultando Usuario");
    return this._httpService.get<GetUserResponse>(ApisRestChangePassword.GetUser + "/" + userName);
  }

  public GetApiPostChangePasswordForAdmin(): string {
    return ApisRestChangePassword.PostChangePassword;
  }
}
