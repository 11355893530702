import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AuditoriaService } from '../../../services/security/auditoria/auditoria.service';
import { AuditoriaTable } from '../../../entities/security/auditoria';
import { AuditoriaDatosBasicos, ConsultarAuditoriaRequest } from '../../../services/security/auditoria/entities/auditoria';
import { ToastrService } from 'ngx-toastr';
import { DataTableGenericComponent, CellClickedTableGeneric } from '../../../components/anibal/data-table-generic/data-table-generic.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-auditoria',
  templateUrl: './auditoria.component.html',
  styleUrls: ['./auditoria.component.css'],
  providers: [AuditoriaService, ToastrService]
})
export class AuditoriaComponent implements OnInit {

  private FechaInicial = new Date();
  public RangoFecha: Date[];
  private FechaFinal = new Date();

  constructor(
    private _auditoriaService: AuditoriaService,
    private _toastr: ToastrService,
    private _modalService: BsModalService,
    private _router: Router) {
    //this.FechaFinal = new Date(this.FechaFinal.setMonth(this.FechaFinal.getMonth() + 1));
    //this.RangoFecha = [this.FechaInicial, this.FechaFinal];
  }

  ngOnInit() {
    this.ConsultarDatosBasicos();
  }
  public disabledButton: boolean = false;
  private modalRef: BsModalRef;

  public Tablas: string[];
  public Modulos: string[];
  public Acciones: string[];
  public CargandoTablaAuditoria: boolean = true;
  public Request: ConsultarAuditoriaRequest = { Evento: "", FechaFinal: null, FechaInicial: null, Id: "", Interactor: "", Modulo: "", Tabla: "", Usuario: "" };

  public Auditorias: Array<AuditoriaTable>;
  public DetalleAuditoria: DetalleAuditoria = { Detalles: null, NombreTabla: "", RegistroId: "" };

  public objectKeys = Object.keys;
  @ViewChild('ModalVerDetalles') _modalTemplateRef: TemplateRef<any>;

  public Columnas: Array<any> = [
    { title: 'Modulo', name: 'Module', filtering: { filterString: '', placeholder: 'Buscar...' } },
    { title: 'Accion', className: 'text-center', name: 'Interactor', filtering: { filterString: '', placeholder: 'Buscar...' } },
    { title: 'Fecha', name: 'Fecha', filtering: { filterString: '', placeholder: 'Buscar...' } },
    { title: 'Hora', name: 'Hora', filtering: { filterString: '', placeholder: 'Buscar...' } },
    { title: 'Usuario', name: 'NombreUsuario', filtering: { filterString: '', placeholder: 'Buscar...' } },
    { title: 'Tabla', sort: '', name: 'TableName', filtering: { filterString: '', placeholder: 'Buscar...' } },
    { title: 'Columna', name: 'ColumnName', filtering: { filterString: '', placeholder: 'Buscar...' } },
    { title: 'Registro', name: 'RecordID', filtering: { filterString: '', placeholder: 'Buscar...' } },
    { title: 'Evento', name: 'Evento', filtering: { filterString: '', placeholder: 'Buscar...' } },
    { title: 'Valor Original', name: 'OriginalValue', filtering: { filterString: '', placeholder: 'Buscar...' } },
    { title: 'Valor Nuevo', name: 'ShowDetail', filtering: { filterString: '', placeholder: 'Buscar...' } },
    { title: 'Fecha UTC', name: 'FechaUTC', filtering: { filterString: '', placeholder: 'Buscar...' } },
    { title: 'IP', name: 'IpAddress', filtering: { filterString: '', placeholder: 'Buscar...' } },
    { title: 'HOST', name: 'HostName', filtering: { filterString: '', placeholder: 'Buscar...' } }
  ];

  private ConsultarDatosBasicos(): void {
    this.CargandoTablaAuditoria = true;
    this._auditoriaService.ConsultarDatosBasicos().subscribe(response => {
      this.CargandoTablaAuditoria = false;
      this.Tablas = response.Tablas;
      this.Acciones = response.Acciones;
      this.Modulos = response.Modulos;
    }, err => {
      this.CargandoTablaAuditoria = false;
    });
  }

  public ConsultarAuditoria(): void {
    this.CargandoTablaAuditoria = true;
    this.Auditorias = [];
    if (this.RangoFecha) {
      this.Request.FechaInicial = this.RangoFecha[0];
      this.Request.FechaFinal = this.RangoFecha[1];
    }
    this._auditoriaService.ConsultarAuditoria(this.Request).subscribe(response => {
      this.CargandoTablaAuditoria = false;
      this.Auditorias = response.Auditorias;
      for (let auditoria of this.Auditorias) {
        if (auditoria.ColumnName == "*ALL") {
          auditoria.ShowDetail = '<a href="javascript:void(0);" title="Ver Detalles" ><i class="fa fa-newspaper-o" aria-hidden="true">';
        } else {
          auditoria.ShowDetail = auditoria.NewValue;
        }
        auditoria.Fecha = new DatePipe("es-CO").transform(auditoria.EventDateLocalTime, 'dd/MM/yyyy');
        auditoria.Hora = new DatePipe("es-CO").transform(auditoria.EventDateLocalTime, 'HH:mm:ss');
        auditoria.FechaUTC = new DatePipe("es-CO").transform(auditoria.EventDateUTC, 'dd/MM/yyyy');
      }
    }, err => {
      this.CargandoTablaAuditoria = false;
    });
  }

  private OpenModal(template: TemplateRef<any>) {
    this.disabledButton = false;
    this.modalRef = this._modalService.show(template, { class: 'modal-lg' });
  }

  public ClickFila(data: CellClickedTableGeneric): any {
    if (data.Columna === "ShowDetail" && data.Fila.ColumnName == "*ALL") {
      this.AbrirModal(data.Fila.TableName, data.Fila.RecordID, data.Fila.NewValue);
    }
  }

  private AbrirModal(NombreTabla: string, RegistroId: string, JSONValue: string): void {
    this.DetalleAuditoria.Detalles = JSON.parse(JSONValue);
    this.DetalleAuditoria.NombreTabla = NombreTabla;
    this.DetalleAuditoria.RegistroId = RegistroId;
    this.OpenModal(this._modalTemplateRef);
  }

}


export class DetalleAuditoria {
  public NombreTabla: string;
  public RegistroId: string;
  public Detalles: any;
}
