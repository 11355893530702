<div *ngIf="Usuario && Tercero">
    <div class="col-lg-12">
        <div class="widget-head-color-box navy-bg p-lg text-center">
            <div class="m-b-md">
                <h2 class="font-bold no-margins">
                    {{Usuario.NombreCompleto}}
                </h2>
                <!-- <small>{{Usuario.Identificacion}}</small>-->
            </div>
            <img src="assets/images/a4.jpg" class="img-circle circle-border m-b-md" alt="profile">

            <div>
                <span>{{Usuario.Email}}</span> |
                <span>{{Usuario.Identificacion}}</span> |
                <span>{{Tercero.Telefono}}</span>
            </div>
        </div>
        <div class="widget-text-box">
            <div class="row">
                <div class="col-lg-6 col-sm-12 col-md-6">
                    <div class="panel panel-info">
                        <div class="panel-heading">
                            <button class="btn btn-primary " (click)="GuardarPermiso()" [disabled]="disabledButton" type="button"><i class="fa fa-save"></i>&nbsp;Guardar</button>
                            <span class="pull-right MarginLabel">Permisos</span>
                        </div>
                        <div class="panel-body">
                            <div class="table-responsive">
                                <table class="table table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th class="text-center"></th>
                                            <th class="text-center">Titulo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let permiso of Permisos">
                                            <td>
                                                <div class="text-center radio radio-success">
                                                    <input id="{{permiso.Rol}}" type="radio" value="{{permiso.Rol}}" [(ngModel)]="PermisoInicial">
                                                    <label for="{{permiso.Rol}}"></label>
                                                </div>
                                            </td>
                                            <td title="{{permiso.Descripcion}}">{{permiso.Titulo}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-sm-12 col-md-6">
                    <div class="panel panel-success">
                        <div class="panel-heading">
                            Información del tercero
                        </div>
                        <div class="panel-body">
                            <div class="row col-sm-12">
                                <app-detalle-tercero [Tercero]="Tercero" [DeshabilitarInputs]="true"></app-detalle-tercero>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>