import { Injectable } from '@angular/core';
import { HttpService } from '../../http/http.service';
import { ApisRestResponderPqrs } from '../../../system/apisrest';

import { Observable } from 'rxjs';
import { Pqrs } from '../../../entities/pqr/pqr';
import { UtilitiesAnibalService } from '../../utilities-anibal';

@Injectable()
export class ResponderPqrsService {

  constructor(private _httpService: HttpService) { }

  public GetPqrsById(pqrsId: string): Observable<ConsultaPqrsByIdResponse> {
    UtilitiesAnibalService.Console("Consultando pqrs " + pqrsId + "sistema");
    return this._httpService.get<ConsultaPqrsByIdResponse>(ApisRestResponderPqrs.GetPqrsById + "/" + pqrsId);
  }

  public RegistrarRespuesta(request: ResponderPqrsRequest): Observable<ResponderPqrsResponse> {
    UtilitiesAnibalService.Console("Registrando respuesta Pqrs" + request.PqrsId);
    return this._httpService.post<ResponderPqrsResponse>(ApisRestResponderPqrs.PostRegistrarRespuestaPqrs, request);
  }
}

export class ConsultaPqrsByIdResponse {
  public Pqrs: Pqrs;
}

export class ResponderPqrsRequest {
  public Descripcion: string;
  public PqrsId: number;
  public DocumentosSoporte: Array<DocumentoSoporteRespuestaPqr>;
}

export class DocumentoSoporteRespuestaPqr {
  public ExtensionDocumento: string;
  public NombreDocumento: string;
  public Documento: any;
}

export class ResponderPqrsResponse {
  public Mensaje: string;
}
