import { Injectable } from '@angular/core';
import { FamiliaIndigenaSeguimiento } from '../../../../entities/sipa/familiaindigena';
import { Observable } from 'rxjs';
import { HttpService } from '../../../http/http.service';
import { UtilitiesAnibalService } from '../../../utilities-anibal';
import { ApisRestCaracterizacionFamiliaIndigenaGestionSeguimiento } from '../../../../system/apisrest';

@Injectable()
export class SeguimientoFamiliaIndigenaService {

  constructor(private _httpService: HttpService) { }

  public GetConsultarDatosFormulario(familiaIndigenaId: number): Observable<GestionseguimientosDatosFormularioResponse> {
    UtilitiesAnibalService.Console("Consultando seguimientos de la familia (Datos formulario gestion)" + familiaIndigenaId);
    return this._httpService.get<GestionseguimientosDatosFormularioResponse>(ApisRestCaracterizacionFamiliaIndigenaGestionSeguimiento.GetConsultarDatosFormulario(familiaIndigenaId));
  }

  public RegistroSeguimientoFamilia(request: GestionseguimientosRequest): Observable<GestionseguimientosResponse> {
    UtilitiesAnibalService.Console("Registro seguimiento de la familia" + request.FamiliaIndigenaId);
    return this._httpService.post<GestionseguimientosResponse>(ApisRestCaracterizacionFamiliaIndigenaGestionSeguimiento.PostRegistroSeguimiento, request);
  }

  public GetConsultarSeguimiento(familiaIndigenaSeguimientoId: number): Observable<ConsultaseguimientoIndividualResponse> {
    UtilitiesAnibalService.Console("Consultando seguimiento " + familiaIndigenaSeguimientoId);
    return this._httpService.get<ConsultaseguimientoIndividualResponse>(ApisRestCaracterizacionFamiliaIndigenaGestionSeguimiento.GetConsultarSeguimiento(familiaIndigenaSeguimientoId));
  }

  public GestionSeguimientoFamilia(request: GestionseguimientoIndividualRequest): Observable<GestionseguimientoIndividualResponse> {
    UtilitiesAnibalService.Console("Gestion seguimiento de la familia" + request.FamiliaIndigenaSeguimientoId);
    return this._httpService.post<GestionseguimientoIndividualResponse>(ApisRestCaracterizacionFamiliaIndigenaGestionSeguimiento.PostGestionSeguimiento, request);
  }
}

export class ConsultaseguimientoIndividualResponse {
  public Seguimiento: FamiliaIndigenaSeguimiento = new FamiliaIndigenaSeguimiento();
}

export class GestionseguimientoIndividualRequest {
  public FamiliaIndigenaSeguimientoId: number;
  public Seguimiento: FamiliaIndigenaSeguimiento = new FamiliaIndigenaSeguimiento();
}

export class GestionseguimientoIndividualResponse {
  public Mensaje: string;
}

export class GestionseguimientosResponse {
  public Mensaje: string;
}

export class GestionseguimientosRequest {
  public FamiliaIndigenaId: number;
  public Seguimiento: FamiliaIndigenaSeguimiento = new FamiliaIndigenaSeguimiento();
}

export class GestionseguimientosDatosFormularioResponse {
  public Seguimientos: Array<FamiliaIndigenaSeguimientoTable> = new Array<FamiliaIndigenaSeguimientoTable>();
}

export class FamiliaIndigenaSeguimientoTable extends FamiliaIndigenaSeguimiento {
  public FechaTable: string;
  public BotonGestionar: string;
}
