import { Injectable } from '@angular/core';
import { HttpService } from '../../http/http.service';
import { UtilitiesAnibalService } from '../../utilities-anibal';
import { Observable } from 'rxjs';
import { ApisRestHealthApplication } from '../../../system/apisrest';

@Injectable()
export class HealthApplicationService {

    constructor(private _httpService: HttpService) { }

    public GetProcess(): Observable<ConsultaProcesosWindowsResponse> {
        UtilitiesAnibalService.Console("Consultando procesos del sistema (Sistema operativo)");
        return this._httpService.get<ConsultaProcesosWindowsResponse>(ApisRestHealthApplication.GetAllProcess);
    }

    public KillProcess(processName: string): Observable<string> {
        UtilitiesAnibalService.Console("Kill process " + processName);
        return this._httpService.get<string>(ApisRestHealthApplication.GetKillProcess(processName));
    }

}

export class ConsultaProcesosWindowsResponse {
    public Process: Array<ProcessWindows> = new Array<ProcessWindows>();
}

export class ProcessWindows {
    public Id: number;
    public ProcessName: string;
    public Threads: number;

    public BotonKillProceso: string;
}
