<div class="row">
  <div class="col-lg-12">
    <div class="m-b-md">
      <button type="button" class="btn btn-primary btn-sm pull-right" (click)="Guardar()"><i class="fa fa-save (alias)"></i> Guardar</button>
      <h2 class="center">IDENTIFICACIÓN DE PROCESOS DE RIESGOS EN LA FAMILIA</h2>
    </div>
  </div>
  <div class="col-lg-12" *ngIf="RequestGestion && RequestGestion.ProcesosRiesgos.length>0 && RequestGestion.ProcesosRiesgos[0].Id==0">
    <div class="alert alert-warning">
      <p>Estimado usuario por favor recuerde dar click en guardar para que el cuestionario presente sea almacenado en el sistema. </p>
      <small class="text-danger">Este mensaje sera vigente hasta tanto usted no guarde el formulario.</small>
    </div>
  </div>
</div>
<div class="row m-t-sm" *ngIf="RequestGestion && RequestGestion.ProcesosRiesgos.length>0">
  <div class="col-lg-12">
    <div class="table-responsive">
      <table class="table table-striped table-bordered">
        <thead>
          <tr>
            <th class="text-center">Procesos</th>
            <th class="text-center">Satisfactores</th>
            <th class="text-center">Respuesta</th>
            <th class="text-center">Observación</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let procesoriesgo of RequestGestion.ProcesosRiesgos">
            <tr>
              <td [attr.rowspan]="procesoriesgo.Cuestionarios.length" style="vertical-align: middle;text-align: center;" class="text-left" placement="top">
                <strong>{{procesoriesgo.NombreProcesoRiesgo}}</strong>
              </td>
              <td class="text-left" placement="top">
                {{procesoriesgo.Cuestionarios[0].NombrePregunta}}
              </td>
              <td class="text-left" placement="top">
                <div class="text-center checkbox checkbox-primary">
                  <input type="checkbox" ng-reflect-model="true" id="DinamycPregunta{{procesoriesgo.Cuestionarios[0].CodigoPregunta}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="procesoriesgo.Cuestionarios[0].Respuesta">
                  <label for="DinamycPregunta{{procesoriesgo.Cuestionarios[0].CodigoPregunta}}"></label>
                </div>
              </td>
              <td class="text-left" placement="top">
                <input type="text" class="form-control" placeholder="Observación" [(ngModel)]="procesoriesgo.Cuestionarios[0].Observacion">
              </td>
            </tr>
            <tr *ngFor="let cuestionario of procesoriesgo.Cuestionarios | slice:1">
              <td class="text-left" placement="top">
                {{cuestionario.NombrePregunta}}
              </td>
              <td class="text-left" placement="top">
                <div class="text-center checkbox checkbox-primary">
                  <input type="checkbox" ng-reflect-model="true" id="DinamycPregunta{{cuestionario.CodigoPregunta}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="cuestionario.Respuesta">
                  <label for="DinamycPregunta{{cuestionario.CodigoPregunta}}"></label>
                </div>
              </td>
              <td class="text-left" placement="top">
                <input type="text" class="form-control" [ngClass]="(cuestionario.Respuesta && cuestionario.SiEsTrueDebeEscribirObservacion)?'inputIluminado':''" (change)="ValidarSiTieneObservacionAndRespuestaDebeSerTrue(cuestionario)" placeholder="Observación" [(ngModel)]="cuestionario.Observacion">
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
