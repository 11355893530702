import { Component, OnInit, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FamiliaIndigena, FamiliaIndigenaFactorProtectorCuestionario } from '../../../../../entities/sipa/familiaindigena';
import { UtilitiesAnibalService } from '../../../../../services/utilities-anibal';
import { EventEmitter } from '@angular/core';
import { FactorProtectorFamiliaIndigenaService, GestionFactorProtectorRequest } from '../../../../../services/sipa/caracterizacion/diagnosticofamiliar/factor-protector-familia-indigena.service';

@Component({
  selector: 'app-factorprotector',
  templateUrl: './factorprotector.component.html',
  styleUrls: ['./factorprotector.component.css'],
  providers: [ToastrService, FactorProtectorFamiliaIndigenaService]
})
export class FactorprotectorComponent implements OnInit {

  constructor(
    private _toastr: ToastrService,
    private _FactorProtectorFamiliaIndigenaService: FactorProtectorFamiliaIndigenaService,
  ) { }

  ngOnInit() {
  }

  @Output() public NotificacionComponente: EventEmitter<GestionFactorProtectorComponentEventResponse> = new EventEmitter();
  private ObjectNotificacion: GestionFactorProtectorComponentEventResponse = new GestionFactorProtectorComponentEventResponse();

  private _FamiliaIndigena: FamiliaIndigena;
  @Input()
  set FamiliaIndigena(familia: FamiliaIndigena) {
    this._FamiliaIndigena = familia;
    if (familia && familia.Id) {
      this.RequestGestion.FamiliaIndigenaId = familia.Id;
      this.ConsultarDatosFormulario();
    }
  }

  get FamiliaIndigena(): FamiliaIndigena {
    return this._FamiliaIndigena;
  }

  public RequestGestion: GestionFactorProtectorRequest = new GestionFactorProtectorRequest();

  public Guardar(): void {
    UtilitiesAnibalService.ShowLoading();
    for (let ecomapa of this.RequestGestion.FactoresProtectores) {
      for (let cuestionario of ecomapa.Cuestionarios) {
        this.ValidarSiTieneObservacionAndRespuestaDebeSerTrue(cuestionario);
      }
    }
    this._FactorProtectorFamiliaIndigenaService.GestionFactorProtector(this.RequestGestion).subscribe(response => {
      UtilitiesAnibalService.HideLoading();
      this.ConsultarDatosFormulario();
      this._toastr.success(response.Mensaje, "Correcto!");
    }, err => {
      UtilitiesAnibalService.HideLoading();
    });
  }

  public ConsultarDatosFormulario(): void {
    this.ObjectNotificacion.EstaConsultando = true;
    this.NotificacionComponente.emit(this.ObjectNotificacion);
    this._FactorProtectorFamiliaIndigenaService.GetConsultarDatosFormulario(this._FamiliaIndigena.Id).subscribe(response => {
      this.ObjectNotificacion.EstaConsultando = false;
      this.RequestGestion.FactoresProtectores = response.FactoresProtectores;
      this.ObjectNotificacion.NoHaRegistrado = this.RequestGestion.FactoresProtectores.some(function (e) { return e.Id == 0; })
      this.NotificacionComponente.emit(this.ObjectNotificacion);
    }, err => {
    });
  }

  public ValidarSiTieneObservacionAndRespuestaDebeSerTrue(cuestionario: FamiliaIndigenaFactorProtectorCuestionario) {
    if (cuestionario.SiEsTrueDebeEscribirObservacion) {
      if (cuestionario.Observacion) {
        cuestionario.Respuesta = true;
      } else {
        cuestionario.Respuesta = false;
      }
    }
  }
}

export class GestionFactorProtectorComponentEventResponse {
  public EstaConsultando: boolean;
  public NoHaRegistrado: boolean;
}
