<div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
        <div class="col-lg-12">
            <div class="ibox float-e-margins">
                <div class="ibox-title">
                    <h5>Gestión de P.Q.R.S</h5>
                </div>
                <div class="ibox-content ">
                    <div class="row">
                        <div class="col-lg-2 col-sm-12 col-md-2">
                            <div class="form-group">
                                <label>Identificación Solicitante</label>
                                <input type="text" [(ngModel)]="Request.IdentificacionSolicitante" name="Identificacion" required placeholder="Identificacion" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-12 col-md-2">
                            <div class="form-group">
                                <label>Nombre Solicitante</label>
                                <input type="text" [(ngModel)]="Request.NombreSolicitante" name="Nombre" placeholder="Nombre Solicitante" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-12 col-md-2">
                            <div class="form-group">
                                <label>Número Radicado</label>
                                <input type="number" [(ngModel)]="Request.NumeroRadicado" name="NumeroRadicado" required placeholder="Número Radicado" class="form-control">
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-12 col-md-2">
                            <div class="form-group">
                                <label>Fecha</label>
                                <input class="form-control" #drp="bsDaterangepicker" bsDaterangepicker [(ngModel)]="RangoFecha" [bsConfig]="{dateInputFormat: 'YYYY-MM-DD' , containerClass: 'theme-blue' }">
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-12 col-md-2">
                            <div class="form-group">
                                <label>Estado</label>
                                <select class="form-control" [(ngModel)]="Request.Estado" name="Estado">
                                    <option value="">Todos</option>
                                    <option value="Pendiente">Pendiente</option>
                                    <option value="Finalizado">Finalizado</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-12 col-md-2">
                            <div class="form-group">
                                <label>Tipo</label>
                                <select class="form-control" [(ngModel)]="Request.TipoPqrs" name="TipoPqrs">
                                    <option value="">Todos</option>
                                    <option value="Peticion">Petición</option>
                                    <option value="Queja">Queja</option>
                                    <option value="Reclamo">Reclamo</option>
                                    <option value="Solicitud">Solicitud</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-2 col-sm-12 col-md-2">
                            <button class="btn btn-primary " (click)="ConsultarPqrs()" type="button"><i class="fa fa-search"></i>&nbsp;Consultar</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12 col-sm-12 col-md-12">
                            <app-data-table-generic (CellClicked)="ClickFila($event)" [Filas]="ListaPqrs" [ShowLoading]="CargandoTablaPqrs" [columns]="Columnas"></app-data-table-generic>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
