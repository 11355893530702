export class FamiliaIndigena {
  public Id: number;
  public Numero: string;
  public Municipio: string;
  public Asentamiento: string;
  public Sector: string;
  public Observacion: string;
  public Fecha: Date;
}

export class FamiliaIndigenaNovedad {
  public FamiliaIndigenaId: number;
  public NumeroFamilia: string;
  public Tipo: string;
  public Observacion: string;
  public Fecha: Date;
  public Importancia: string;
  public NombreUsuarioGenera: string;
  public IdentificacionUsuarioGenera: string;
}

export class FamiliaIndigenaIntegrante {
  public Id: number;
  public FamiliaIndigenaId: number;
  public NumeroFamilia: string;
  public Numero: number;
  public Identificacion: string;
  public TipoIdentificacion: string;
  public NombreCompleto: string;
  public Sexo: string;
  public Aseguramiento: string;
  public Parentesco: string;
  public EstadoCivil: string;
  public FechaNacimiento: any;
  public EdadValor: number;
  public TipoEdad: string;
  public EdadConBaseFecha: number;
  public Escolaridad: string;
  public ConocimientosAncestrales: string;
  public Ocupacion: string;
  public EsGestante: boolean;
  public CuidadosDelEmbarazoAncestral: boolean;
  public CuidadosDelEmbarazoOccidental: boolean;
  public AsisteCuidadoDeLosMenoresDe10Year: boolean;
  public EstadoNutricional: string;
  public PoseeVacunasEsquemaCompleto: boolean;
  public PoseeVacunasEsquemaIncompleto: boolean;
  public PoseeVacunasEsquemaAdecuado: boolean;
  public PoseeVacunasEsquemaInadecuado: boolean;
  public PoseeVacunasEmbarazadaRenuente: boolean;
  public PoseeVacunasMujeresEnEdadFertil: boolean;
  public PoseeVacunasAdultoJoven: boolean;
  public PoseeVacunasTerceraEdad: boolean;
  public AsisteAlCuidadoDeAdultoMayor: boolean;
  public AsisteAlCuidadoDeAdultoJoven: boolean;
  public AsisteAlCuidadoDePlanificacionFamiliar: boolean;
  public TipoMetodoPlanificacionFamiliar: string;
  public SeHaRealizadoAlgunaVezCitologiaVaginal: boolean;
  public Discapacidad: string;
  public ConMalformacionesCongenitas: string;
  public OtraEnfermedad: string;
  public TomaDePresionArterial: string;
  public CausalFallecimiento: string;
}

export class FamiliaIndigenaClasificacionFamiliar {
  public Id: number;
  public FamiliaIndigenaId: number;
  public NumeroFamilia: string;
  public TipoFamilia: string;
  public CicloVitalDeLaFamilia: string;
  public TipoSituacionActual: string;
  public TipoUbicacion: string;
  public CondicionActual: string;
  public Observacion: string;
}

export class FamiliaIndigenaApgar {
  public Id: number;
  public FamiliaIndigenaId: number;
  public NumeroFamilia: string;
  public Fecha: Date;
  public NombreUsuarioRealiza: string;
  public ValorApgar: number;
  public ValoracionApgar: string;
  public Observacion: string;
  public Cuestionarios: Array<FamiliaIndigenaApgarCuestionario> = new Array<FamiliaIndigenaApgarCuestionario>();
}

export class FamiliaIndigenaApgarCuestionario {
  public Id: number;
  public FamiliaIndigenaApgarId: number;
  public SipaApgarId: number;
  public NumeroFamilia: string;
  public NombrePregunta: string;
  public Valor: any;
  public CualidadPregunta: string;
  public Observacion: string;
}

export class FamiliaIndigenaEcomapa {
  public Id: number;
  public FamiliaIndigenaId: number;
  public SipaEcomapaId: number;
  public NumeroFamilia: string;
  public CodigoEcomapa: string;
  public NombreEcomapa: string;
  public Fecha: Date;
  public NombreUsuarioRealiza: string;
  public Cuestionarios: Array<FamiliaIndigenaEcomapaCuestionario> = new Array<FamiliaIndigenaEcomapaCuestionario>();
}

export class FamiliaIndigenaEcomapaCuestionario {
  public Id: number;
  public FamiliaIndigenaEcomapaId: number;
  public SipaEcomapaPreguntaId: number;
  public NumeroFamilia: string;
  public CodigoPregunta: string;
  public NombrePregunta: string;
  public Respuesta: boolean;
  public Observacion: string;
  public SiEsTrueDebeEscribirObservacion: boolean;
}

export class FamiliaIndigenaFactorProtector {
  public Id: number;
  public FamiliaIndigenaId: number;
  public SipaFactorProtectorId: number;
  public NumeroFamilia: string;
  public CodigoFactorProtector: string;
  public NombreFactorProtector: string;
  public Fecha: Date;
  public NombreUsuarioRealiza: string;
  public Cuestionarios: Array<FamiliaIndigenaFactorProtectorCuestionario> = new Array<FamiliaIndigenaFactorProtectorCuestionario>();
}

export class FamiliaIndigenaFactorProtectorCuestionario {
  public Id: number;
  public FamiliaIndigenaFactorProtectorId: number;
  public SipaFactorProtectorPreguntaId: number;
  public NumeroFamilia: string;
  public CodigoPregunta: string;
  public NombrePregunta: string;
  public Respuesta: boolean;
  public Observacion: string;
  public SiEsTrueDebeEscribirObservacion: boolean;
}

export class FamiliaIndigenaRemision {
  public Id: number;
  public FamiliaIndigenaId: number;
  public NumeroFamilia: string;
  public FamiliaIndigenaIntegranteId: number;
  public IdentificacionIntegrante: string;
  public NombreCompletoIntegrante: string;
  public SexoIntegrante: string;
  public Mamu: boolean;
  public Autoridad: boolean;
  public CuidadoraDeButizaku: boolean;
  public Sobandero: boolean;
  public Botanico: boolean;
  public OtraMedicinaTradicional: string;
  public Nutricion: boolean;
  public ConsultaMedica: boolean;
  public ConsultaOdontologica: boolean;
  public Vacunacion: boolean;
  public ControlPrenatal: boolean;
  public CrecimientoDesarrollo: boolean;
  public AdultoJoven: boolean;
  public PlanificacionFamiliar: boolean;
  public Citologia: boolean;
  public AdultoMayor: boolean;
  public Otro: string;
}

export class FamiliaIndigenaProcesoRiesgo {
  public Id: number;
  public FamiliaIndigenaId: number;
  public SipaProcesoRiesgoId: number;
  public NumeroFamilia: string;
  public CodigoProcesoRiesgo: string;
  public NombreProcesoRiesgo: string;
  public Fecha: Date;
  public NombreUsuarioRealiza: string;
  public Cuestionarios: Array<FamiliaIndigenaProcesoRiesgoCuestionario> = new Array<FamiliaIndigenaProcesoRiesgoCuestionario>();
}

export class FamiliaIndigenaProcesoRiesgoCuestionario {
  public Id: number;
  public FamiliaIndigenaProcesoRiesgoId: number;
  public SipaProcesoRiesgoPreguntaId: number;
  public NumeroFamilia: string;
  public CodigoPregunta: string;
  public NombrePregunta: string;
  public Respuesta: boolean;
  public Observacion: string;
  public SiEsTrueDebeEscribirObservacion: boolean;
}

export class FamiliaIndigenaNutricion {
  public Id: number;

  public FamiliaIndigenaId: number;
  public NumeroFamilia: string;
  public FamiliaIndigenaIntegranteId: number;
  public IdentificacionIntegrante: string;
  public NombreCompletoIntegrante: string;
  public SexoIntegrante: string;
  public EdadIntegrante: number;
  public TipoEdadIntegrante: string;
  public DespigmentacionCabello: boolean;
  public NalgasColgantes: boolean;
  public ColorPalidoOjos: boolean;
  public SequedadConjuntivalFaltaLagrimas: boolean;
  public SeNotanLasCostillas: boolean;
  public AbdomenGrandeEnFormaGlobo: boolean;
  public PerdidaTejidoGraso: boolean;
  public DetencionDelCrecimiento: boolean;
  public PosicionFetalPostramientoCama: boolean;
  public LlantoDebilSinCausaAparente: boolean;
  public ExtremidadesFrias: boolean;
  public GrasaSubcutaneaAusente: boolean;
  public Carahinchada: boolean;
  public CaraSenilEnvejecido: boolean;
  public EdemaMiembrosSuperiores: boolean;
  public EdemaMiembrosInferiores: boolean;
  public EdemaGeneralizado: boolean;
  public Anemia: boolean;
  public Observacion: string;
}

export class FamiliaIndigenaCapacitacion {
  public Id: number;
  public FamiliaIndigenaId: number;
  public SipaCapacitacionId: number;
  public NumeroFamilia: string;
  public CodigoCapacitacion: string;
  public NombreCapacitacion: string;
  public Fecha: Date;
  public Observacion: string;
}

export class FamiliaIndigenaSeguimiento {
  public Id: number;
  public FamiliaIndigenaId: number;
  public NumeroFamilia: string;
  public Fecha: Date;
  public IdentificacionUsuarioRealiza: string;
  public NombreUsuarioRealiza: string;
  public ValorSeguimiento: number;
  public ValoracionSeguimiento: string;
  public QueHacer: string;
  public Observacion: string;
  public Secciones: Array<FamiliaIndigenaSeguimientoSeccion> = new Array<FamiliaIndigenaSeguimientoSeccion>();

}

export class FamiliaIndigenaSeguimientoSeccion {
  public Id: number;
  public FamiliaIndigenaSeguimientoId: number;
  public SipaSeguimientoId: number;
  public NumeroFamilia: string;
  public CodigoSeguimiento: string;
  public NombreSeguimiento: string;
  public Fecha: Date;
  public NombreUsuarioRealiza: string;
  public Cuestionarios: Array<FamiliaIndigenaSeguimientoSeccionCuestionario> = new Array<FamiliaIndigenaSeguimientoSeccionCuestionario>();
}

export class FamiliaIndigenaSeguimientoSeccionCuestionario {
  public Id: number;
  public FamiliaIndigenaSeguimientoSeccionId: number;
  public SipaSeguimientoPreguntaId: number;
  public NumeroFamilia: string;
  public CodigoPregunta: string;
  public NombrePregunta: string;
  public Respuesta: boolean;
  public Observacion: string;
  public SiEsTrueDebeEscribirObservacion: boolean;
}
