import { Component, OnInit } from '@angular/core';
import { ProcessWindows, HealthApplicationService } from '../../../services/security/healthapplication/health-application.service';
import { UtilitiesAnibalService } from '../../../services/utilities-anibal';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogService } from '../../../components/anibal/confirmation-dialog/confirmation-dialog.service';
import { CellClickedTableGeneric } from '../../../components/anibal/data-table-generic/data-table-generic.component';

@Component({
    selector: 'app-healthapplication',
    templateUrl: './healthapplication.component.html',
    styleUrls: ['./healthapplication.component.css'],
    providers: [ToastrService, HealthApplicationService, ConfirmationDialogService]
})
export class HealthapplicationComponent implements OnInit {

    constructor(
        private _toastr: ToastrService,
        private _healthApplicationService: HealthApplicationService,
        private _confirmationDialogService: ConfirmationDialogService
    ) { }

    ngOnInit() {
        this.ConsultarProcesos();
    }

    public CargandoTablaProcesos: boolean = false;

    public Procesos: Array<ProcessWindows> = new Array<ProcessWindows>();

    public ColumnasProcesos: Array<any> = [
        { title: 'Id', name: 'Id' },
        { title: 'Process Name', name: 'ProcessName', classNameRow: 'text-left' },
        { title: 'Threads', name: 'Threads' },
        { title: 'Kill', name: 'BotonKillProceso', hideInExport: true },
    ];

    public ConsultarProcesos(): void {
        this.CargandoTablaProcesos = true;
        UtilitiesAnibalService.ShowLoading();
        this._healthApplicationService.GetProcess().subscribe(response => {
            this.CargandoTablaProcesos = false;
            UtilitiesAnibalService.HideLoading();
            this.Procesos = response.Process;
            for (let process of this.Procesos) {
                process.BotonKillProceso = '<a href="javascript:void(0);" title="Kill" ><i class="fa fa-close text-danger" aria-hidden="true">';
            }
        }, err => {
            this.CargandoTablaProcesos = false;
            UtilitiesAnibalService.HideLoading();
        });
    }

    public KillProcess(processName: string): void {
        this._confirmationDialogService.confirm('Confirmación', "Esta seguro de destruir el proceso <strong>" + processName + "</strong>?").subscribe(respuesta => {
            if (respuesta) {
                UtilitiesAnibalService.ShowLoading();
                UtilitiesAnibalService.ShowLoading();
                this._healthApplicationService.KillProcess(processName).subscribe(response => {
                    this._toastr.success(response);
                    UtilitiesAnibalService.HideLoading();
                    this.ConsultarProcesos();
                }, err => {
                    UtilitiesAnibalService.HideLoading();
                });
            }
        });
    }

    public ClickCeldaProcesos(data: CellClickedTableGeneric): any {
        if (data.Columna === "BotonKillProceso") {
            this.KillProcess(data.Fila.ProcessName);
        }
    }
}
