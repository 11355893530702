import { Routes } from "@angular/router";

import { BlankLayoutComponent } from "../components/common/layouts/blankLayout.component";

import { IndexComponent } from '../views/public/index/index.component';

export const Public: any = {
    path: '', component: BlankLayoutComponent,
    children: [
        {
            path: 'Inicio', component: IndexComponent
        }
    ]
};