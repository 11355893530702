<div class="middle-box-anibal text-center loginscreen  animated fadeInDown">
  <div>
    <h3>Ingrese el número de radicado</h3>
    <form class="m-t" role="form" action="#">
      <div class="form-group">
        <input type="text" class="form-control" [(ngModel)]="NumeroRadicado" name="NumeroRadicado" placeholder="Número Radicado" required="">
      </div>
      <button type="submit" (click)="ConsultarPqrsByNumero()" [disabled]="disabledButton" class="btn btn-primary block full-width m-b">Consultar</button>
    </form>
  </div>
</div>

<div class="row" *ngIf="Pqrs">
  <div class="col-lg-12 col-sm-12 col-md-12">
    <div class="wrapper wrapper-content animated fadeInRight">
      <div class="ibox">
        <div class="ibox-content">
          <div class="row">
            <div class="col-lg-12">
              <div class="m-b-md">
                <h2>Información {{Pqrs.TipoPqr}}</h2>
              </div>
              <dl class="dl-horizontal">
                <dt>Estado:</dt>
                <dd><span class="label " [ngClass]="Pqrs.Estado=='Pendiente'?'label-danger':Pqrs.Estado=='Finalizado'?'label-primary':'label-success'">{{Pqrs.Estado}}</span></dd>
              </dl>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-5">
              <dl class="dl-horizontal">
                <dt>Tipo Identificación:</dt>
                <dd>{{Pqrs.Tercero.TipoIdentificacion}}</dd>
                <dt>Identificación:</dt>
                <dd>{{Pqrs.Tercero.Identificacion}}</dd>
                <dt>Nombre completo:</dt>
                <dd>{{Pqrs.Tercero.NombreCompleto}}</dd>
                <dt>Correo Electronico:</dt>
                <dd><a href="mailto:{{Pqrs.Tercero.CorreoElectronico}}" class="text-navy">{{Pqrs.Tercero.CorreoElectronico}}</a></dd>
                <dt>Sexo:</dt>
                <dd>{{Pqrs.Tercero.Sexo}}</dd>
                <dt>Telefono:</dt>
                <dd>{{Pqrs.Tercero.Telefono}}</dd>
              </dl>
            </div>
            <div class="col-lg-7" id="cluster_info">
              <dl class="dl-horizontal">
                <dt>Fecha:</dt>
                <dd>{{Pqrs.Fecha | date: 'dd/MM/yyyy h:mm:ss a'}}</dd>
                <dt>Número Radicado:</dt>
                <dd><span class="label label-info">{{Pqrs.NumeroRadicado}}</span></dd>
                <dt *ngIf="Pqrs.DocumentosSoporte.length>0">Documento Soporte:</dt>
                <dd *ngIf="Pqrs.DocumentosSoporte.length>0" class="project-people">
                  <a href="{{UrlApiDownloadDocumentoSoportePqr}}{{documento.Id}}" target="_blank" *ngFor="let documento of Pqrs.DocumentosSoporte" class="btn btn-white btn-xs">
                    <i class="fa fa-download"></i> {{documento.NombreDocumento}}{{documento.ExtensionDocumento}}
                  </a>
                </dd>
              </dl>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <dl class="dl-horizontal">
                <dt>Descripción:</dt>
                <dd>
                  <pre class="well">
                    {{Pqrs.Descripcion}}
                  </pre>
                </dd>
              </dl>
            </div>
          </div>
          <div class="row m-t-sm" *ngIf="Pqrs.Respuestas.length>0">
            <div class="col-lg-12">
              <div class="panel blank-panel ui-tab">
                <div class="ng-isolate-scope">
                  <ul class="nav nav-tabs" ng-transclude="">
                    <li class="dsads uib-tab ng-scope ng-isolate-scope active" heading="Users messages" active="tab.active">
                      <a href="javascript:void(0);" uib-tab-heading-transclude="" class="ng-binding">Respuestas</a>
                    </li>
                  </ul>
                  <div class="tab-content">
                    <div class="tab-pane ng-scope active" uib-tab-content-transclude="tab">
                      <div class="feed-activity-list ng-scope">
                        <div class="feed-element" *ngFor="let respuesta of Pqrs.Respuestas">
                          <div class="media-body ">
                            <!--<small class="pull-right text-navy">5h ago</small>-->
                            Usuario Quien Responde:<strong> {{respuesta.TerceroResponde.NombreCompleto}}</strong>. <br>
                            <small class="text-muted">{{respuesta.Fecha | date: 'dd/MM/yyyy h:mm:ss a'}}</small>
                            <pre class="well">
                              {{respuesta.Descripcion}}
                            </pre>
                            <div class="actions" *ngIf="respuesta.DocumentosSoporte.length>0">
                              <a href="{{UrlApiDownloadDocumentoSoporteRespuestaPqr}}{{documento.Id}}" target="_blank" *ngFor="let documento of respuesta.DocumentosSoporte" class="btn btn-white btn-xs">
                                <i class="fa fa-download"></i> {{documento.NombreDocumento}}{{documento.ExtensionDocumento}}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="font-size: 9px;color: #cac8c8;">
            La presente información representa oficialmente la respuesta de {{AppName | uppercase}} ante su {{Pqrs.TipoPqr}}.
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #ModalResponder style="z-index:999999">
  <div class="modal-header">
    <h4 class="modal-title pull-left">Formulario de respuesta</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form ngNativeValidate #FormRegistroRespuestaPqr="ngForm" (ngSubmit)="RegistrarRespuestaPqr(FormRegistroRespuestaPqr)">
    <div class="modal-body">
      <div class="row">
        <div class="col-lg-6 col-sm-12 col-md-6">
          <div class="form-group">
            <label>Documento de soporte</label>
            <input type="file" class="form-control" name="documents" (change)="onChange($event)">
          </div>
        </div>
        <div class="col-lg-12 col-sm-12 col-md-12">
          <div class="form-group">
            <textarea rows="10" name="Descripcion" required [(ngModel)]="Request.Descripcion" style="width: 100%;"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary pull-left" type="submit" [disabled]="disabledButton"><i class="fa fa-check"></i>&nbsp;Registrar</button>
      <button type="button" class="btn btn-default pull-right" (click)="modalRef.hide()">Cerrar</button>
    </div>
  </form>
</ng-template>
