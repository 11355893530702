import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
    name: 'sumByKey'
})
export class PipeSumByKey implements PipeTransform {
    transform(items: any[], key: string): number {
        if (!items) 0;
        if (!key) return 0;
        return items.reduce((sum, item) => sum + item[""+key+""], 0);
    }
}
