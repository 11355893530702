import { Injectable } from '@angular/core';
import { FamiliaIndigenaRemision, FamiliaIndigenaIntegrante } from '../../../../entities/sipa/familiaindigena';
import { Observable } from 'rxjs';
import { HttpService } from '../../../http/http.service';
import { UtilitiesAnibalService } from '../../../utilities-anibal';
import { ApisRestCaracterizacionFamiliaIndigenaGestionRemisiones } from '../../../../system/apisrest';

@Injectable()
export class RemisionesFamiliaIndigenaService {

  constructor(private _httpService: HttpService) { }

  public GetConsultarDatosFormulario(familiaIndigenaId: number): Observable<GestionremisionesDatosFormularioResponse> {
    UtilitiesAnibalService.Console("Consultando remisiones de la familia (Datos formulario gestion)" + familiaIndigenaId);
    return this._httpService.get<GestionremisionesDatosFormularioResponse>(ApisRestCaracterizacionFamiliaIndigenaGestionRemisiones.GetConsultarDatosFormulario(familiaIndigenaId));
  }

  public GestionRemisionesFamilia(request: GestionremisionesRequest): Observable<GestionremisionesResponse> {
    UtilitiesAnibalService.Console("Gestión remisiones de la familia" + request.FamiliaIndigenaId);
    return this._httpService.post<GestionremisionesResponse>(ApisRestCaracterizacionFamiliaIndigenaGestionRemisiones.PostGestionRemisiones, request);
  }

  public GetIntegranteParaRemision(identificacion: string, familiaIndigenaId: number): Observable<ConsultaIntegranteParaRemision> {
    UtilitiesAnibalService.Console("Consultando integrante para remision de la familia" + familiaIndigenaId);
    return this._httpService.get<ConsultaIntegranteParaRemision>(ApisRestCaracterizacionFamiliaIndigenaGestionRemisiones.GetIntegranteFamilia(identificacion, familiaIndigenaId));
  }

}

/**
 * A pesar que es una lista debe devolver uno solo ya que es por familia
 * */
export class ConsultaIntegranteParaRemision {
  public Integrantes: Array<FamiliaIndigenaIntegrante> = new Array<FamiliaIndigenaIntegrante>();
}

export class GestionremisionesResponse {
  public Mensaje: string;
}

export class GestionremisionesRequest {
  public FamiliaIndigenaId: number;
  public Remisiones: Array<FamiliaIndigenaRemision> = new Array<FamiliaIndigenaRemision>();
}

export class GestionremisionesDatosFormularioResponse {
  public Remisiones: Array<FamiliaIndigenaRemision> = new Array<FamiliaIndigenaRemision>();
}

