import { Injectable } from '@angular/core';
import { HttpService } from '../../../http/http.service';
import { Observable } from 'rxjs';
import { UtilitiesAnibalService } from '../../../utilities-anibal';
import { FamiliaIndigenaEcomapa } from '../../../../entities/sipa/familiaindigena';
import { ApisRestCaracterizacionFamiliaIndigenaGestionEcomapa } from '../../../../system/apisrest';

@Injectable()
export class EcomapaFamiliaIndigenaService {

  constructor(private _httpService: HttpService) { }

  public GetConsultarDatosFormulario(familiaIndigenaId: number): Observable<GestionEcomapaDatosFormularioResponse> {
    UtilitiesAnibalService.Console("Consultando ecomapa de la familia (Datos formulario gestion)" + familiaIndigenaId);
    return this._httpService.get<GestionEcomapaDatosFormularioResponse>(ApisRestCaracterizacionFamiliaIndigenaGestionEcomapa.GetConsultarDatosFormulario(familiaIndigenaId));
  }

  public GestionEcomapa(request: GestionEcomapaRequest): Observable<GestionEcomapaResponse> {
    UtilitiesAnibalService.Console("Gestión ecomapa de la familia" + request.FamiliaIndigenaId);
    return this._httpService.post<GestionEcomapaResponse>(ApisRestCaracterizacionFamiliaIndigenaGestionEcomapa.PostGestionEcomapa, request);
  }

}

export class GestionEcomapaResponse {
  public Mensaje: string;
}

export class GestionEcomapaRequest {
  public FamiliaIndigenaId: number;
  public Ecomapas: Array<FamiliaIndigenaEcomapa> = new Array <FamiliaIndigenaEcomapa>();
}

export class GestionEcomapaDatosFormularioResponse {
  public Ecomapas: Array<FamiliaIndigenaEcomapa> = new Array<FamiliaIndigenaEcomapa>();
}
