<div class="row">
  <div class="col-lg-4">
    <button type="button" class="btn btn-info btn-sm pull-left" (click)="NuevoIntegrante()"><i class="fa fa-plus-square-o"></i> Nueva nutrición</button>
  </div>
  <div class="col-lg-4 text-center">
    <h1>Examenes nutricionales de la familia</h1>
  </div>
  <div class="col-lg-4">
    <button type="button" class="btn btn-primary btn-sm pull-right" (click)="Guardar()"><i class="fa fa-save (alias)"></i> Guardar</button>
  </div>
</div>
<div class="hr-line-dashed"></div>
<div class="row">
  <div class="col-lg-12">
    <div class="table-responsive">
      <table class="table table-striped table-bordered">
        <thead>
          <tr role="row">
            <th class="text-center-cell static" style="height: 143px;padding: 54px;">Nombre completo</th>
            <th class="text-center-cell first-col">Identificación</th>
            <th class="text-center-cell">Sexo</th>
            <th class="text-center-cell">Edad</th>
            <th class="text-center-cell">Despigmentación (Señal de bandera)</th>
            <th class="text-center-cell">Nalgas colgantes o signo de pantalón caído, marcación ósea</th>
            <th class="text-center-cell">Color pálido o amarillo en las conjuntivas</th>
            <th class="text-center-cell"> Sequedad conjuntival, falta de lágrimas</th>
            <th class="text-center-cell">Se notan fácilmente las costillas y la columna vertebral</th>
            <th class="text-center-cell">Abdomen grande, prominente, en forma de globo (Globoso)</th>
            <th class="text-center-cell">Pérdida del tejido graso (Emaciación)</th>
            <th class="text-center-cell">Detención del crecimiento (Estancamiento)</th>
            <th class="text-center-cell">Posición fetal, postramiento en cama</th>
            <th class="text-center-cell">Llanto débil y monótono sin causa aparente</th>
            <th class="text-center-cell">Extremidades frías</th>
            <th class="text-center-cell">Grasa subcutánea ausente </th>
            <th class="text-center-cell">Cara hinchada o edematosa (cara de luna)</th>
            <th class="text-center-cell">Cara senil (rostro envejecido)</th>
            <th class="text-center-cell">Edema en miembros superiores (en los brazos)</th>
            <th class="text-center-cell">Edema en miembros inferiores (En los pies, piernas, muslos)</th>
            <th class="text-center-cell">Edema generalizado (En todo el cuerpo)</th>
            <th class="text-center-cell">Palidez en las palmas de las manos y/o pies</th>
            <th class="text-center-cell">Observación</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let nutricion of RequestGestion.Nutriciones">
            <td class="static">
              <input type="text" style="height: 64px;" disabled class="form-control" placeholder="Nombre completo" [(ngModel)]="nutricion.NombreCompletoIntegrante">
            </td>
            <td class="first-col" title="{{nutricion.FamiliaIndigenaIntegranteId}}">
              <input type="text" class="form-control ancho-3" (blur)="ConsultarIntegranteParaNutricion(nutricion)" placeholder="Identificación" [(ngModel)]="nutricion.IdentificacionIntegrante">
            </td>
            <td>
              {{nutricion.SexoIntegrante}}
            </td>
            <td>
              {{nutricion.EdadIntegrante}} {{nutricion.TipoEdadIntegrante}}
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="nutricion.DespigmentacionCabello{{nutricion.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="nutricion.DespigmentacionCabello">
                <label for="nutricion.DespigmentacionCabello{{nutricion.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="nutricion.NalgasColgantes{{nutricion.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="nutricion.NalgasColgantes">
                <label for="nutricion.NalgasColgantes{{nutricion.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="nutricion.ColorPalidoOjos{{nutricion.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="nutricion.ColorPalidoOjos">
                <label for="nutricion.ColorPalidoOjos{{nutricion.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="nutricion.SequedadConjuntivalFaltaLagrimas{{nutricion.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="nutricion.SequedadConjuntivalFaltaLagrimas">
                <label for="nutricion.SequedadConjuntivalFaltaLagrimas{{nutricion.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="nutricion.SeNotanLasCostillas{{nutricion.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="nutricion.SeNotanLasCostillas">
                <label for="nutricion.SeNotanLasCostillas{{nutricion.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="nutricion.AbdomenGrandeEnFormaGlobo{{nutricion.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="nutricion.AbdomenGrandeEnFormaGlobo">
                <label for="nutricion.AbdomenGrandeEnFormaGlobo{{nutricion.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="nutricion.PerdidaTejidoGraso{{nutricion.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="nutricion.PerdidaTejidoGraso">
                <label for="nutricion.PerdidaTejidoGraso{{nutricion.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="nutricion.DetencionDelCrecimiento{{nutricion.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="nutricion.DetencionDelCrecimiento">
                <label for="nutricion.DetencionDelCrecimiento{{nutricion.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="nutricion.PosicionFetalPostramientoCama{{nutricion.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="nutricion.PosicionFetalPostramientoCama">
                <label for="nutricion.PosicionFetalPostramientoCama{{nutricion.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="nutricion.LlantoDebilSinCausaAparente{{nutricion.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="nutricion.LlantoDebilSinCausaAparente">
                <label for="nutricion.LlantoDebilSinCausaAparente{{nutricion.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="nutricion.ExtremidadesFrias{{nutricion.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="nutricion.ExtremidadesFrias">
                <label for="nutricion.ExtremidadesFrias{{nutricion.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="nutricion.GrasaSubcutaneaAusente{{nutricion.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="nutricion.GrasaSubcutaneaAusente">
                <label for="nutricion.GrasaSubcutaneaAusente{{nutricion.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" [disabled]="nutricion.SexoIntegrante=='M'" id="nutricion.Carahinchada{{nutricion.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="nutricion.Carahinchada">
                <label for="nutricion.Carahinchada{{nutricion.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="nutricion.CaraSenilEnvejecido{{nutricion.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="nutricion.CaraSenilEnvejecido">
                <label for="nutricion.CaraSenilEnvejecido{{nutricion.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <!---->
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="nutricion.EdemaMiembrosSuperiores{{nutricion.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="nutricion.EdemaMiembrosSuperiores">
                <label for="nutricion.EdemaMiembrosSuperiores{{nutricion.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="nutricion.EdemaMiembrosInferiores{{nutricion.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="nutricion.EdemaMiembrosInferiores">
                <label for="nutricion.EdemaMiembrosInferiores{{nutricion.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="nutricion.EdemaGeneralizado{{nutricion.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="nutricion.EdemaGeneralizado">
                <label for="nutricion.EdemaGeneralizado{{nutricion.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <div class="text-center checkbox checkbox-primary">
                <input type="checkbox" ng-reflect-model="true" id="nutricion.Anemia{{nutricion.IdentificacionIntegrante}}" class="ng-valid ng-dirty ng-touched" [(ngModel)]="nutricion.Anemia">
                <label for="nutricion.Anemia{{nutricion.IdentificacionIntegrante}}"></label>
              </div>
            </td>
            <td>
              <textarea rows="3" class="ancho-4" placeholder="Observacion" [(ngModel)]="nutricion.Observacion"></textarea>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
