import { Injectable } from '@angular/core';
import { AuditoriaDatosBasicos, ConsultarAuditoriaRequest, ConsultarAuditoriaResponse } from './entities/auditoria';
import { HttpService } from '../../http/http.service';
import { ApisRestAudotiria } from '../../../system/apisrest';

import { UtilitiesAnibalService } from '../../utilities-anibal';
import { Observable } from 'rxjs';

@Injectable()
export class AuditoriaService {

    constructor(private _httpService: HttpService) { }

    public ConsultarDatosBasicos(): Observable<AuditoriaDatosBasicos> {
        UtilitiesAnibalService.Console("Consultando datos basicos");
        return this._httpService.get<AuditoriaDatosBasicos>(ApisRestAudotiria.GetDatosBasicos);
    }

    public ConsultarAuditoria(request: ConsultarAuditoriaRequest): Observable<ConsultarAuditoriaResponse> {
        UtilitiesAnibalService.Console("Consultando auditoria sistema");
        return this._httpService.post<ConsultarAuditoriaResponse>(ApisRestAudotiria.PostConsultaAuditoria, request);
    }

}
