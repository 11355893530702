<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h5>
            Health Application
          </h5>
          <div class="ibox-tools pull-right">
            <label class="label label-warning">Solo para usuarios con conocimientos avanzados de software!</label>
          </div>
        </div>
        <div class="ibox-content ">
          <tabset>
            <tab *ngIf="Procesos">
              <ng-template tabHeading>
                <i class="fa fa-pagelines"></i> Procesos <span class="badge badge-info" (click)="ConsultarProcesos()" style="cursor:pointer;"> {{Procesos.length}}</span>
              </ng-template>
              <app-data-table-generic [Filas]="Procesos" (CellClicked)="ClickCeldaProcesos($event)" [ShowLoading]="CargandoTablaProcesos" [columns]="ColumnasProcesos"></app-data-table-generic>
            </tab>
          </tabset>
        </div>
      </div>
    </div>
  </div>
</div>
