import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS, } from '@angular/common/http';
import { RouterModule } from "@angular/router";
import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { ROUTES } from "./app.routes";
import { AppComponent } from './app.component';

// App views
import { AppviewsModule } from "./views/appviews/appviews.module";
import { SecurityModule } from './views/security/security.module';
import { ConfiguracionModule } from './views/configuracion/configuracion.module';
import { PublicModule } from './views/public/public.module';
import { PqrsModule } from './views/pqrs/pqrs.module';
import { DatosbasicosModule } from './views/datosbasicos/datosbasicos.module';

// App modules/components
import { LayoutsModule } from "./components/common/layouts/layouts.module";
import { HttpServiceInterceptor } from './services/http/http.service';

// Libreria para gestionar los permisos dentro de la aplicacion
import { NgxPermissionsModule } from 'ngx-permissions';

import { SipaModule } from './views/sipa/sipa.module';
import { SistemaService } from './services/security/sistema.service';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    LayoutsModule,
    AppviewsModule,
    SecurityModule,
    ConfiguracionModule,
    PublicModule,
    PqrsModule,
    DatosbasicosModule,
    SipaModule,
    RouterModule.forRoot(ROUTES, { useHash: false }),
    NgxPermissionsModule.forRoot()
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'es-CO' // 'de-DE' for Germany, 'fr-FR' for France ...
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpServiceInterceptor,
      multi: true
    },
    SistemaService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
