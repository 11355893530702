import { Injectable } from '@angular/core';
import { HttpService } from '../../../http/http.service';
import { Observable } from 'rxjs';
import { UtilitiesAnibalService } from '../../../utilities-anibal';
import { FamiliaIndigenaClasificacionFamiliar } from '../../../../entities/sipa/familiaindigena';
import { ApisRestCaracterizacionFamiliaIndigenaGestionClasificacionFamiliar } from '../../../../system/apisrest';

@Injectable()
export class ClasificacionFamiliarService {

  constructor(private _httpService: HttpService) { }

  public GetConsultarDatosFormulario(familiaIndigenaId: number): Observable<GestionClasificacionFamiliarDatosFormularioResponse> {
    UtilitiesAnibalService.Console("Consultando clasificacion familiar de la familia (Datos formulario gestion)" + familiaIndigenaId);
    return this._httpService.get<GestionClasificacionFamiliarDatosFormularioResponse>(ApisRestCaracterizacionFamiliaIndigenaGestionClasificacionFamiliar.GetConsultarDatosFormulario(familiaIndigenaId));
  }

  public GestionClasificacionFamiliar(request: GestionClasificacionFamiliarRequest): Observable<GestionClasificacionFamiliarResponse> {
    UtilitiesAnibalService.Console("Gestión integrantes de la familia" + request.FamiliaIndigenaId);
    return this._httpService.post<GestionClasificacionFamiliarResponse>(ApisRestCaracterizacionFamiliaIndigenaGestionClasificacionFamiliar.PostGestionClasificacionFamiliar, request);
  }

}

export class GestionClasificacionFamiliarResponse {
  public Mensaje: string ;
}

export class GestionClasificacionFamiliarRequest {
  public FamiliaIndigenaId: number;
  public ClasificacionFamiliar: FamiliaIndigenaClasificacionFamiliar = new FamiliaIndigenaClasificacionFamiliar();
}

export class GestionClasificacionFamiliarDatosFormularioResponse {
  public ClasificacionFamiliar: FamiliaIndigenaClasificacionFamiliar = new FamiliaIndigenaClasificacionFamiliar();
}
