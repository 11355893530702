import { Routes } from "@angular/router";

import { NgxPermissionsGuard } from 'ngx-permissions';
import { BasicLayoutComponent } from "../components/common/layouts/basicLayout.component";

import { RegistrousuarioComponent } from '../views/security/registrousuario/registrousuario.component';
import { GestionPermisosComponent } from '../views/security/registrousuario/components/gestion-permisos/gestion-permisos.component';
import { ChangePasswordAdminComponent } from '../views/security/registrousuario/components/change-password-admin/change-password-admin.component';
import { ForzeEmailConfirmComponent } from '../views/security/registrousuario/components/forze-email-confirm/forze-email-confirm.component';
import { ModificarUsuarioComponent } from '../views/security/registrousuario/components/modificar-usuario/modificar-usuario.component';
import { AsignarPaginaInicioComponent } from '../views/security/registrousuario/components/asignar-pagina-inicio/asignar-pagina-inicio.component';
import { AuditoriaComponent } from '../views/security/auditoria/auditoria.component';

export const Seguridad: any = {
    path: 'Seguridad', component: BasicLayoutComponent,
    children: [
        {
            path: 'Registro/Usuario', component: RegistrousuarioComponent, canActivate: [NgxPermissionsGuard],
            data: {
                permissions: {
                  only: ['Seguridad.GestionUsuario'],
                    redirectTo: 'login'
                }
            }
        },
        {
            path: 'Permisos/User/:userName', component: GestionPermisosComponent, canActivate: [NgxPermissionsGuard],
            data: {
                permissions: {
                  only: ['Seguridad.PermisosToUser'],
                    redirectTo: 'Seguridad/Registro/Usuario'
                }
            }
        },
        {
          path: 'Password/User/:userName', component: ChangePasswordAdminComponent, canActivate: [NgxPermissionsGuard],
            data: {
                permissions: {
                  only: ['Seguridad.ChangePasswordToUser'],
                    redirectTo: 'Seguridad/Registro/Usuario'
                }
            }
        },
        {
            path: 'Email/Confirm/User/:userName', component: ForzeEmailConfirmComponent, canActivate: [NgxPermissionsGuard],
            data: {
                permissions: {
                  only: ['Seguridad.ForzeEmailConfirmToUser'],
                    redirectTo: 'Seguridad/Registro/Usuario'
                }
            }
        },
        {
            path: 'Modificar/User/Tercero/:terceroId', component: ModificarUsuarioComponent, canActivate: [NgxPermissionsGuard],
            data: {
                permissions: {
                  only: ['Seguridad.ModifiedTerceroAndUser'],
                    redirectTo: 'Seguridad/Registro/Usuario'
                }
            }
        },
        {
            path: 'Asignar/Pagina/Inicio/:terceroId', component: AsignarPaginaInicioComponent, canActivate: [NgxPermissionsGuard],
            data: {
                permissions: {
                  only: ['Seguridad.AssignIndexPageToUser'],
                    redirectTo: 'Seguridad/Registro/Usuario'
                }
            }
        },
        {
            path: 'Auditoria', component: AuditoriaComponent, canActivate: [NgxPermissionsGuard],
            data: {
                permissions: {
                  only: ['Seguridad.ConsultaAuditoria'],
                    redirectTo: 'Seguridad/Registro/Usuario'
                }
            }
        }
    ]
};
