import { Component, OnInit, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FamiliaIndigena } from '../../../../../entities/sipa/familiaindigena';
import { UtilitiesAnibalService } from '../../../../../services/utilities-anibal';
import { EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { GestioncapacitacionesRequest, CapacitacionFamiliaIndigenaService, FamiliaIndigenaCapacitacionTable, CapacitacionParaRegistro } from '../../../../../services/sipa/caracterizacion/nutricioncapacitacion/capacitacion-familia-indigena.service';

@Component({
  selector: 'app-capacitacion',
  templateUrl: './capacitacion.component.html',
  styleUrls: ['./capacitacion.component.css'],
  providers: [ToastrService, CapacitacionFamiliaIndigenaService]
})
export class CapacitacionComponent implements OnInit {

  constructor(
    private _toastr: ToastrService,
    private _gestioncapacitacionesService: CapacitacionFamiliaIndigenaService,
  ) { }

  ngOnInit() {
  }

  @Output() public NotificacionComponente: EventEmitter<GestionCapacitacionesComponentEventResponse> = new EventEmitter();
  private ObjectNotificacion: GestionCapacitacionesComponentEventResponse = new GestionCapacitacionesComponentEventResponse();

  private _FamiliaIndigena: FamiliaIndigena;
  @Input()
  set FamiliaIndigena(familia: FamiliaIndigena) {
    this._FamiliaIndigena = familia;
    if (familia && familia.Id) {
      this.RequestGestion.FamiliaIndigenaId = familia.Id;
      this.ConsultarDatosFormulario();
    }
  }

  get FamiliaIndigena(): FamiliaIndigena {
    return this._FamiliaIndigena;
  }

  public CargandoTablaCapacitaciones: boolean = true;
  public Columnas: Array<any> = [
    { title: 'Capacitación', name: 'NombreCapacitacion', classNameRow: 'text-right' },
    { title: 'Fecha', className: 'text-left', classNameRow: 'text-right', name: 'FechaTable' },
    { title: 'Observacion', className: 'text-left', classNameRow: 'text-right', name: 'Observacion' },
  ];

  public RequestGestion: GestioncapacitacionesRequest = new GestioncapacitacionesRequest();
  public Capacitaciones: Array<FamiliaIndigenaCapacitacionTable> = new Array<FamiliaIndigenaCapacitacionTable>();
  public CapacitacionesParaRegistrar: Array<CapacitacionParaRegistro> = new Array<CapacitacionParaRegistro>();

  public Guardar(): void {
    UtilitiesAnibalService.ShowLoading();
    this._gestioncapacitacionesService.GestionCapacitacionFamilia(this.RequestGestion).subscribe(response => {
      UtilitiesAnibalService.HideLoading();
      this.ConsultarDatosFormulario();
      this._toastr.success(response.Mensaje, "Correcto!");
    }, err => {
      UtilitiesAnibalService.HideLoading();
    });
  }

  public ConsultarDatosFormulario(): void {
    this.RequestGestion = new GestioncapacitacionesRequest();
    this.RequestGestion.FamiliaIndigenaId = this.FamiliaIndigena.Id;
    this.ObjectNotificacion.EstaConsultando = true;
    this.CargandoTablaCapacitaciones = true;
    this.NotificacionComponente.emit(this.ObjectNotificacion);
    this._gestioncapacitacionesService.GetConsultarDatosFormulario(this._FamiliaIndigena.Id).subscribe(response => {
      this.ObjectNotificacion.EstaConsultando = false;
      this.Capacitaciones = response.Capacitaciones;
      for (let capacitacion of this.Capacitaciones) {
        capacitacion.FechaTable = new DatePipe("es-CO").transform(capacitacion.Fecha, 'dd/MM/yyyy');
      }
      this.CapacitacionesParaRegistrar = response.CapacitacionesParaRegistrar;
      this.ObjectNotificacion.CantidadCapacitaciones = response.Capacitaciones.length;
      this.CargandoTablaCapacitaciones = false;
      this.NotificacionComponente.emit(this.ObjectNotificacion);
    }, err => {
    });
  }

}

export class GestionCapacitacionesComponentEventResponse {
  public CantidadCapacitaciones: number;
  public EstaConsultando: boolean;
}

