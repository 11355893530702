import { Injectable } from '@angular/core';
import { RegistroPqrsRequest, RegistroPqrsResponse } from './entities/pqrs';
import { HttpService } from '../../http/http.service';
import { ApisRestRegistroPqr } from '../../../system/apisrest';

import { UtilitiesAnibalService } from '../../utilities-anibal';
import { Observable } from 'rxjs';

@Injectable()
export class PqrsService {

    constructor(private _httpService: HttpService) { }

    public RegistroPqr(request: RegistroPqrsRequest): Observable<RegistroPqrsResponse> {
        UtilitiesAnibalService.Console("Registro de pqr");
        return this._httpService.post<RegistroPqrsResponse>(ApisRestRegistroPqr.PostAllonimousRegistroPqr, request);
    }

}
