<div class="modal-header bg-primary">
  <h4 class="modal-title pull-left">{{ title }}</h4>
  <button type="button" class="close pull-right" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ng-template anibal-add-component></ng-template>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-danger" (click)="dismiss()">Cancelar</button>
  <!--<button type="button" class="btn btn-primary" (click)="accept()">{{ btnOkText }}</button>-->
</div>
