import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GestionFamiliaIndigenaService, FamiliaIndigenaTable, ConsultaParametrizadaFamiliaIndigenaRequest, RegistrarFamiliaIndigenaRequest, IntegranteParaVerificarEnQueFamiliaEsta } from '../../../services/sipa/gestionfamiliaindigena/gestion-familia-indigena.service';
import { ToastrService } from 'ngx-toastr';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { UtilitiesAnibalService } from '../../../services/utilities-anibal';
import { DatePipe } from '@angular/common';
import { CellClickedTableGeneric } from '../../../components/anibal/data-table-generic/data-table-generic.component';
import { Router } from '@angular/router';
import { Municipio, Asentamiento, Sector } from '../../../entities/general/departamento';
import { PublicService } from '../../../services/general/public/public.service';

@Component({
  selector: 'app-gestionfamiliaindigena',
  templateUrl: './gestionfamiliaindigena.component.html',
  styleUrls: ['./gestionfamiliaindigena.component.css'],
  providers: [GestionFamiliaIndigenaService, PublicService, ToastrService]
})
export class GestionfamiliaindigenaComponent implements OnInit {

  constructor(
    private _gestionFamiliaIndigenaService: GestionFamiliaIndigenaService,
    private _publicService: PublicService,
    private _modalService: BsModalService,
    private _router: Router,
    private _toastr: ToastrService) {

  }

  ngOnInit() {
    this.ConsultarDatosFormulario();
  }

  private modalRefRegistroFamiliaIndigena: BsModalRef;
  public RequestRegistroFamiliaIndigena: RegistrarFamiliaIndigenaRequest = new RegistrarFamiliaIndigenaRequest();


  public FamiliasIndigenas: FamiliaIndigenaTable[];
  public CargandoTablaFamiliasIndigenas: boolean = true;
  public RequestConsultaParametrizada: ConsultaParametrizadaFamiliaIndigenaRequest = new ConsultaParametrizadaFamiliaIndigenaRequest();
  public Integrantes: Array<IntegranteParaVerificarEnQueFamiliaEsta> = new Array<IntegranteParaVerificarEnQueFamiliaEsta>();
  public MunicipiosConAsentamientos: Array<Municipio> = new Array<Municipio>();
  public Asentamientos: Array<Asentamiento> = new Array<Asentamiento>();
  public Sectores: Array<Sector> = new Array<Sector>();

  public Columnas: Array<any> = [
    { title: 'Número', name: 'Numero', classNameRow: 'text-right' },
    { title: 'Municipio', className: 'text-left', classNameRow: 'text-right', name: 'Municipio' },
    { title: 'Asentamiento', className: 'text-left', classNameRow: 'text-right', name: 'Asentamiento' },
    { title: 'Sector', className: 'text-left', classNameRow: 'text-right', name: 'Sector' },
    { title: 'Integrantes', name: 'CantidadIntegrantesBadge', replaceNameInExportFor: 'CantidadIntegrantes' },
    { title: 'Seguimientos', name: 'CantidadSeguimientosBadge', replaceNameInExportFor: 'CantidadSeguimientos' },
    { title: '', name: 'BotonCaracterizacion', hideInExport: true }
  ];

  public ConsultarFamiliasIndigenas(): void {
    this.CargandoTablaFamiliasIndigenas = true;
    UtilitiesAnibalService.ShowLoading();
    this._gestionFamiliaIndigenaService.ConsultarTodasLasVigencias(this.RequestConsultaParametrizada).subscribe(response => {
      this.CargandoTablaFamiliasIndigenas = false;
      UtilitiesAnibalService.HideLoading();
      this.FamiliasIndigenas = response.FamiliaIndigenas;
      for (let familiaIndigena of this.FamiliasIndigenas) {
        familiaIndigena.FechaPipe = new DatePipe("es-CO").transform(familiaIndigena.Fecha, 'dd/MM/yyyy');
        familiaIndigena.CantidadSeguimientosBadge = '<span class="badge badge-success">' + familiaIndigena.CantidadSeguimientos + '</span>';
        familiaIndigena.CantidadIntegrantesBadge = '<span class="badge badge-info">' + familiaIndigena.CantidadIntegrantes + '</span>';
        familiaIndigena.BotonCaracterizacion = '<a href="javascript:0;" title="Caracterizar familia" class="btn btn-xs btn-success"><i class="fa fa-eye"> Caracterizar</i></a>';
      }
    }, err => {
      UtilitiesAnibalService.HideLoading();
      this.CargandoTablaFamiliasIndigenas = false;
    });
  }

  public ConsultarDatosFormulario(): void {
    UtilitiesAnibalService.ShowLoading();
    this._gestionFamiliaIndigenaService.GetConsultarDatosFormulario().subscribe(response => {
      UtilitiesAnibalService.HideLoading();
      this.MunicipiosConAsentamientos = response.Municipios;
      this.ConsultarFamiliasIndigenas();
    }, err => {
      UtilitiesAnibalService.HideLoading();
    });
  }

  public ConsultarAsentamientos(): void {
    UtilitiesAnibalService.ShowLoading();
    this._publicService.ConsultarAsentamientos(this.RequestRegistroFamiliaIndigena.MunicipioId).subscribe(response => {
      UtilitiesAnibalService.HideLoading();
      this.Asentamientos = response.Asentamientos;
      this.RequestRegistroFamiliaIndigena.AsentamientoId = response.Asentamientos[0].Id;
      this.ConsultarSectores();
    }, err => {
      UtilitiesAnibalService.HideLoading();
    });
  }

  public ConsultarSectores(): void {
    UtilitiesAnibalService.ShowLoading();
    this._publicService.ConsultarSectores(this.RequestRegistroFamiliaIndigena.AsentamientoId).subscribe(response => {
      UtilitiesAnibalService.HideLoading();
      this.Sectores = response.Sectores;
      this.RequestRegistroFamiliaIndigena.SectorId = response.Sectores[0].Id;
    }, err => {
      UtilitiesAnibalService.HideLoading();
    });
  }

  public ColumnasIntegrantes: Array<any> = [
    { title: 'Identificación', name: 'Identificacion' },
    { title: 'Nombre completo', name: 'NombreCompleto' },
    { title: 'Sexo', name: 'Sexo' },
    { title: 'Número familia', name: 'NumeroFamilia' },
    { title: 'Parentesco', name: 'Parentesco' },
    { title: '', name: 'BotonIdDetalle', hideInExport: true }
  ];

  @ViewChild('ModalRegistrarFamiliaIndigena') public templateRefModalRegistrar: TemplateRef<any>;

  public OpenModalRegistroFamiliaIndigena() {
    this.modalRefRegistroFamiliaIndigena = this._modalService.show(this.templateRefModalRegistrar, { class: 'modal-lg' });
  }

  public UltimaFamiliaRegistrada: string;

  public RegistrarFamiliaIndigena(parametro): void {
    UtilitiesAnibalService.ShowLoading();
    this._gestionFamiliaIndigenaService.RegistrarFamiliaIndigena(this.RequestRegistroFamiliaIndigena).subscribe(response => {
      UtilitiesAnibalService.HideLoading();
      this.RequestRegistroFamiliaIndigena = new RegistrarFamiliaIndigenaRequest();
      this.ConsultarFamiliasIndigenas();
      this.UltimaFamiliaRegistrada = response.NumeroFamilia;
      this._toastr.success(response.Mensaje, "Correcto!");
    }, err => {
      UtilitiesAnibalService.HideLoading();
    });
  }

  private modalRefConsultaIntegrantes: BsModalRef;
  @ViewChild('ModalConsultaIntegrantes') public templateRefModalConsultaIntegrantes: TemplateRef<any>;

  public OpenModalConsultaIntegrantes() {
    this.modalRefConsultaIntegrantes = this._modalService.show(this.templateRefModalConsultaIntegrantes, { class: 'modal-lg' });
  }

  public ConsultarIntegrantes(IdentificacionIntegrante: string): void {
    if (IdentificacionIntegrante) {
      UtilitiesAnibalService.ShowLoading();
      this._gestionFamiliaIndigenaService.GetIntegrantes(IdentificacionIntegrante).subscribe(response => {
        UtilitiesAnibalService.HideLoading();
        this.Integrantes = response.Integrantes;
        for (let integrante of this.Integrantes) {
          integrante.BotonIdDetalle = '<a href="javascript:0;" title="Ir detalle" class="btn btn-xs btn-success"><i class="fa fa-eye"> Ver</i></a>';
        }
        if (response.Integrantes.length == 0) {
          this._toastr.warning("No se encontro integrante en ninguna familia indigena");
        }
      }, err => {
        UtilitiesAnibalService.HideLoading();
      });
    }
  }

  public ClickFila(data: CellClickedTableGeneric): any {
    if (data.Columna === "BotonCaracterizacion") {
      if (data.Fila.BotonCaracterizacion) {
        this.AbrirCaracterizacionFamilia(data.Fila.Numero);
      } else {
      }
    }
  }

  public ClickFilaIntegrantes(data: CellClickedTableGeneric): any {
    if (data.Columna === "BotonIdDetalle") {
      if (data.Fila.BotonIdDetalle) {
        open('#/Sipa/Caracterizacion/Familia/' + data.Fila.NumeroFamilia);
      } else {
      }
    }
  }

  public AbrirCaracterizacionFamilia(numeroFamilia: string): void {
    this._router.navigate(['/Sipa/Caracterizacion/Familia/' + numeroFamilia]);
  }

  public AbrirCaracterizacionFamiliaDesdeRegistro(numeroFamilia: string): void {
    this.modalRefRegistroFamiliaIndigena.hide();
    this.AbrirCaracterizacionFamilia(numeroFamilia);
  }
}
