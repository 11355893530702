import { Component, OnInit, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FamiliaIndigena, FamiliaIndigenaSeguimiento } from '../../../../../entities/sipa/familiaindigena';
import { UtilitiesAnibalService } from '../../../../../services/utilities-anibal';
import { EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { FamiliaIndigenaSeguimientoTable, GestionseguimientosRequest, SeguimientoFamiliaIndigenaService, GestionseguimientoIndividualRequest } from '../../../../../services/sipa/caracterizacion/seguimiento/seguimiento-familia-indigena.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { CellClickedTableGeneric } from '../../../../../components/anibal/data-table-generic/data-table-generic.component';

@Component({
  selector: 'app-gestionseguimientos',
  templateUrl: './gestionseguimientos.component.html',
  styleUrls: ['./gestionseguimientos.component.css'],
  providers: [ToastrService, SeguimientoFamiliaIndigenaService]
})
export class GestionseguimientosComponent implements OnInit {

  constructor(
    private _toastr: ToastrService,
    private _gestionseguimientosService: SeguimientoFamiliaIndigenaService,
    private _modalService: BsModalService,
  ) { }

  ngOnInit() {
  }

  @Output() public NotificacionComponente: EventEmitter<GestionSeguimientoesComponentEventResponse> = new EventEmitter();
  private ObjectNotificacion: GestionSeguimientoesComponentEventResponse = new GestionSeguimientoesComponentEventResponse();

  private _FamiliaIndigena: FamiliaIndigena;
  @Input()
  set FamiliaIndigena(familia: FamiliaIndigena) {
    this._FamiliaIndigena = familia;
    if (familia && familia.Id) {
      this.RequestRegistroSeguimiento.FamiliaIndigenaId = familia.Id;
      this.ConsultarDatosFormulario();
    }
  }

  get FamiliaIndigena(): FamiliaIndigena {
    return this._FamiliaIndigena;
  }

  public CargandoTablaSeguimientos: boolean = true;

  public Columnas: Array<any> = [
    { title: 'Fecha', className: 'text-left', classNameRow: 'text-right', name: 'FechaTable' },
    { title: 'Valor', className: 'text-left', classNameRow: 'text-right', name: 'ValorSeguimiento' },
    { title: 'Valoración', className: 'text-left', classNameRow: 'text-right', name: 'ValoracionSeguimiento' },
    { title: 'Acción', className: 'text-left', classNameRow: 'text-right', name: 'QueHacer' },
    { title: 'Observacion', className: 'text-left', classNameRow: 'text-right', name: 'Observacion' },
    { title: '', name: 'BotonGestionar', hideInExport: true }
  ];

  public RequestRegistroSeguimiento: GestionseguimientosRequest = new GestionseguimientosRequest();
  public Seguimientos: Array<FamiliaIndigenaSeguimientoTable> = new Array<FamiliaIndigenaSeguimientoTable>();

  public RequestGestion: GestionseguimientoIndividualRequest = new GestionseguimientoIndividualRequest();
  private SeguimientoSeleccionado: FamiliaIndigenaSeguimientoTable = new FamiliaIndigenaSeguimientoTable();

  private modalRefRegistroSeguimiento: BsModalRef;
  @ViewChild('ModalRegistrarSeguimiento') public templateRefModalRegistrar: TemplateRef<any>;

  public ModalNuevoSeguimiento() {
    this.modalRefRegistroSeguimiento = this._modalService.show(this.templateRefModalRegistrar, { class: 'modal-lg' });
  }

  public NuevoSeguimiento(parametro): void {
    UtilitiesAnibalService.ShowLoading();
    this._gestionseguimientosService.RegistroSeguimientoFamilia(this.RequestRegistroSeguimiento).subscribe(response => {
      UtilitiesAnibalService.HideLoading();
      this.ConsultarDatosFormulario();
      this._toastr.success(response.Mensaje, "Correcto!");
    }, err => {
      UtilitiesAnibalService.HideLoading();
    });
  }

  public ConsultarDatosFormulario(): void {
    this.RequestRegistroSeguimiento = new GestionseguimientosRequest();
    this.RequestRegistroSeguimiento.FamiliaIndigenaId = this.FamiliaIndigena.Id;
    this.ObjectNotificacion.EstaConsultando = true;
    this.CargandoTablaSeguimientos = true;
    this.NotificacionComponente.emit(this.ObjectNotificacion);
    this._gestionseguimientosService.GetConsultarDatosFormulario(this._FamiliaIndigena.Id).subscribe(response => {
      this.ObjectNotificacion.EstaConsultando = false;
      this.Seguimientos = response.Seguimientos;
      for (let capacitacion of this.Seguimientos) {
        capacitacion.FechaTable = new DatePipe("es-CO").transform(capacitacion.Fecha, 'dd/MM/yyyy');
        capacitacion.BotonGestionar = '<a href="javascript:0;" title="Diligenciar seguimiento" class="btn btn-xs btn-success"><i class="fa fa-eye"> Diligenciar</i></a>';
      }
      this.ObjectNotificacion.CantidadSeguimientos = response.Seguimientos.length;
      this.CargandoTablaSeguimientos = false;
      this.NotificacionComponente.emit(this.ObjectNotificacion);
    }, err => {
    });
  }

  public ConsultarSeguimientoParaProcesar(): void {
    UtilitiesAnibalService.ShowLoading();
    this.RequestGestion = new GestionseguimientoIndividualRequest();
    this.RequestGestion.FamiliaIndigenaSeguimientoId = this.SeguimientoSeleccionado.Id;
    this._gestionseguimientosService.GetConsultarSeguimiento(this.SeguimientoSeleccionado.Id).subscribe(response => {
      this.RequestGestion.Seguimiento = response.Seguimiento;
      UtilitiesAnibalService.HideLoading();
    }, err => {
      UtilitiesAnibalService.HideLoading();
    });
  }

  public GestionSeguimiento(): void {
    UtilitiesAnibalService.ShowLoading();
    this._gestionseguimientosService.GestionSeguimientoFamilia(this.RequestGestion).subscribe(response => {
      UtilitiesAnibalService.HideLoading();
      this.ConsultarDatosFormulario();
      this.ConsultarSeguimientoParaProcesar();
      this._toastr.success(response.Mensaje, "Correcto!");
    }, err => {
      UtilitiesAnibalService.HideLoading();
    });
  }

  public ClickFila(data: CellClickedTableGeneric): any {
    if (data.Columna === "BotonGestionar") {
      if (data.Fila.BotonGestionar) {
        this.SeguimientoSeleccionado = data.Fila as FamiliaIndigenaSeguimientoTable;
        this.ConsultarSeguimientoParaProcesar();
      } else {
      }
    }
  }

}

export class GestionSeguimientoesComponentEventResponse {
  public CantidadSeguimientos: number;
  public EstaConsultando: boolean;
}
