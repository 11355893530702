<div class="col-lg-12">
  <div class="ibox ">
    <div class="ibox-title">
      <h5>Formulario de soporte técnico</h5>
    </div>
    <div>
      <div class="ibox-content no-padding border-left-right">
        <img alt="image" class="img-fluid" [src]="CapturePantalla">
      </div>
      <div class="ibox-content profile-content">
        <div class="form-group">
          <label>Descripción</label>
          <textarea rows="10" required name="Descripcion" [(ngModel)]="Request.Descripcion" style="width: 100%;"></textarea>
        </div>
        <div class="user-button">
          <div class="row">
            <div class="col-md-2">
              <button type="button" [disabled]="disabledButton" (click)="EnviarSoporteTecnico()" class="btn btn-primary btn-sm btn-block"><i class="fa fa-envelope"></i> Enviar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
