import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableGenericComponent } from '../../components/anibal/data-table-generic/data-table-generic.component';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';

import { AnibalModulesModule } from '../anibalmodules/anibalmodules.module';

import { GestionpqrsComponent } from './gestionpqrs/gestionpqrs.component';
import { ResponderpqrsComponent } from './responderpqrs/responderpqrs.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        AnibalModulesModule,
        PaginationModule.forRoot(),
        ModalModule.forRoot(),
        BsDatepickerModule.forRoot()
    ],
    declarations: [GestionpqrsComponent, ResponderpqrsComponent]
})
export class PqrsModule { }
