export class ParametroBasic {
  public Id: number;
  public Abreviatura: string;
  public Modulo: string;
  public Nombre: string;
  public Estado: string;
  public Descripcion: string;
  public CantidadDetallesRegistrados: number;
  public CantidadDetallesObligatorios: number;
}

export class Parametro extends ParametroBasic {
  public Detalles: Array<DetalleParametro>;
}

export class DetalleParametro {
  public ParametroId: number;
  public NombreParametro: string;
  public Nombre: string;
  public Abreviatura: string;
  public Valor: string;
  public TipoDato: string;
  public Descripcion: string;
  public FechaInicial: Date;
  public FechaFinal: Date;
}

