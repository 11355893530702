<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <button [disabled]="ParametrosNoRegistrados.length<=0" class="btn " [ngClass]="ParametrosNoRegistrados.length<=0?'btn-primary':'btn-danger'" (click)="OpenModalRegistroParametro(ModalModificarParametro)" type="button"><i class="fa fa-paste"></i>&nbsp;Nuevo <span class="badge badge-warning">{{ParametrosNoRegistrados.length}}</span></button>
          <span class="label label-primary pull-right MarginLabel">Parametros</span>
        </div>
        <div class="ibox-content ">
          <div class="row">
            <div class="col-lg-2 col-sm-12 col-md-2">
              <div class="form-group">
                <label>Modulo</label>
                <select class="form-control" [(ngModel)]="Request.Modulo" name="Modulo">
                  <option value="">Todos</option>
                  <option value="{{modulo.Value}}" *ngFor="let modulo of Modulos">{{modulo.DisplayName}}</option>
                </select>
              </div>
            </div>
            <div class="col-lg-4 col-sm-12 col-md-4 MarginTop">
              <button class="btn btn-primary " (click)="ConsultarParametrosRegistrados()" type="button"><i class="fa fa-search"></i>&nbsp;Consultar</button>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-sm-12 col-md-12">
              <app-data-table-generic (CellClicked)="ClickFila($event)" [Filas]="Parametros" [ShowLoading]="CargandoTablaParametros" [columns]="Columnas"></app-data-table-generic>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #ModalRegistrarParametro>
  <div class="modal-header bg-primary">
    <h4 class="modal-title pull-left">Registro de parametro</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefRegistroParametro.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form ngNativeValidate #FormRegistrarParametro="ngForm" (ngSubmit)="RegistrarParametro(FormRegistrarParametro)">
    <div class="modal-body">
      <div class="row">
        <div class="col-lg-9">
          <div class="form-group">
            <label>Parametros no registrados</label>
            <select class="form-control" [(ngModel)]="ParametroARegistrarSeleccionado" name="ParametroToRegistrar" (change)="CambioParametroARegistrar($event)">
              <option value="">Seleccione una opción</option>
              <option value="{{parametro.Abreviatura}}" *ngFor="let parametro of ParametrosNoRegistrados">{{parametro.Nombre}}</option>
            </select>
          </div>
        </div>
        <div class="col-lg-3">
          <label>Estado</label>
          <select class="form-control" [(ngModel)]="RequestRegistroParametro.Estado" name="EstadoParametroRegistrar">
            <option value="">Seleccione un estado</option>
            <option value="{{estado.Value}}" *ngFor="let estado of Estados">{{estado.DisplayName}}</option>
          </select>
        </div>
        <div class="col-lg-10 col-sm-12 col-md-12">
          <div class="form-group">
            <label>Nombre</label>
            <input type="text" [(ngModel)]="RequestRegistroParametro.Nombre" name="NombreParametroRegistrar" required placeholder="Nombre" class="form-control">
          </div>
        </div>
        <div class="col-lg-12 col-sm-12 col-md-12">
          <div class="form-group">
            <label>Descripcion</label>
            <textarea rows="10" required [(ngModel)]="RequestRegistroParametro.Descripcion" name="DescripcionParametroRegistrar" style="width: 100%;"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary pull-left" type="submit" [disabled]="disabledButtonRegistroParametro"><i class="fa fa-check"></i>&nbsp;Registrar</button>
      <button type="button" class="btn btn-default pull-right" (click)="modalRefRegistroParametro.hide()">Cerrar</button>
    </div>
  </form>
</ng-template>

<ng-template #ModalModificarParametro>
  <div class="modal-header bg-primary ">
    <h4 class="modal-title pull-left">Modificar parametro</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRefModificarParametro.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-3">
        <label>Estado</label>
        <select class="form-control" [(ngModel)]="RequestModificar.Estado" name="EstadoParametroModificar">
          <option value="">Seleccione un estado</option>
          <option value="{{estado.Value}}" *ngFor="let estado of Estados">{{estado.DisplayName}}</option>
        </select>
      </div>
      <div class="col-lg-9 MarginTop">
        <button class="btn btn-primary pull-right" (click)="ModificarParametro()" [disabled]="disabledButtonRegistroParametro"><i class="fa fa-check"></i>&nbsp;Modificar</button>
      </div>
      <div class="col-lg-10 col-sm-12 col-md-12">
        <div class="form-group">
          <label>Nombre</label>
          <input type="text" [(ngModel)]="RequestModificar.Nombre" name="NombreParametroModificar" required placeholder="Nombre" class="form-control">
        </div>
      </div>
      <div class="col-lg-12 col-sm-12 col-md-12">
        <div class="form-group">
          <label>Descripcion</label>
          <textarea rows="3" required [(ngModel)]="RequestModificar.Descripcion" name="DescripcionParametroModificar" style="width: 100%;"></textarea>
        </div>
      </div>
      <div class="col-lg-12 col-sm-12 col-md-12">
        <label>Detalles del parametro</label>
        <div class="table-responsive">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th class="text-center">Nombre</th>
                <th class="text-center">Descripción</th>
                <th class="text-center">Tipo de dato</th>
                <th class="text-center">Valor</th>
                <th class="text-center">Fecha de inicio</th>
                <th class="text-center">Fecha de final</th>
                <th class="text-center">Estado</th>
                <th class="text-center">Opción</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="6" class="text-left">
                  <select class="form-control" [(ngModel)]="NuevoDetalle.Abreviatura" (change)="SeleccionoUnNuevoDetalle($event)" name="AbreviaturaNuevoParametro">
                    <option value="">Seleccione una opción</option>
                    <option value="{{detalleValido.Abreviatura}}" *ngFor="let detalleValido of DetallesValidosParametro" title="{{detalleValido.Nombre}}">{{detalleValido.Nombre}}</option>
                  </select>
                </td>
                <td class="text-left">
                  <span class="badge bg-default">{{NuevoDetalle.TipoDato}}</span>
                </td>
                <td class="text-left" colspan="2">
                  <button type="button" class="btn btn-primary pull-right" *ngIf="RequestModificar.Detalles.length>0" (click)="AgregarDetalle()">Agregar</button>
                  <button type="button" class="btn btn-info pull-right" *ngIf="RequestModificar.Detalles.length==0" (click)="AgregarTodosLosDetalles()">Agregar Todos</button>
                </td>
              </tr>
              <tr *ngFor="let detalle of RequestModificar.Detalles">
                <td class="text-left cortar-texto-2" placement="top" title="{{detalle.Nombre}}">
                  <textarea rows="5" [(ngModel)]="detalle.Nombre" style="width: 100%;"></textarea>
                </td>
                <td class="text-left cortar-texto-2" title="{{detalle.Descripcion}}">
                  <textarea rows="5" [(ngModel)]="detalle.Descripcion" style="width: 100%;"></textarea>
                </td>
                <td class="text-left" title="{{detalle.TipoDato}}">
                  <select [(ngModel)]="detalle.TipoDato" disabled>
                    <option value="">Seleccione un tipo de dato</option>
                    <option value="{{tipoDato.Value}}" *ngFor="let tipoDato of TiposDatos">{{tipoDato.DisplayName}}</option>
                  </select>
                </td>
                <td class="text-left cortar-texto-3" title="{{detalle.Valor}}">
                  <span *ngIf="detalle.TipoDato=='Imagen'">
                    <a href="unsafe:javascript:0;" title="Limpiar imagen" (click)="detalle.Valor=null" class="btn btn-xs btn-primary"><i class="fa fa-edit"> Limpiar</i></a>
                  </span>
                  <input [disabled]="detalle.TipoDato=='Imagen' && detalle.Valor" *ngIf="detalle.TipoDato!='Html' && detalle.TipoDato!='Color'" type="text" [(ngModel)]="detalle.Valor" class="form-control">
                  <textarea *ngIf="detalle.TipoDato=='Html'" rows="5" [(ngModel)]="detalle.Valor" style="width: 100%;"></textarea>
                  <input *ngIf="detalle.TipoDato=='Color'" type="color" [(ngModel)]="detalle.Valor" class="form-control">
                  <img *ngIf="detalle.TipoDato=='Imagen'" alt="image" height="68" [src]="detalle.Valor">
                </td>
                <td class="text-left" title="{{detalle.FechaInicial}}">
                  <input type="date" [(ngModel)]="detalle.FechaInicial" class="form-control">
                </td>
                <td class="text-left" title="{{detalle.FechaFinal}}">
                  <input type="date" [(ngModel)]="detalle.FechaFinal" class="form-control">
                </td>
                <td class="text-left" colspan="2" title="{{detalle.Estado}}">
                  <select class="form-control" [(ngModel)]="detalle.Estado" name="EstadoParametroModificar">
                    <option value="">Seleccione un estado</option>
                    <option value="{{estado.Value}}" *ngFor="let estado of Estados">{{estado.DisplayName}}</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-default pull-right" (click)="modalRefModificarParametro.hide()">Cerrar</button>
  </div>
</ng-template>
