import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { GestionPqrsService, ConsultarPqrsRequest, ConsultarPqrsResponse, PqrsTable } from '../../../services/pqrs/gestionpqrs/gestion-pqrs.service';
import { ToastrService } from 'ngx-toastr';
import { CellClickedTableGeneric } from '../../../components/anibal/data-table-generic/data-table-generic.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-gestionpqrs',
  templateUrl: './gestionpqrs.component.html',
  styleUrls: ['./gestionpqrs.component.css'],
  providers: [GestionPqrsService, ToastrService]
})
export class GestionpqrsComponent implements OnInit {

  private FechaInicial = new Date();
  public RangoFecha: Date[];
  private FechaFinal = new Date();

  constructor(
    private _gestionPqrsService: GestionPqrsService,
    private _toastr: ToastrService,
    private _modalService: BsModalService,
    private _router: Router) {
    this.FechaInicial = new Date(this.FechaInicial.getFullYear(), 0, 1);
    this.RangoFecha = [this.FechaInicial, this.FechaFinal];
    this.ConsultarPqrs();
  }

  ngOnInit() {

  }
  public disabledButton: boolean = false;
  private modalRef: BsModalRef;

  public Tablas: string[];
  public Modulos: string[];
  public Acciones: string[];
  public CargandoTablaPqrs: boolean = false;
  public Request: ConsultarPqrsRequest = { Estado: "", FechaFinal: null, FechaInicial: null, IdentificacionSolicitante: "", NombreSolicitante: "", NumeroRadicado: "", TipoPqrs: "" };

  public ListaPqrs: Array<PqrsTable>;

  public Columnas: Array<any> = [
    { title: 'Tipo', name: 'TipoPqrBadge', replaceNameInExportFor: 'TipoPqr' },
    { title: 'Identificación', name: 'Identificacion' },
    { title: 'Solicitante', name: 'NombreSolicitante' },
    { title: 'Telefono', name: 'Telefono' },
    { title: 'Correo Electrónico', name: 'CorreoElectronico' },
    { title: 'Número Radicado', name: 'NumeroRadicado' },
    { title: 'Descripción', name: 'Descripcion', classNameRow: 'cortar-texto-3' },
    { title: 'Estado', name: 'EstadoBadge', replaceNameInExportFor: 'Estado' },
    { title: 'Fecha', name: 'FechaPipe' },
    { title: '', name: 'Responder', hideInExport: true },
  ];

  public ConsultarPqrs(): void {
    this.CargandoTablaPqrs = true;
    this.ListaPqrs = [];
    if (this.RangoFecha) {
      this.Request.FechaInicial = this.RangoFecha[0];
      this.Request.FechaFinal = this.RangoFecha[1];
    }
    this._gestionPqrsService.ConsultarPQRS(this.Request).subscribe(response => {
      this.CargandoTablaPqrs = false;
      this.ListaPqrs = response.ListaPqrs;
      for (let pqr of this.ListaPqrs) {
        pqr.FechaPipe = new DatePipe("es-CO").transform(pqr.Fecha, 'dd/MM/yyyy');
        pqr.NombreSolicitante = pqr.Tercero.NombreCompleto;
        pqr.Identificacion = pqr.Tercero.Identificacion;
        pqr.Telefono = pqr.Tercero.Telefono;
        if (pqr.Estado == "Pendiente") {
          pqr.EstadoBadge = '<span class="label label-danger">' + pqr.Estado + '</span>';
          pqr.Responder = '<a href="javascript:void(0);" title="Responder ' + pqr.TipoPqr + ' "><i class="fa fa-send" aria-hidden="true">';
        } else {
          pqr.EstadoBadge = '<span class="label label-primary">' + pqr.Estado + '</span>';
          pqr.Responder = '<a href="javascript:void(0);" title="Ver detalle ' + pqr.TipoPqr + ' "><i class="fa fa-eercast" aria-hidden="true">';
        }
        if (pqr.TipoPqr == "Peticion") {
          pqr.TipoPqrBadge = '<span class="badge">' + pqr.TipoPqr + '</span>';
        } else if (pqr.TipoPqr == "Queja") {
          pqr.TipoPqrBadge = '<span class="badge badge-danger">' + pqr.TipoPqr + '</span>';
        } else if (pqr.TipoPqr == "Reclamo") {
          pqr.TipoPqrBadge = '<span class="badge badge-warning">' + pqr.TipoPqr + '</span>';
        } else {
          pqr.TipoPqrBadge = '<span class="badge badge-info">' + pqr.TipoPqr + '</span>';
        }

      }
    }, err => {
      this.CargandoTablaPqrs = false;
    });
  }

  public ClickFila(data: CellClickedTableGeneric): any {
    if (data.Columna === "Responder") {
      this._router.navigate(['/Pqrs/Responder/' + data.Fila.Id]);
    }
  }

}
