<div class="row">
  <div class="col-lg-4">
  </div>
  <div class="col-lg-4 text-center">
    <h1>Capacitaciones de la familia</h1>
  </div>
  <div class="col-lg-4">
  </div>
</div>
<div class="hr-line-dashed"></div>
<div class="row">
  <div class="col-lg-6">
    <div class="row">
      <div class="col-lg-8">
        <div class="form-group">
          <label>Capacitación</label>
          <select class="form-control" [(ngModel)]="RequestGestion.Capacitacion.SipaCapacitacionId" name="CapacitacionSipa">
            <option value="">Seleccione una opción</option>
            <option value="{{capacitacionSipa.Id}}" *ngFor="let capacitacionSipa of CapacitacionesParaRegistrar">{{capacitacionSipa.Nombre}}</option>
          </select>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <label>Fecha</label>
          <input class="form-control" type="date" [(ngModel)]="RequestGestion.Capacitacion.Fecha" />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="form-group">
          <label>Observación</label>
          <textarea rows="10" required [(ngModel)]="RequestGestion.Capacitacion.Observacion" name="ClasificacionFamiliarObservacion" style="width: 100%;"></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <button type="button" class="btn btn-primary btn-sm pull-right" (click)="Guardar()"><i class="fa fa-save (alias)"></i> Guardar</button>
    </div>
  </div>
  <div class="col-lg-6">
    <app-data-table-generic [NameReport]="'Capacitaciones registradas'" [Filas]="Capacitaciones" [ShowLoading]="CargandoTablaCapacitaciones" [columns]="Columnas"></app-data-table-generic>
  </div>
</div>
