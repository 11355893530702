import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Login } from '../../entities/security/login';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/security/auth.service';
import { Router } from '@angular/router';
import { NavigationComponent } from '../../components/common/navigation/navigation.component';

@Component({
    selector: 'login',
    templateUrl: 'login.template.html',
    providers: [AuthService],
})

export class LoginComponent implements OnInit {
    login: Login = {
        grant_type: "password",
        password: "",
        username: "",
        process: false,
    };

    public Year: number;

    constructor(private http: HttpClient, private _service: AuthService, private _router: Router) {
        if (_service.UserLogged()) { this._router.navigate(['/Home']); }
        this.Year = new Date().getFullYear();
    }

    ngOnInit(): void {
        NavigationComponent.DeteleObjectSingleton();
    }


    Login(): void{
        this._service.obtainAccessToken(this.login);
    }
}
