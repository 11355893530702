import { Injectable } from '@angular/core';
import { HttpService } from '../../../http/http.service';
import { Observable } from 'rxjs';
import { UtilitiesAnibalService } from '../../../utilities-anibal';
import { FamiliaIndigenaFactorProtector } from '../../../../entities/sipa/familiaindigena';
import { ApisRestCaracterizacionFamiliaIndigenaGestionFactorProtector } from '../../../../system/apisrest';

@Injectable()
export class FactorProtectorFamiliaIndigenaService {

  constructor(private _httpService: HttpService) { }

  public GetConsultarDatosFormulario(familiaIndigenaId: number): Observable<GestionFactorProtectorDatosFormularioResponse> {
    UtilitiesAnibalService.Console("Consultando FactorProtector de la familia (Datos formulario gestion)" + familiaIndigenaId);
    return this._httpService.get<GestionFactorProtectorDatosFormularioResponse>(ApisRestCaracterizacionFamiliaIndigenaGestionFactorProtector.GetConsultarDatosFormulario(familiaIndigenaId));
  }

  public GestionFactorProtector(request: GestionFactorProtectorRequest): Observable<GestionFactorProtectorResponse> {
    UtilitiesAnibalService.Console("Gestión FactorProtector de la familia" + request.FamiliaIndigenaId);
    return this._httpService.post<GestionFactorProtectorResponse>(ApisRestCaracterizacionFamiliaIndigenaGestionFactorProtector.PostGestionFactorProtector, request);
  }

}

export class GestionFactorProtectorResponse {
  public Mensaje: string;
}

export class GestionFactorProtectorRequest {
  public FamiliaIndigenaId: number;
  public FactoresProtectores: Array<FamiliaIndigenaFactorProtector> = new Array<FamiliaIndigenaFactorProtector>();
}

export class GestionFactorProtectorDatosFormularioResponse {
  public FactoresProtectores: Array<FamiliaIndigenaFactorProtector> = new Array<FamiliaIndigenaFactorProtector>();
}

