<div *ngIf="Usuario">
    <div class="panel panel-warning">
        <div class="panel-heading">
            {{Usuario.NombreCompleto}} - {{Usuario.Identificacion}}
        </div>
        <div class="panel-body">
            <p class="PaddingMensaje">aqui podra cambiar la contraseña del usuario {{Usuario.UserName}}</p>
            <div class="col-lg-12 col-sm-12 col-md-12">
                <div class="panel panel-success">

                    <div class="panel-heading">
                        Cambie la contraseña
                    </div>
                    <div class="panel-body">
                        <form ngNativeValidate #FormCambioPassword="ngForm" (ngSubmit)="CambiarPassword(FormCambioPassword)">
                            <div class="col-lg-4 col-sm-12 col-md-4">
                                <div class="form-group">
                                    <label>Contraseña</label>
                                    <input type="password" id="Password" [(ngModel)]="Request.Password" name="Password" required placeholder="Contraseña" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-12 col-md-4">
                                <div class="form-group">
                                    <label>Confirmar Contraseña</label>
                                    <input type="password" id="PasswordConfirm" [(ngModel)]="Request.PasswordConfirm" name="PasswordConfirm" required placeholder="Confirme Contraseña" class="form-control">
                                </div>
                            </div>
                            <div class="col-lg-4 col-sm-12 col-md-4" style="margin-top:15px;">
                                <button class="btn btn-primary pull-left" type="submit"><i class="fa fa-check"></i>&nbsp;Guardar</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
