import { Routes } from "@angular/router";

import { NgxPermissionsGuard } from 'ngx-permissions';
import { BasicLayoutComponent } from "../components/common/layouts/basicLayout.component";
import { GestionfamiliaindigenaComponent } from "../views/sipa/gestionfamiliaindigena/gestionfamiliaindigena.component";
import { CaracterizacionComponent } from "../views/sipa/caracterizacion/caracterizacion.component";
import { InformesComponent } from "../views/sipa/informes/informes.component";


export const SIPA: any = {
  path: 'Sipa', component: BasicLayoutComponent,
  children: [
    {
      path: 'Gestion/Familias', component: GestionfamiliaindigenaComponent, canActivate: [NgxPermissionsGuard],
      data: {
        permissions: {
          only: ['Sipa.GestionFamiliaIndigena'],
          redirectTo: 'Home'
        }
      }
    },
    {
      path: 'Caracterizacion/Familia/:numeroFamiliaIndigena', component: CaracterizacionComponent, canActivate: [NgxPermissionsGuard],
      data: {
        permissions: {
          only: ['Sipa.GestionFamiliaIndigena'],
          redirectTo: 'Home'
        }
      }
    },
    {
      path: 'Informes', component: InformesComponent, canActivate: [NgxPermissionsGuard],
      data: {
        permissions: {
          only: ['Sipa.Informes'],
          redirectTo: 'Home'
        }
      }
    },
  ]
};
