<div class="text-center  p-md">
  <span>Para registrar o activar una vigencia por favor digitela aquí: </span>
  <span><input [(ngModel)]="Request.VigenciaNueva" placeholder="Vigencia"/></span>
  <a href="javascript:0;" (click)="NuevaVigencia()" class="btn btn-xs btn-primary" style="margin-bottom: 3px; margin-left: 3px;" id="lightVersion"> Aceptar</a>
</div>
<div class="ibox-content" id="ibox-content">

  <div id="vertical-timeline" class="vertical-container dark-timeline center-orientation">
    <div class="vertical-timeline-block" *ngFor="let vigencia of Vigencias">
      <div class="vertical-timeline-icon" [ngClass]="vigencia.EstaActiva?'navy-bg':'bg-danger'">
        <i class="fa fa-calendar"></i>
      </div>
      <div class="vertical-timeline-content">
        <h2>Vigencia: {{vigencia.Vigencia}}</h2>
        <p>
          {{vigencia.Descripcion}}
        </p>
        <span class="vertical-date">
          {{vigencia.Vigencia}}<br>
          <small *ngIf="vigencia.EstaActiva">{{FechaActual| date}}</small>
        </span>
      </div>
    </div>
  </div>

</div>
