import { Component, OnInit, ViewChild, ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { ComponentModal } from './entities/component-modal';
import { AddComponentDirective } from './directives/add-component.directive';
import { IComponentModal } from './entities/interface-component-modal';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-modal-generic',
  templateUrl: './modal-generic.component.html',
  styleUrls: ['./modal-generic.component.css']
})
export class ModalGenericComponent implements OnInit {

    public componentRefInstance: ComponentRef<IComponentModal>;

    private _modalComponentData: ComponentModal;
  public BsModalRef: BsModalRef;
  public title: string;
  public dialogSize: string;
    set modalComponentData(modalComponentData: ComponentModal) {
        this._modalComponentData = modalComponentData;
        this.loadComponent();
    }

    get modalComponentData(): ComponentModal {
        return this._modalComponentData;
    }
  @ViewChild(AddComponentDirective) adHost: AddComponentDirective;

    constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

    ngOnInit() {
    }

    loadComponent() {
        const modalComponentData = this.modalComponentData;

        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(modalComponentData.component);

        const viewContainerRef = this.adHost.viewContainerRef;
        viewContainerRef.clear();

        this.componentRefInstance = viewContainerRef.createComponent(componentFactory);
        this.componentRefInstance.instance.Data = modalComponentData.data;
    }

    public dismiss() {
        this.BsModalRef.hide();
    }
}
