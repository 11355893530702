import { Component, OnInit, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FamiliaIndigena } from '../../../../../entities/sipa/familiaindigena';
import { UtilitiesAnibalService } from '../../../../../services/utilities-anibal';
import { EventEmitter } from '@angular/core';
import { GestionClasificacionFamiliarRequest, ClasificacionFamiliarService } from '../../../../../services/sipa/caracterizacion/diagnosticofamiliar/clasificacion-familiar.service';

@Component({
  selector: 'app-clasificacionfamiliar',
  templateUrl: './clasificacionfamiliar.component.html',
  styleUrls: ['./clasificacionfamiliar.component.css'],
  providers: [ToastrService, ClasificacionFamiliarService]
})
export class ClasificacionfamiliarComponent implements OnInit {

  constructor(
    private _toastr: ToastrService,
    private _clasificacionFamiliarService: ClasificacionFamiliarService,
  ) { }

  ngOnInit() {
  }

  @Output() public NotificacionComponente: EventEmitter<GestionClasificacionFamiliarComponentEventResponse> = new EventEmitter();
  private ObjectNotificacion: GestionClasificacionFamiliarComponentEventResponse = new GestionClasificacionFamiliarComponentEventResponse();

  private _FamiliaIndigena: FamiliaIndigena;
  @Input()
  set FamiliaIndigena(familia: FamiliaIndigena) {
    this._FamiliaIndigena = familia;
    if (familia && familia.Id) {
      this.RequestGestion.FamiliaIndigenaId = familia.Id;
      this.ConsultarDatosFormulario();
    }
  }

  get FamiliaIndigena(): FamiliaIndigena {
    return this._FamiliaIndigena;
  }
  
  public RequestGestion: GestionClasificacionFamiliarRequest = new GestionClasificacionFamiliarRequest();

  public Guardar(): void {
    UtilitiesAnibalService.ShowLoading();
    this._clasificacionFamiliarService.GestionClasificacionFamiliar(this.RequestGestion).subscribe(response => {
      UtilitiesAnibalService.HideLoading();
      this.ConsultarDatosFormulario();
      this._toastr.success(response.Mensaje, "Correcto!");
    }, err => {
      UtilitiesAnibalService.HideLoading();
    });
  }

  public ConsultarDatosFormulario(): void {
    this.ObjectNotificacion.EstaConsultando = true;
    this.NotificacionComponente.emit(this.ObjectNotificacion);
    this._clasificacionFamiliarService.GetConsultarDatosFormulario(this._FamiliaIndigena.Id).subscribe(response => {
      this.ObjectNotificacion.EstaConsultando = false;
      this.RequestGestion.ClasificacionFamiliar = response.ClasificacionFamiliar;
      this.NotificacionComponente.emit(this.ObjectNotificacion);
    }, err => {
    });
  }
}

export class GestionClasificacionFamiliarComponentEventResponse {
  public EstaConsultando: boolean;
}
