import { Injectable } from '@angular/core';
import { ForzarEmailConfirmResponse } from './entities/forzar-emailconfirm';
import { HttpService } from '../../http/http.service';
import { ApisRestForzarEmailConfirm } from '../../../system/apisrest';

import { UtilitiesAnibalService } from '../../utilities-anibal';
import { Observable } from 'rxjs';
import { GetUserResponse } from '../../../services/security/general/entities/get-user-response';

@Injectable()
export class ForzarEmailConfirmService {

  constructor(private _httpService: HttpService) { }

  public ActivarCuenta(userName: string): Observable<ForzarEmailConfirmResponse> {
    UtilitiesAnibalService.Console("Activando cuenta");
    return this._httpService.get<ForzarEmailConfirmResponse>(ApisRestForzarEmailConfirm.GetConfirmEmail + "/" + userName);
  }

  public GetDatosUsuario(userName: string): Observable<GetUserResponse> {
    UtilitiesAnibalService.Console("Consultando Usuario");
    return this._httpService.get<GetUserResponse>(ApisRestForzarEmailConfirm.GetUser + "/" + userName);
  }
}
