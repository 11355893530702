import { Component, OnInit, TemplateRef } from '@angular/core';

//import * as html2canvas from "html2canvas"
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SoporteTecnicoRequest, SoporteTecnicoService } from '../../../services/general/soporte-tecnico.service';
import { ToastrService } from 'ngx-toastr';
import { UtilitiesAnibalService } from '../../../services/utilities-anibal';
import { NgxCaptureService } from 'ngx-capture';
import { SistemaService } from '../../../services/security/sistema.service';

@Component({
  selector: 'app-soporte-tecnico',
  templateUrl: './soporte-tecnico.component.html',
  styleUrls: ['./soporte-tecnico.component.css'],
  providers: [SoporteTecnicoService, ToastrService, SistemaService, NgxCaptureService]
})
export class SoporteTecnicoComponent implements OnInit {

  constructor(
    private _modalService: BsModalService,
    private _toastr: ToastrService,
    private _sistemaService: SistemaService,
    private _soporteTecnicoService: SoporteTecnicoService,
    private _ngxCaptureService: NgxCaptureService) {
  }

  ngOnInit() {
    this.downloadImage();
  }

  public CapturePantalla: string;
  public Request: SoporteTecnicoRequest = new SoporteTecnicoRequest();
  public disabledButton: boolean = false;
  private modalRef: BsModalRef;

  public OpenModal(template: TemplateRef<any>) {
    this.downloadImage();
    setTimeout(() => {
      this.modalRef = this._modalService.show(template, {class:'modal-lg'});
    }, 800);
  }

  downloadImage() {
    var nodo = document.getElementById('CuerpoSistema');
    this._ngxCaptureService.getImage(nodo, true).then(image => {
      this.CapturePantalla = image;
      this.Request.Documento = this.CapturePantalla;
      this.Request.ExtensionDocumento = "png";
      var fecha = new Date();
      this.Request.NombreDocumento = "Soporte técnico " + fecha.getFullYear() + "" + (fecha.getMonth() + 1) + "" + fecha.getDay() + "" + fecha.getMinutes() + "" + fecha.getSeconds() + "-" + this._sistemaService.GetNombreTerceroLogged();
    });
    //html2canvas(nodo).then(canvas => {
    //  this.CapturePantalla = canvas.toDataURL();
    //  this.Request.Documento = this.CapturePantalla;
    //  this.Request.ExtensionDocumento = "png";
    //  var fecha = new Date();
    //  this.Request.NombreDocumento = "Soporte técnico " + fecha.getFullYear() + "" + (fecha.getMonth() + 1) + "" + fecha.getDay() + "" + fecha.getMinutes() + "" + fecha.getSeconds();
    //});
  }

  public EnviarSoporteTecnico(): void {
    this.disabledButton = true;
    UtilitiesAnibalService.ShowLoading();
    this._soporteTecnicoService.EnviarSoporte(this.Request).subscribe(response => {
      this._toastr.success(response.Mensaje, "Correcto!");
      UtilitiesAnibalService.HideLoading();
      this.disabledButton = false;
    }, err => {
      this.disabledButton = false;
      UtilitiesAnibalService.HideLoading();
      this._toastr.info("Intente nuevamente!!!", "Fallo!");
    });
  }

}
