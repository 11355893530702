import { Injectable } from '@angular/core';
import { HttpServiceAllonimous } from '../../http/http.service';
import { Observable } from 'rxjs';
import { UtilitiesAnibalService } from '../../utilities-anibal';
import { ApisRestPublic } from '../../../system/apisrest';

@Injectable()
export class IndexService {

    constructor(private _httpServiceAllonimous: HttpServiceAllonimous) {
    }

    public GetPaginaPrincipal(): Observable<ConsultaPaginaInicioResponse> {
        UtilitiesAnibalService.Console("Consultando pagina de inicio");
        return this._httpServiceAllonimous.get<ConsultaPaginaInicioResponse>(ApisRestPublic.GetPaginaPrincipalIndex);
    }
}

export class ConsultaPaginaInicioResponse {
    public UrlPaginaPrincipal: string;
}
