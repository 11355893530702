import { Injectable } from '@angular/core';
import { UtilitiesAnibalService } from '../utilities-anibal';
import { ApisRestPublic } from '../../system/apisrest';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';
import { DatosEntidad } from '../../entities/general/datos-entidad';

@Injectable()
export class DatosEntidadService {

  public
  constructor(private _httpService: HttpService) { }

  public GetDatos(): Observable<DatosEntidadResponse> {
    const observableResponse = new Observable<DatosEntidadResponse>(observer => {
      if (!HelperDatosEntidadService.DatosEntidad) {
        UtilitiesAnibalService.Console("Consultando datos de la entidad");
        this._httpService.get<DatosEntidadResponse>(ApisRestPublic.GetDatosEntidad).subscribe(datos => {
          HelperDatosEntidadService.DatosEntidad = datos;
          observer.next(HelperDatosEntidadService.DatosEntidad);
        });
      } else {
        observer.next(HelperDatosEntidadService.DatosEntidad);
      }
    });

    return observableResponse;
  }
}

export class DatosEntidadResponse {
  public DatosEntidad: DatosEntidad;
}

export class HelperDatosEntidadService {
  public static DatosEntidad: DatosEntidadResponse;
}
