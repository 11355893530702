<div *ngIf="Usuario && Tercero">
    <div class="col-lg-12">
        <div class="widget-head-color-box navy-bg p-lg text-center">
            <div class="m-b-md">
                <h2 class="font-bold no-margins">
                    {{Usuario.NombreCompleto}}
                </h2>
                <!-- <small>{{Usuario.Identificacion}}</small>-->
            </div>
            <img src="assets/images/a4.jpg" class="img-circle circle-border m-b-md" alt="profile">

            <div>
                <span>{{Usuario.Email}}</span> |
                <span>{{Usuario.Identificacion}}</span> |
                <span>{{Tercero.Telefono}}</span>
            </div>
        </div>
        <div class="widget-text-box">
            <form ngNativeValidate #FormModificarTercero="ngForm" (ngSubmit)="ModificarTercero(FormModificarTercero)">
                <div class="row">
                  <app-detalle-tercero [Tercero]="Tercero" [DeshabilitarInputs]="false" (ChangeIdentificacion)="CambioIdentificacion($event)" (ChangeCorreoElectronico)="CambioCorreoElectronico($event)"></app-detalle-tercero>
                </div>
                <div class="hr-line-dashed"></div>
                <div class="row">
                    <div class="col-lg-4 col-sm-12 col-md-4">
                        <div class="form-group">
                            <label>Usuario</label>
                            <input type="text" id="Usuario" [(ngModel)]="Usuario.UserName" name="UserName" [disabled]="true" required placeholder="Usuario" class="form-control">
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12 col-md-6">
                        <div class="form-group">
                            <label>Correo Electrónico</label>
                            <input type="email" id="Correo" [(ngModel)]="Usuario.Email" name="Email" [disabled]="true" required placeholder="Correo Electrónico" class="form-control">
                        </div>
                    </div>
                </div>
                <div class="hr-line-dashed"></div>
                <div class="row">
                    <div class="form-group pull-right" style="margin-right:10px;">
                        <button class="btn btn-primary pull-left" type="submit" [disabled]="disabledButton"><i class="fa fa-check"></i>&nbsp;Modificar</button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
