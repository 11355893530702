import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from "@angular/router";

import { StarterViewComponent } from "./starterview.component";
//import { LoginComponent } from "./login.component";

import { FormsModule } from '@angular/forms';

// Componentes Instalados
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

//Componentes y Servicios Creados
//import { AuthService } from '../../services/security/auth.service';

@NgModule({
    declarations: [
        StarterViewComponent,
        //LoginComponent,
        //AuthService
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        RouterModule,
        FormsModule,
        ToastrModule.forRoot({
            timeOut: 10000,
            progressBar:true,
            positionClass: 'toast-bottom-center',
            //preventDuplicates: true,
        }),
        BrowserAnimationsModule
    ],
    exports: [
        StarterViewComponent,
        //LoginComponent
    ],
})

export class AppviewsModule {
}
