import { Component, OnInit, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FamiliaIndigena } from '../../../../../entities/sipa/familiaindigena';
import { UtilitiesAnibalService } from '../../../../../services/utilities-anibal';
import { EventEmitter } from '@angular/core';
import { ApgarFamiliaIndigenaService, GestionApgarRequest } from '../../../../../services/sipa/caracterizacion/diagnosticofamiliar/apgar-familia-indigena.service';
import { EnumerationObject } from '../../../../../entities/general/enumeration-object';


@Component({
  selector: 'app-apgar',
  templateUrl: './apgar.component.html',
  styleUrls: ['./apgar.component.css'],
  providers: [ToastrService, ApgarFamiliaIndigenaService]
})
export class ApgarComponent implements OnInit {

  constructor(
    private _toastr: ToastrService,
    private _ApgarFamiliaIndigenaService: ApgarFamiliaIndigenaService,
  ) { }

  ngOnInit() {
  }

  @Output() public NotificacionComponente: EventEmitter<GestionApgarComponentEventResponse> = new EventEmitter();
  private ObjectNotificacion: GestionApgarComponentEventResponse = new GestionApgarComponentEventResponse();

  public RespuestasApgars: Array<EnumerationObject> = new Array<EnumerationObject>();

  private _FamiliaIndigena: FamiliaIndigena;
  @Input()
  set FamiliaIndigena(familia: FamiliaIndigena) {
    this._FamiliaIndigena = familia;
    if (familia && familia.Id) {
      this.RequestGestion.FamiliaIndigenaId = familia.Id;
      this.ConsultarDatosFormulario();
    }
  }

  get FamiliaIndigena(): FamiliaIndigena {
    return this._FamiliaIndigena;
  }

  public RequestGestion: GestionApgarRequest = new GestionApgarRequest();

  public Guardar(): void {
    UtilitiesAnibalService.ShowLoading();
    this._ApgarFamiliaIndigenaService.GestionApgar(this.RequestGestion).subscribe(response => {
      UtilitiesAnibalService.HideLoading();
      this.ConsultarDatosFormulario();
      this._toastr.success(response.Mensaje, "Correcto!");
    }, err => {
      UtilitiesAnibalService.HideLoading();
    });
  }

  public ConsultarDatosFormulario(): void {
    this.ObjectNotificacion.EstaConsultando = true;
    this.NotificacionComponente.emit(this.ObjectNotificacion);
    this._ApgarFamiliaIndigenaService.GetConsultarDatosFormulario(this._FamiliaIndigena.Id).subscribe(response => {
      this.ObjectNotificacion.EstaConsultando = false;
      this.RequestGestion.Apgar = response.Apgar;
      this.RespuestasApgars = response.RespuestasApgars;
      if (this.RequestGestion.Apgar.Id == 0) {
        for (let integrante of this.RequestGestion.Apgar.Cuestionarios) {
          integrante.Valor = "";
        }
      }
      this.NotificacionComponente.emit(this.ObjectNotificacion);
    }, err => {
    });
  }
}

export class GestionApgarComponentEventResponse {
  public EstaConsultando: boolean;
}

