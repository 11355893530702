import { Component, OnInit, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FamiliaIndigena, FamiliaIndigenaRemision } from '../../../../../entities/sipa/familiaindigena';
import { UtilitiesAnibalService } from '../../../../../services/utilities-anibal';
import { EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { RemisionesFamiliaIndigenaService, GestionremisionesRequest } from '../../../../../services/sipa/caracterizacion/diagnosticofamiliar/remisiones-familia-indigena.service';

@Component({
  selector: 'app-remisiones',
  templateUrl: './remisiones.component.html',
  styleUrls: ['./remisiones.component.css'],
  providers: [ToastrService, RemisionesFamiliaIndigenaService]
})
export class RemisionesComponent implements OnInit {

  constructor(
    private _toastr: ToastrService,
    private _gestionremisionesService: RemisionesFamiliaIndigenaService,
  ) { }

  ngOnInit() {
  }

  @Output() public NotificacionComponente: EventEmitter<GestionRemisionesComponentEventResponse> = new EventEmitter();
  private ObjectNotificacion: GestionRemisionesComponentEventResponse = new GestionRemisionesComponentEventResponse();

  private _FamiliaIndigena: FamiliaIndigena;
  @Input()
  set FamiliaIndigena(familia: FamiliaIndigena) {
    this._FamiliaIndigena = familia;
    if (familia && familia.Id) {
      this.RequestGestion.FamiliaIndigenaId = familia.Id;
      this.ConsultarDatosFormulario();
    }
  }

  get FamiliaIndigena(): FamiliaIndigena {
    return this._FamiliaIndigena;
  }

  public RequestGestion: GestionremisionesRequest = new GestionremisionesRequest();

  public NuevoIntegrante(): void {
    let objeto = new FamiliaIndigenaRemision();
    this.RequestGestion.Remisiones.push(objeto);
  }

  public Guardar(): void {
    UtilitiesAnibalService.ShowLoading();
    this._gestionremisionesService.GestionRemisionesFamilia(this.RequestGestion).subscribe(response => {
      UtilitiesAnibalService.HideLoading();
      this.ConsultarDatosFormulario();
      this._toastr.success(response.Mensaje, "Correcto!");
    }, err => {
      UtilitiesAnibalService.HideLoading();
    });
  }

  public ConsultarDatosFormulario(): void {
    this.ObjectNotificacion.EstaConsultando = true;
    this.NotificacionComponente.emit(this.ObjectNotificacion);
    this._gestionremisionesService.GetConsultarDatosFormulario(this._FamiliaIndigena.Id).subscribe(response => {
      this.ObjectNotificacion.EstaConsultando = false;
      this.RequestGestion.Remisiones = response.Remisiones;
      this.ObjectNotificacion.CantidadRemisiones = response.Remisiones.length;
      this.NotificacionComponente.emit(this.ObjectNotificacion);
    }, err => {
    });
  }

  public ConsultarIntegranteParaRemision(remision: FamiliaIndigenaRemision): void {
    if (remision.IdentificacionIntegrante) {
      UtilitiesAnibalService.ShowLoading();
      this._gestionremisionesService.GetIntegranteParaRemision(remision.IdentificacionIntegrante, this._FamiliaIndigena.Id).subscribe(response => {
        UtilitiesAnibalService.HideLoading();
        if (response.Integrantes.length > 0) {
          if (response.Integrantes.length == 1) {
            var integrante = response.Integrantes[0];
            var remisiones = this.RequestGestion.Remisiones.filter(function (remision, index) {
              return remision.IdentificacionIntegrante == integrante.Identificacion;
            });
            if (remisiones.length == 1) {
              var remision = remisiones[0];
              remision.NombreCompletoIntegrante = integrante.NombreCompleto;
              remision.SexoIntegrante = integrante.Sexo;
              remision.FamiliaIndigenaIntegranteId = integrante.Id;
            } else {
              this._toastr.warning("Se detecto que existen identificaciones duplicadas para realizar el registro de remisiones, por favor verifique!!");
            }
          } else {
            ///Si esto pasa es porque en la familia se registraron dos integrantes con la misma identificación, esto se deberia validar 2019-08-02: 12:39 am
            this._toastr.warning("Se encontraron mas de un integrante con la misma identificación, por favor comuniquese con el departamento de soporte!!");
          }
        } else {
          this._toastr.warning("No se encontro integrante para realizar la remisión");
        }
      }, err => {
        UtilitiesAnibalService.HideLoading();
      });
    }
  }
}

export class GestionRemisionesComponentEventResponse {
  public CantidadRemisiones: number;
  public EstaConsultando: boolean;
}

