<div class="col-lg-12 col-sm-12 col-md-12">
    <div class="panel panel-success">
        <div class="panel-heading">
            Información del P.Q.R.S
        </div>
        <div class="panel-body">
            <form ngNativeValidate #FormRegistroPqr="ngForm" (ngSubmit)="RegistroPqr(FormRegistroPqr)">
                <div class="row col-lg-12 col-sm-12 col-md-12">
                    <app-detalle-tercero [Tercero]="Request.Tercero" [DeshabilitarInputs]="false"></app-detalle-tercero>
                </div>
                <div class="row ">
                    <div class="col-lg-6 col-sm-12 col-md-6">
                        <div class="form-group">
                            <label>Categoria</label>
                            <select required class="form-control" id="TipoIdentificacion"  [(ngModel)]="Request.TipoPqr" name="TipoPqr" required>
                                <option value="Peticion">Petición</option>
                                <option value="Queja">Queja</option>
                                <option value="Reclamo">Reclamo</option>
                                <option value="Solicitud">Solicitud</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-12 col-md-6">
                      <div class="form-group">
                        <label>Documento de soporte</label>
                        <input type="file" class="form-control" name="documents" (change)="onChange($event)" [(ngModel)]="DocumentoSoporte" #documents="ngModel">
                        <!--<input type="file" id="DocumentoSoporte" name="DocumentoSoporte" placeholder="Documento soporte" class="form-control">-->
                      </div>
                    </div>
                    <div class="col-lg-12 col-sm-12 col-md-12">
                        <div class="form-group">
                            <textarea rows="10" name="Descripcion" [(ngModel)]="Request.Descripcion" style="width: 100%;">
                                
                            </textarea>
                        </div>
                    </div>
                </div>
                <div class="row col-lg-12 col-sm-12 col-md-12">
                    <button class="btn btn-primary pull-left" type="submit" [disabled]="disabledButton"><i class="fa fa-check"></i>&nbsp;Registrar</button>
                </div>
            </form>
        </div>
    </div>
</div>
<ng-template #ModalNumeroRadicado>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Numero Radicado</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="alert alert-info">
                Estimado usuario, por favor guarde este radicado para que pueda hacerle seguimiento a su {{TipoPqrsParaMensaje}}
            </div>
            <div class="bg-primary p-xs b-r-sm">{{NumeroRadicado}}</div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-default pull-right" (click)="modalRef.hide()">Cerrar</button>
    </div>

</ng-template>
