
export class User {
  public Email: string;
  public LockoutEndDateUtc: Date;
  public EmailConfirmed: boolean;
  public LockoutEnabled: boolean;
  public AccessFailedCount: number;
  public CorreoConfirmado: string;
  public FinBloqueo: string;
  public BloqueoHabilitado: string;
  public AccessosFallidos: number;
  public UserName: string;
  public FechaDesactivacion: Date;
  public NombreCompleto: string;
  public Identificacion: string;
  public TerceroId: number;
  public Estado: string;
}

export class UserTable extends User {
  public EstadoBadge: string;
  public CorreoConfirmadoBadge: string;
  public Permission: string;
  public ForzeEmailConfirm: string;
  public ChangePassword: string;
  public ModificarUser: string;
  public AsignarPaginaInicio: string;
  public FechaPipe: string;
}
