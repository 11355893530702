<div class="row border-bottom white-bg">
  <nav class="navbar navbar-static-top" role="navigation">
    <div class="navbar-header">
      <button aria-controls="navbar" aria-expanded="false" data-target="#navbar" data-toggle="collapse" class="navbar-toggle collapsed" type="button">
        <i class="fa fa-reorder"></i>
      </button>
      <a href="#" class="navbar-brand">Inspinia</a>
    </div>
    <div class="navbar-collapse collapse" id="navbar">
      <ul class="nav navbar-nav">
        <li class="active">
          <a aria-expanded="false" role="button" [routerLink]="['/layoutsview']"> Back to main Layout page</a>
        </li>
        <li dropdown>
          <a aria-expanded="false" role="button"  dropdownToggle> Menu item <span class="caret"></span></a>
          <ul role="menu" *dropdownMenu class="dropdown-menu">
            <li><a href="">Menu item</a></li>
            <li><a href="">Menu item</a></li>
            <li><a href="">Menu item</a></li>
            <li><a href="">Menu item</a></li>
          </ul>
        </li>
        <li dropdown>
          <a aria-expanded="false" role="button" dropdownToggle> Menu item <span class="caret"></span></a>
          <ul role="menu" *dropdownMenu class="dropdown-menu">
            <li><a href="">Menu item</a></li>
            <li><a href="">Menu item</a></li>
            <li><a href="">Menu item</a></li>
            <li><a href="">Menu item</a></li>
          </ul>
        </li>
        <li dropdown>
          <a aria-expanded="false" role="button" dropdownToggle> Menu item <span class="caret"></span></a>
          <ul role="menu" *dropdownMenu class="dropdown-menu">
            <li><a href="">Menu item</a></li>
            <li><a href="">Menu item</a></li>
            <li><a href="">Menu item</a></li>
            <li><a href="">Menu item</a></li>
          </ul>
        </li>
        <li dropdown>
          <a aria-expanded="false" role="button" dropdownToggle> Menu item <span class="caret"></span></a>
          <ul role="menu" *dropdownMenu class="dropdown-menu">
            <li><a href="">Menu item</a></li>
            <li><a href="">Menu item</a></li>
            <li><a href="">Menu item</a></li>
            <li><a href="">Menu item</a></li>
          </ul>
        </li>
      </ul>
      <ul class="nav navbar-top-links navbar-right">
        <li>
          <a href="login.html">
            <i class="fa fa-sign-out"></i> Log out
          </a>
        </li>
      </ul>
    </div>
  </nav>
</div>
