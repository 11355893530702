import { Component, OnInit, Input, Output, ViewChild, EventEmitter, SimpleChanges } from '@angular/core';
import { User } from '../../../entities/security/user';
import { ChangePasswordService } from '../../../services/security/changepassword/change-password.service';
import { ChangePasswordRequest, ChangePasswordResponse } from '../../../services/security/changepassword/entities/change-password';
import { Modulos, Permission } from '../../../entities/security/modulos';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
  providers: [ToastrService, ChangePasswordService]
})
export class ChangePasswordComponent implements OnInit {

  @Input() Usuario: User;
  @Input() ApiPostChangePassword: string;

  public Request: ChangePasswordRequest = { Password: "", PasswordConfirm: "", UserName: "" };

  constructor(
    private route: ActivatedRoute,
    private location: Location,
    private _toastr: ToastrService,
    private _changePasswordService: ChangePasswordService
  ) { }

  ngOnInit() {

  }

  public CambiarPassword(_requestForm): void {

    this.Request.UserName = this.Usuario.UserName;

    if (!this.CamposValidos()) { return; }

    this._changePasswordService.CambiarPassword(this.Request, this.ApiPostChangePassword).subscribe(response => {
      if (!response.Error) {
        this._toastr.success(response.Mensaje, "Correcto!!");
      } else {
        this._toastr.error(response.Mensaje, "Error!!");
      }
    });
  };

  private CamposValidos(): boolean {
    if (this.Request.Password != this.Request.PasswordConfirm) {
      this._toastr.warning("Las contraseñas no son iguales", "Advertencia!!");
      return false;
    }
    if (!this.Request.UserName) {
      this._toastr.warning("No se ha establecido el usuario a cambiar contraseña", "Advertencia!!");
      return false;
    }
    return true;
  }

}
