import { Component, OnInit, Input, Output, ViewChild, EventEmitter, SimpleChanges } from '@angular/core';
import { User } from '../../../../../entities/security/user';
import { ForzarEmailConfirmService } from '../../../../../services/security/forzemailconfirm/forzar-email-confirm.service';
import { ForzarEmailConfirmResponse } from '../../../../../services/security/forzemailconfirm/entities/forzar-emailconfirm';
import { Modulos, Permission } from '../../../../../entities/security/modulos';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-forze-email-confirm',
    templateUrl: './forze-email-confirm.component.html',
    styleUrls: ['./forze-email-confirm.component.css'],
    providers: [ ToastrService, ForzarEmailConfirmService]
})
export class ForzeEmailConfirmComponent implements OnInit {

    @Input() Usuario: User;
    public disabledButton: boolean = false;
    constructor(
        private route: ActivatedRoute,
        private location: Location,
        private _toastr: ToastrService,
        private _forzarEmailConfirmService: ForzarEmailConfirmService
    ) { }

    ngOnInit() {
      this._forzarEmailConfirmService.GetDatosUsuario(this.route.snapshot.paramMap.get('userName'))
            .subscribe(response => {
                if (!response.Error) {
                    this.Usuario = response.Usuario;
                } else {
                    this._toastr.error(response.Mensaje, "Error!!");
                }
            });
    }

    public ActivarCuenta(): void {
        this.disabledButton = true;
        this._forzarEmailConfirmService.ActivarCuenta(this.Usuario.UserName).subscribe(response => {
            this.disabledButton = false;
            if (!response.Error) {
                this._toastr.success(response.Mensaje, "Correcto!!");
            } else {
                this._toastr.error(response.Mensaje, "Error!!");
            }
        });
    };
}
