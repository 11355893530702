import { Component } from '@angular/core';
import { smoothlyMenu } from '../../../app.helpers';
import { AuthService } from '../../../services/security/auth.service';
import { Title } from '@angular/platform-browser';

declare var jQuery: any;

@Component({
  selector: 'topnavbar',
  templateUrl: 'topnavbar.template.html',
  providers: [AuthService]
})
export class TopNavbarComponent {

  constructor(private _serviceAuth: AuthService, private _titleService: Title) {

  }
  toggleNavigation(): void {
    jQuery("body").toggleClass("mini-navbar");
    smoothlyMenu();
  }

  Logout(): void {
    this._serviceAuth.logout();
    this.CambiarTitle();
  }

  private CambiarTitle() {
    var titulo = this._titleService.getTitle();
    if (titulo.toString().indexOf(" | ") > 0) {
      titulo = titulo.toString().split(" | ")[0];
    }
    this._titleService.setTitle(titulo);
  }
}
