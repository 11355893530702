import { Injectable } from '@angular/core';
import { HttpService } from '../../http/http.service';
import { ApisRestGestionVigencias } from '../../../system/apisrest';

import { Observable } from 'rxjs';
import { UtilitiesAnibalService } from '../../utilities-anibal';

@Injectable()
export class GestionVigenciasService {

  constructor(private _httpService: HttpService) { }

  public ConsultarTodasLasVigencias(): Observable<ConsultarTodasLasVigenciasResponse> {
    UtilitiesAnibalService.Console("Consultando vigencias del sistema");
    return this._httpService.get<ConsultarTodasLasVigenciasResponse>(ApisRestGestionVigencias.GetAllVigencias);
  }

  public RegistrarVigencia(request: RegistrarVigenciaRequest): Observable<RegistrarVigenciaResponse> {
    UtilitiesAnibalService.Console("Registrando nueva vigencia del sistema");
    return this._httpService.post<RegistrarVigenciaResponse>(ApisRestGestionVigencias.PostRegistrarNuevaVigencia,request);
  }

  
}

export class RegistrarVigenciaRequest {
  public VigenciaNueva: number;
}

export class RegistrarVigenciaResponse {
  public Mensaje: string;
}

export class ConsultarTodasLasVigenciasResponse {
  public Vigencias: Array<VigenciaTimeLine>;
}

export class VigenciaTimeLine {
  public Vigencia: number;
  public Descripcion: string;
  public EstaActiva: boolean;
}
