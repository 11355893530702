<div *ngIf="Usuario">
    <div class="col-lg-12">
        <div class="widget-head-color-box navy-bg p-lg text-center">
            <div class="m-b-md">
                <h2 class="font-bold no-margins">
                    {{Usuario.NombreCompleto}}
                </h2>
               <!-- <small>{{Usuario.Identificacion}}</small>-->
            </div>
            <img src="assets/images/a4.jpg" class="img-circle circle-border m-b-md" alt="profile">

            <div>
                <span>{{Usuario.Email}}</span> |
                <span>{{Usuario.Identificacion}}</span>
                <!--<span>610 Followers</span>-->
            </div>
        </div>
        <div class="widget-text-box">
            <h4 class="media-heading">Usuario: {{Usuario.UserName}}</h4>

            <p>Presione click en <b>Confirmar</b> para activar la cuenta.</p>

            <div class="text-right">
                <button class="btn btn-xs btn-primary" [disabled]="disabledButton" (click)="ActivarCuenta()"><i class="fa fa-check-circle"></i> Activar</button>
            </div>
        </div>
    </div>
</div>