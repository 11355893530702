import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { UtilitiesAnibalService } from './services/utilities-anibal';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public constructor(private titleService: Title) { }

  public ServiceLoading = UtilitiesAnibalService;


  ngOnInit() {
    this.setTitle();
  }

  public setTitle() {
    this.titleService.setTitle(this.ServiceLoading.AppName.toUpperCase());
  }
}
