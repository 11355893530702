<!-- Main view/routes wrapper-->
<router-outlet></router-outlet>
<div class="loading-background " *ngIf="ServiceLoading" [ngClass]="ServiceLoading.Loading?'':'hide'">
  <div class="rotate-center">
    <img class="logo img-circle" src="assets/images/Logo.png" style="height: 90px;width:90px;">
  </div>
  <div class="text-shadow-pop-tl text-center">
    <div style="margin-bottom: -34px;">{{ServiceLoading.AppName}}</div>
    <small style="font-size: 28px;">Cargando...</small>
  </div>
</div>
