import { Injectable } from '@angular/core';
import { GetUserResponse } from './entities/get-user-response';
import { HttpService } from '../../http/http.service';
import { ApisRestUser } from '../../../system/apisrest';

import { UtilitiesAnibalService } from '../../utilities-anibal';
import { Observable } from 'rxjs';

@Injectable()
export class UserServiceService {

  constructor(private _httpService: HttpService) { }

  public GetDatosUsuario(): Observable<GetUserResponse> {
    UtilitiesAnibalService.Console("Consultando usuario autenticado");
    return this._httpService.get<GetUserResponse>(ApisRestUser.GetUser);
  }

  public GetApiPostChangePasswordForUser(): string {
    return ApisRestUser.PostChangePassword;
  }
}
