import { Component, OnInit } from '@angular/core';
import { ConstantConfigSystem } from '../../../system/constantconfig';
import { InformesService, InformeAsentamientosFamiliasCaracterizadas, InformeEcomapaPorAsentamientos } from '../../../services/sipa/informes/informes.service';
import { UtilitiesAnibalService } from '../../../services/utilities-anibal';
import { ToastrService } from 'ngx-toastr';
import { EnumerationObject } from '../../../entities/general/enumeration-object';

@Component({
  selector: 'app-informes',
  templateUrl: './informes.component.html',
  styleUrls: ['./informes.component.scss'],
  providers: [ToastrService,InformesService]
})
export class InformesComponent implements OnInit {

  constructor(private _toastr: ToastrService,private _informesService: InformesService) { }

  public AppName: string = ConstantConfigSystem.AppName;

  public EcomapaSeleccionado: string;

  ngOnInit(): void {
    this.ConsultarDatosFormulario();
    this.ConsultarInformeCaracterizacion();
  }

  public Ecomapas: Array<EnumerationObject> = new Array<EnumerationObject>();

  public ConsultarDatosFormulario(): void {
    UtilitiesAnibalService.ShowLoading();
    this._informesService.GetDatosFormulario().subscribe(response => {
      UtilitiesAnibalService.HideLoading();
      this.Ecomapas = response.Ecomapas;
      if (this.Ecomapas.length > 0) {
        this.EcomapaSeleccionado = this.Ecomapas[0].Value;
        this.ConsultarInformeEcomapa();
      }
    }, err => {
      UtilitiesAnibalService.HideLoading();
    });
  }

  public ColumnasInformeCaracterizacion: Array<any> = [
    { title: 'Asentamiento', name: 'Nombre' },
    { title: 'Familias', name: 'CantidadFamilias' },
    { title: 'Gestantes', name: 'Gestantes' },
    { title: 'Desnutrición / Riesgo', name: 'DesnutridosOEnRiesgo' },
    { title: 'Capacitaciones', name: 'Capacitaciones' },
    { title: 'Seguimientos', name: 'Seguimientos' },
  ];

  public CargandoTablaCaracterizacion: boolean;

  public InformeCaracterizacion: Array<InformeAsentamientosFamiliasCaracterizadas> = new Array<InformeAsentamientosFamiliasCaracterizadas>();
  public InformeCaracterizacionWithTotal: Array<InformeAsentamientosFamiliasCaracterizadas> = new Array<InformeAsentamientosFamiliasCaracterizadas>();
  public ConsultarInformeCaracterizacion(): void {
    UtilitiesAnibalService.ShowLoading();
    this.CargandoTablaCaracterizacion = true;
    this._informesService.GetInformeCaracterizacion().subscribe(response => {
      this.CargandoTablaCaracterizacion = false;
      UtilitiesAnibalService.HideLoading();
      this.InformeCaracterizacion = response.InformeAsentamientos;
      this.InformeCaracterizacionWithTotal = Object.assign([], this.InformeCaracterizacion);
      var CantidadFamilias: number = 0 ;
      var Gestantes: number = 0 ;
      var DesnutridosOEnRiesgo: number = 0 ;
      var Capacitaciones: number = 0 ;
      var Seguimientos: number = 0 ;
      this.InformeCaracterizacionWithTotal.forEach(t => {
        CantidadFamilias += t.CantidadFamilias;
        Gestantes += t.Gestantes;
        DesnutridosOEnRiesgo += t.DesnutridosOEnRiesgo;
        Capacitaciones += t.Capacitaciones;
        Seguimientos += t.Seguimientos;
      });
      this.InformeCaracterizacionWithTotal.push({ Nombre:"TOTAL", CantidadFamilias: CantidadFamilias, Capacitaciones: Capacitaciones, DesnutridosOEnRiesgo: DesnutridosOEnRiesgo, Gestantes: Gestantes, Seguimientos: Seguimientos })
    }, err => {
      this.CargandoTablaCaracterizacion = false;
      UtilitiesAnibalService.HideLoading();
    });
  }

  public ColumnasInformeEcomapa: Array<any> = [
    { title: 'Asentamiento', name: 'AsentamientoNombre' },
    { title: 'Pregunta', name: 'NombrePregunta' },
    { title: 'Cantidad', name: 'Cantidad' },
  ];

  public CargandoTablaEcomapa: boolean;

  public InformeEcomapas: Array<InformeEcomapaPorAsentamientos> = new Array<InformeEcomapaPorAsentamientos>();
  public ConsultarInformeEcomapa(): void {
    if (!this.EcomapaSeleccionado) { this._toastr.warning("Debe seleccionar un filtro para el informe!!"); return; }

    UtilitiesAnibalService.ShowLoading();
    this.CargandoTablaEcomapa = true;
    this._informesService.GetInformeEcomapa(this.EcomapaSeleccionado).subscribe(response => {
      this.CargandoTablaEcomapa = false;
      UtilitiesAnibalService.HideLoading();
      this.InformeEcomapas = response.InformeAsentamientos;
    }, err => {
      this.CargandoTablaEcomapa = false;
      UtilitiesAnibalService.HideLoading();
    });
  }

}
