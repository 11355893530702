import { Injectable } from '@angular/core';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';
import { DatosEntidad } from '../../../../entities/general/datos-entidad';
import * as moment from 'moment';
import { UtilitiesAnibalService } from '../../../../services/utilities-anibal';
import { ConvertColumnsAndRowsToArraysService } from './convert-columns-rows-to-array.service';

@Injectable()
export class ExportExcelService {

  constructor(private _convertColumnsAndRowsToArraysService: ConvertColumnsAndRowsToArraysService) {
  }

  private _datosEntidad: DatosEntidad;
  async generateExcel(columns: Array<any>, filas: Array<any>, logoBase64: string, datosEntidad: DatosEntidad, nameReport: string, nameFileWithoutExtension: string) {
    this._datosEntidad = datosEntidad;

    // const ExcelJS = await import('exceljs');
    // console.log(ExcelJS);
    // const Workbook: any = {};

    // Excel Title, Header, Data
    //const header = ['Year', 'Month', 'Make', 'Model', 'Quantity', 'Pct'];
    //const data = [
    //  [2007, 1, 'Volkswagen ', 'Volkswagen Passat', 1267, 10],
    //  [2007, 1, 'Toyota ', 'Toyota Rav4', 819, 6.5],
    //  [2007, 1, 'Toyota ', 'Toyota Avensis', 787, 6.2],
    //  [2007, 1, 'Volkswagen ', 'Volkswagen Golf', 720, 5.7],
    //  [2007, 1, 'Toyota ', 'Toyota Corolla', 691, 5.4],
    //  [2007, 1, 'Peugeot ', 'Peugeot 307', 481, 3.8],
    //  [2008, 1, 'Toyota ', 'Toyota Prius', 217, 2.2],
    //  [2008, 1, 'Skoda ', 'Skoda Octavia', 216, 2.2],
    //  [2008, 1, 'Peugeot ', 'Peugeot 308', 135, 1.4],
    //  [2008, 2, 'Ford ', 'Ford Mondeo', 624, 5.9],
    //  [2008, 2, 'Volkswagen ', 'Volkswagen Passat', 551, 5.2],
    //  [2008, 2, 'Volkswagen ', 'Volkswagen Golf', 488, 4.6],
    //  [2008, 2, 'Volvo ', 'Volvo V70', 392, 3.7],
    //  [2008, 2, 'Toyota ', 'Toyota Auris', 342, 3.2],
    //  [2008, 2, 'Volkswagen ', 'Volkswagen Tiguan', 340, 3.2],
    //  [2008, 2, 'Toyota ', 'Toyota Avensis', 315, 3],
    //  [2008, 2, 'Nissan ', 'Nissan Qashqai', 272, 2.6],
    //  [2008, 2, 'Nissan ', 'Nissan X-Trail', 271, 2.6],
    //  [2008, 2, 'Mitsubishi ', 'Mitsubishi Outlander', 257, 2.4],
    //  [2008, 2, 'Toyota ', 'Toyota Rav4', 250, 2.4],
    //  [2008, 2, 'Ford ', 'Ford Focus', 235, 2.2],
    //  [2008, 2, 'Skoda ', 'Skoda Octavia', 225, 2.1],
    //  [2008, 2, 'Toyota ', 'Toyota Yaris', 222, 2.1],
    //  [2008, 2, 'Honda ', 'Honda CR-V', 219, 2.1],
    //  [2008, 2, 'Audi ', 'Audi A4', 200, 1.9],
    //  [2008, 2, 'BMW ', 'BMW 3-serie', 184, 1.7],
    //  [2008, 2, 'Toyota ', 'Toyota Prius', 165, 1.6],
    //  [2008, 2, 'Peugeot ', 'Peugeot 207', 144, 1.4]
    //];

    let response = this._convertColumnsAndRowsToArraysService.Convert(columns, filas);
    const header = response.Columnas;//['Year', 'Month', 'Make', 'Model', 'Quantity', 'Pct'];
    const data = response.Filas;

    let cantidadColumnas = header.length;
    let cantidadFilas = data.length;

    // Create workbook and worksheet
    const workbook = new Workbook();
    const worksheet = workbook.addWorksheet('Reporte');


    // Add Row and formatting
    const titleRow = worksheet.addRow(["", "", this._datosEntidad.Nombre]);
    titleRow.font = { name: 'Comic Sans MS', family: 4, size: 16, underline: 'double', bold: true };
    worksheet.addRow([]);
    worksheet.addRow(["", "", this._datosEntidad.Nit]);
    worksheet.addRow(["", "", this._datosEntidad.DireccionPrincipal]);
    worksheet.addRow(["", "", this._datosEntidad.Telefono + " - " + this._datosEntidad.Correo]);
    worksheet.addRow([nameReport.toUpperCase()]);

    // Add Image
    const logo = workbook.addImage({
      base64: logoBase64,
      extension: 'png',
    });

    let cantidadColumnasOcupaImagen = 2;//porque va de la A1:B5 Son dos columnas
    worksheet.addImage(logo, 'A1:B5');
    worksheet.mergeCells('A1:B5');//combino las celdas detras de la imagen

    let letraHastaDondeLLeganLasColumnas = this.columnToLetter(cantidadColumnas);
    let celdaNombreEntidad = worksheet.getCell('C1');
    celdaNombreEntidad.alignment = { horizontal: 'center', vertical: 'middle' };//centro vertical y horizontal el nombre de la entidad
    worksheet.mergeCells('C1:' + letraHastaDondeLLeganLasColumnas + '2');//combino celdas nombre de la entidad
    worksheet.mergeCells('C3:' + letraHastaDondeLLeganLasColumnas + '3');//combino celdas del nit
    worksheet.mergeCells('C4:' + letraHastaDondeLLeganLasColumnas + '4');//combino celdas del direccion
    worksheet.mergeCells('C5:' + letraHastaDondeLLeganLasColumnas + '5');//combino celdas del telefono y correo
    worksheet.mergeCells('A6:' + letraHastaDondeLLeganLasColumnas + '6');//combino celdas del nombre del informe
    let celdaNombreInforme = worksheet.getCell('A6');
    celdaNombreInforme.alignment = { horizontal: 'center', vertical: 'middle' };//centro vertical y horizontal el nombre del informe
    celdaNombreInforme.font = { bold: true };//pongo en negrita el nombre del informe
    // Blank Row
    //worksheet.addRow([]);

    // Add Header Row
    const headerRow = worksheet.addRow(header);

    // Cell Style : Fill and Border
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: 'pattern',
        pattern: 'solid',
        fgColor: { argb: '20C4C8' },
        bgColor: { argb: '20C4C8' }
      };
      cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    });
    // worksheet.addRows(data);


     //Add Data and Conditional Formatting
    data.forEach(d => {
      const row = worksheet.addRow(d);
      //Estas lineas son para agregar condiciones y estilos a cada uno de los registros en su respectiva celda
      //En el if que podemos apreciar hay una condicion que exponia que si el valor de dicha celda era manor a 500 le daba un color si no era otro.

      //const qty = row.getCell(5);
      //let color = 'FFFFFF';
      //if (+qty.value < 500) {
      //  color = '20C4C8';
      //}

      //qty.fill = {
      //  type: 'pattern',
      //  pattern: 'solid',
      //  fgColor: { argb: color }
      //};
    }

    );

    worksheet.getColumn(3).width = 30;
    worksheet.getColumn(4).width = 30;
    worksheet.addRow([]);


    // Footer Row
    const footerRow = worksheet.addRow([UtilitiesAnibalService.AppName + " :" + moment().format('lll')]);
    footerRow.getCell(1).fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'A5E2D5' }
    };
    footerRow.getCell(1).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };

    // Merge Cells
    worksheet.mergeCells(`A${footerRow.number}:${letraHastaDondeLLeganLasColumnas}${footerRow.number}`);

    // Generate Excel File with given name
    workbook.xlsx.writeBuffer().then((data: any) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, nameFileWithoutExtension + '.xlsx');
    });

  }

  private columnToLetter(column): string {
    var temp, letter = '';
    while (column > 0) {
      temp = (column - 1) % 26;
      letter = String.fromCharCode(temp + 65) + letter;
      column = (column - temp - 1) / 26;
    }
    return letter;
  }

  private letterToColumn(letter): number {
    var column = 0, length = letter.length;
    for (var i = 0; i < length; i++) {
      column += (letter.charCodeAt(i) - 64) * Math.pow(26, length - i - 1);
    }
    return column;
  }
}
