import { Injectable } from '@angular/core';
import { UtilitiesAnibalService } from '../utilities-anibal';
import { ApisRestSoporteTecnico } from '../../system/apisrest';
import { Observable } from 'rxjs';
import { HttpService } from '../http/http.service';

@Injectable()
export class SoporteTecnicoService {

  constructor(private _httpService: HttpService) { }

  public EnviarSoporte(request: SoporteTecnicoRequest): Observable<SoporteTecnicoResponse> {
    UtilitiesAnibalService.Console("Enviando soporte tecnico " + request.Descripcion);
    return this._httpService.post<SoporteTecnicoResponse>(ApisRestSoporteTecnico.PostEnviarSoporte, request);
  }
}

export class SoporteTecnicoResponse {
  public Mensaje: string;
}

export class SoporteTecnicoRequest {
  public Descripcion: string;
  public ExtensionDocumento: string;
  public NombreDocumento: string;
  public Documento: any;
}
