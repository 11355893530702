import { Injectable } from '@angular/core';
import { HttpService } from '../../../http/http.service';
import { Observable } from 'rxjs';
import { UtilitiesAnibalService } from '../../../utilities-anibal';
import { FamiliaIndigenaApgar } from '../../../../entities/sipa/familiaindigena';
import { ApisRestCaracterizacionFamiliaIndigenaGestionApgar } from '../../../../system/apisrest';
import { EnumerationObject } from '../../../../entities/general/enumeration-object';

@Injectable()
export class ApgarFamiliaIndigenaService {

  constructor(private _httpService: HttpService) { }

  public GetConsultarDatosFormulario(familiaIndigenaId: number): Observable<GestionApgarDatosFormularioResponse> {
    UtilitiesAnibalService.Console("Consultando apgar de la familia (Datos formulario gestion)" + familiaIndigenaId);
    return this._httpService.get<GestionApgarDatosFormularioResponse>(ApisRestCaracterizacionFamiliaIndigenaGestionApgar.GetConsultarDatosFormulario(familiaIndigenaId));
  }

  public GestionApgar(request: GestionApgarRequest): Observable<GestionApgarResponse> {
    UtilitiesAnibalService.Console("Gestión apgar de la familia" + request.FamiliaIndigenaId);
    return this._httpService.post<GestionApgarResponse>(ApisRestCaracterizacionFamiliaIndigenaGestionApgar.PostGestionApgar, request);
  }

}

export class GestionApgarResponse {
  public Mensaje: string;
}

export class GestionApgarRequest {
  public FamiliaIndigenaId: number;
  public Apgar: FamiliaIndigenaApgar = new FamiliaIndigenaApgar();
}

export class GestionApgarDatosFormularioResponse {
  public Apgar: FamiliaIndigenaApgar = new FamiliaIndigenaApgar();
  public RespuestasApgars: Array<EnumerationObject> = new Array<EnumerationObject>();
}
