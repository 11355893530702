import { Injectable } from '@angular/core';
import { FamiliaIndigenaNutricion, FamiliaIndigenaIntegrante } from '../../../../entities/sipa/familiaindigena';
import { Observable } from 'rxjs';
import { HttpService } from '../../../http/http.service';
import { UtilitiesAnibalService } from '../../../utilities-anibal';
import { ApisRestCaracterizacionFamiliaIndigenaGestionNutriciones } from '../../../../system/apisrest';

@Injectable()
export class NutricionesFamiliaIndigenaService {

  constructor(private _httpService: HttpService) { }

  public GetConsultarDatosFormulario(familiaIndigenaId: number): Observable<GestionnutricionesDatosFormularioResponse> {
    UtilitiesAnibalService.Console("Consultando nutriciones de la familia (Datos formulario gestion)" + familiaIndigenaId);
    return this._httpService.get<GestionnutricionesDatosFormularioResponse>(ApisRestCaracterizacionFamiliaIndigenaGestionNutriciones.GetConsultarDatosFormulario(familiaIndigenaId));
  }

  public GestionNutricionesFamilia(request: GestionnutricionesRequest): Observable<GestionnutricionesResponse> {
    UtilitiesAnibalService.Console("Gestión nutriciones de la familia" + request.FamiliaIndigenaId);
    return this._httpService.post<GestionnutricionesResponse>(ApisRestCaracterizacionFamiliaIndigenaGestionNutriciones.PostGestionNutriciones, request);
  }

  public GetIntegranteParaNutricion(identificacion: string, familiaIndigenaId: number): Observable<ConsultaIntegranteParaNutricion> {
    UtilitiesAnibalService.Console("Consultando integrante para remision de la familia" + familiaIndigenaId);
    return this._httpService.get<ConsultaIntegranteParaNutricion>(ApisRestCaracterizacionFamiliaIndigenaGestionNutriciones.GetIntegranteFamilia(identificacion, familiaIndigenaId));
  }

}

/**
 * A pesar que es una lista debe devolver uno solo ya que es por familia
 * */
export class ConsultaIntegranteParaNutricion {
  public Integrantes: Array<FamiliaIndigenaIntegrante> = new Array<FamiliaIndigenaIntegrante>();
}

export class GestionnutricionesResponse {
  public Mensaje: string;
}

export class GestionnutricionesRequest {
  public FamiliaIndigenaId: number;
  public Nutriciones: Array<FamiliaIndigenaNutricion> = new Array<FamiliaIndigenaNutricion>();
}

export class GestionnutricionesDatosFormularioResponse {
  public Nutriciones: Array<FamiliaIndigenaNutricion> = new Array<FamiliaIndigenaNutricion>();
}
