import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgxSelectModule } from 'ngx-select-ex';

import { AnibalModulesModule } from '../anibalmodules/anibalmodules.module';
import { GestionnoticiaComponent } from './gestionnoticia/gestionnoticia.component';
import { GestionparametrosComponent } from './gestionparametros/gestionparametros.component';
import { GestionvigenciasComponent } from './gestionvigencias/gestionvigencias.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        NgxSelectModule,
        AnibalModulesModule,
        BrowserAnimationsModule,
        TooltipModule.forRoot(),
        PaginationModule.forRoot(),
        ModalModule.forRoot(),
        BsDatepickerModule.forRoot()
    ],
    declarations: [GestionnoticiaComponent, GestionparametrosComponent, GestionvigenciasComponent]
})
export class ConfiguracionModule { }
