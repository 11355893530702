import { Injectable } from '@angular/core';
import { FamiliaIndigenaIntegrante } from '../../../../entities/sipa/familiaindigena';
import { Observable } from 'rxjs';
import { HttpService } from '../../../http/http.service';
import { UtilitiesAnibalService } from '../../../utilities-anibal';
import {  ApisRestCaracterizacionFamiliaIndigenaGestionIntegrantes } from '../../../../system/apisrest';
import { EnumerationObject } from '../../../../entities/general/enumeration-object';

@Injectable()
export class GestionintegrantesService {

  constructor(private _httpService: HttpService) { }

  public GetConsultarDatosFormulario(familiaIndigenaId: number): Observable<GestionintegrantesDatosFormularioResponse> {
    UtilitiesAnibalService.Console("Consultando integrantes de la familia (Datos formulario gestion)" + familiaIndigenaId);
    return this._httpService.get<GestionintegrantesDatosFormularioResponse>(ApisRestCaracterizacionFamiliaIndigenaGestionIntegrantes.GetConsultarDatosFormulario(familiaIndigenaId));
  }

  public GestionIntegrantesFamilia(request: GestionintegrantesRequest): Observable<GestionintegrantesResponse> {
    UtilitiesAnibalService.Console("Gestión integrantes de la familia" + request.FamiliaIndigenaId);
    return this._httpService.post<GestionintegrantesResponse>(ApisRestCaracterizacionFamiliaIndigenaGestionIntegrantes.PostGestionIntegrantes, request);
  }

  public GetIntegrantes(identificacion: string): Observable<ConsultaIntegranteParaVerificarExistencia> {
    UtilitiesAnibalService.Console("Consultando integrante para verificar la familia" + identificacion);
    return this._httpService.get<ConsultaIntegranteParaVerificarExistencia>(ApisRestCaracterizacionFamiliaIndigenaGestionIntegrantes.GetIntegrantes(identificacion));
  }

}

/**
 * A pesar que es una lista debe devolver uno solo ya que es por familia
 * */
export class ConsultaIntegranteParaVerificarExistencia {
  public Integrantes: Array<FamiliaIndigenaIntegranteParaVerificarExistencia> = new Array<FamiliaIndigenaIntegranteParaVerificarExistencia>();
}

export class FamiliaIndigenaIntegranteParaVerificarExistencia extends FamiliaIndigenaIntegrante{
  public BotonSeleccionarIntegrante: string;
}

export class GestionintegrantesResponse {
  public Mensaje: string ;
}

export class GestionintegrantesRequest {
  public FamiliaIndigenaId: number;
  public Integrantes: Array<FamiliaIndigenaIntegrante> = new Array<FamiliaIndigenaIntegrante>();
}

export class GestionintegrantesDatosFormularioResponse {
  public Parentescos: Array<EnumerationObject> = new Array<EnumerationObject>();
  public TiposIdentificacion: Array<EnumerationObject> = new Array<EnumerationObject>();
  public Integrantes: Array<FamiliaIndigenaIntegrante> = new Array<FamiliaIndigenaIntegrante>();
}
