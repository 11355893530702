import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataTableGenericComponent } from '../../components/anibal/data-table-generic/data-table-generic.component';
import { DetalleTerceroComponent } from '../../components/anibal/detalle-tercero/detalle-tercero.component';
import { ChangePasswordComponent } from '../../components/anibal/change-password/change-password.component';
import { LoginComponent } from '../appviews/login.component';
import { FormsModule } from '@angular/forms';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { AppLoadService } from '../../components/anibal/app-load/app-load.service';
import { PermissionService } from '../../services/security/permission.service';
import { SoporteTecnicoComponent } from '../../components/anibal/soporte-tecnico/soporte-tecnico.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ConfirmationDialogComponent } from '../../components/anibal/confirmation-dialog/confirmation-dialog.component';
import { PipeSumByKey } from '../../components/anibal/pipe-sumby-key/pipe-sumby-key-pipe';
import { ModalGenericComponent } from '../../components/anibal/modal-generic/modal-generic.component';
import { AddComponentDirective } from '../../components/anibal/modal-generic/directives/add-component.directive';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { NgxCaptureModule } from 'ngx-capture';

export function init_app(appLoadService: AppLoadService) {
  return () => appLoadService.initializeApp();
}

export function get_settings(appLoadService: AppLoadService) {
  return () => appLoadService.getSettings();
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    Ng2SmartTableModule,
    BrowserAnimationsModule,
    NgxCaptureModule,
    TooltipModule.forRoot(),
    PaginationModule.forRoot(),
  ],
  exports: [
    PipeSumByKey, DataTableGenericComponent, DetalleTerceroComponent, LoginComponent, ChangePasswordComponent, SoporteTecnicoComponent, ConfirmationDialogComponent
  ],
  declarations: [PipeSumByKey, DataTableGenericComponent, DetalleTerceroComponent, LoginComponent, ChangePasswordComponent, SoporteTecnicoComponent, ModalGenericComponent, ConfirmationDialogComponent, AddComponentDirective],
  entryComponents: [
    ConfirmationDialogComponent,
    ModalGenericComponent
  ],
  providers: [
    PermissionService,
    AppLoadService,
    { provide: APP_INITIALIZER, useFactory: init_app, deps: [AppLoadService], multi: true },
    //{ provide: APP_INITIALIZER, useFactory: get_settings, deps: [AppLoadService], multi: true }
  ]
})
export class AnibalModulesModule { }
