import { Injectable } from '@angular/core';
import { ConsultaUserTerceroAndPermissionResponse, AsignarPaginaInicialResponse, AsignarPaginaInicialRequest } from './entities/asignar-pagina-inicio';
import { HttpService } from '../../http/http.service';
import { ApisRestAsignarPaginaInicio } from '../../../system/apisrest';

import { UtilitiesAnibalService } from '../../utilities-anibal';
import { Observable } from 'rxjs';

@Injectable()
export class AsignarPaginaInicioService {

  constructor(private _httpService: HttpService) { }

  public ConsultarTerceroyPermisos(TerceroId: string): Observable<ConsultaUserTerceroAndPermissionResponse> {
    UtilitiesAnibalService.Console("Consultando tercero, usuario ans permisos");
    return this._httpService.get<ConsultaUserTerceroAndPermissionResponse>(ApisRestAsignarPaginaInicio.GetTerceroWithPermissionByIdTercero + "/" + TerceroId);
  }

  public RegistrarPermisoInicial(request: AsignarPaginaInicialRequest): Observable<AsignarPaginaInicialResponse> {
    UtilitiesAnibalService.Console("Asignando Pagina inicial");
    return this._httpService.post<AsignarPaginaInicialResponse>(ApisRestAsignarPaginaInicio.PostAsignarPaginaInicialByUserAndPermissionName, request);
  }
}
