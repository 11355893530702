import { ApisRestBase } from '../system/apisrest';
import { ConstantConfigSystem } from '../system/constantconfig';

declare var jQuery: any;

export class UtilitiesAnibalService { 
  public static DomainServer: string = ApisRestBase.UrlServer;

  public static AppName: string = ConstantConfigSystem.AppName;

  public static ExtensionesImagenesSoportadas: Array<string> = ['jpg', 'jpeg', 'png'];

  public static ExtensionesArchivosSoportados: Array<string> = [
    UtilitiesAnibalService.ExtensionesImagenesSoportadas[0],
    UtilitiesAnibalService.ExtensionesImagenesSoportadas[1],
    UtilitiesAnibalService.ExtensionesImagenesSoportadas[2],
    'pdf',
    'doc',
    'xls',
    'txt',
    'docx',
    'xlsx',
    'txt',
    'zip',
    'rar'
  ];

  public static Console(mensaje: any) {
    console.log(mensaje);
  }

  public static Loading: boolean = false;

  public static ShowLoading() {
    this.Loading = true;
  }

  public static HideLoading() {
    this.Loading = false;
  }

  public static SetUrlForIframe(idIframe: string, url: string) {
    jQuery("#" + idIframe).attr('src', url + "");
    setTimeout(() => {
      jQuery("#" + idIframe).attr('src', url + "");
    }, 500);
  }
}

