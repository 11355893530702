import { Component, OnInit, Input, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FamiliaIndigena, FamiliaIndigenaNutricion } from '../../../../../entities/sipa/familiaindigena';
import { UtilitiesAnibalService } from '../../../../../services/utilities-anibal';
import { EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { NutricionesFamiliaIndigenaService, GestionnutricionesRequest } from '../../../../../services/sipa/caracterizacion/nutricioncapacitacion/nutriciones-familia-indigena.service';


@Component({
  selector: 'app-nutricion',
  templateUrl: './nutricion.component.html',
  styleUrls: ['./nutricion.component.css'],
  providers: [ToastrService, NutricionesFamiliaIndigenaService]
})
export class NutricionComponent implements OnInit {

  constructor(
    private _toastr: ToastrService,
    private _gestionnutricionesService: NutricionesFamiliaIndigenaService,
  ) { }

  ngOnInit() {
  }

  @Output() public NotificacionComponente: EventEmitter<GestionNutricionesComponentEventResponse> = new EventEmitter();
  private ObjectNotificacion: GestionNutricionesComponentEventResponse = new GestionNutricionesComponentEventResponse();

  private _FamiliaIndigena: FamiliaIndigena;
  @Input()
  set FamiliaIndigena(familia: FamiliaIndigena) {
    this._FamiliaIndigena = familia;
    if (familia && familia.Id) {
      this.RequestGestion.FamiliaIndigenaId = familia.Id;
      this.ConsultarDatosFormulario();
    }
  }

  get FamiliaIndigena(): FamiliaIndigena {
    return this._FamiliaIndigena;
  }

  public RequestGestion: GestionnutricionesRequest = new GestionnutricionesRequest();

  public NuevoIntegrante(): void {
    let objeto = new FamiliaIndigenaNutricion();
    this.RequestGestion.Nutriciones.push(objeto);
  }

  public Guardar(): void {
    UtilitiesAnibalService.ShowLoading();
    this._gestionnutricionesService.GestionNutricionesFamilia(this.RequestGestion).subscribe(response => {
      UtilitiesAnibalService.HideLoading();
      this.ConsultarDatosFormulario();
      this._toastr.success(response.Mensaje, "Correcto!");
    }, err => {
      UtilitiesAnibalService.HideLoading();
    });
  }

  public ConsultarDatosFormulario(): void {
    this.ObjectNotificacion.EstaConsultando = true;
    this.NotificacionComponente.emit(this.ObjectNotificacion);
    this._gestionnutricionesService.GetConsultarDatosFormulario(this._FamiliaIndigena.Id).subscribe(response => {
      this.ObjectNotificacion.EstaConsultando = false;
      this.RequestGestion.Nutriciones = response.Nutriciones;
      this.ObjectNotificacion.CantidadNutriciones = response.Nutriciones.length;
      this.NotificacionComponente.emit(this.ObjectNotificacion);
    }, err => {
    });
  }

  public ConsultarIntegranteParaNutricion(nutricion: FamiliaIndigenaNutricion): void {
    if (nutricion.IdentificacionIntegrante) {
      UtilitiesAnibalService.ShowLoading();
      this._gestionnutricionesService.GetIntegranteParaNutricion(nutricion.IdentificacionIntegrante, this._FamiliaIndigena.Id).subscribe(response => {
        UtilitiesAnibalService.HideLoading();
        if (response.Integrantes.length > 0) {
          if (response.Integrantes.length == 1) {
            var integrante = response.Integrantes[0];
            var nutriciones = this.RequestGestion.Nutriciones.filter(function (nutricion, index) {
              return nutricion.IdentificacionIntegrante == integrante.Identificacion;
            });
            if (nutriciones.length == 1) {
              var nutricion = nutriciones[0];
              nutricion.NombreCompletoIntegrante = integrante.NombreCompleto;
              nutricion.SexoIntegrante = integrante.Sexo;
              nutricion.FamiliaIndigenaIntegranteId = integrante.Id;
              nutricion.EdadIntegrante = integrante.EdadValor;
              nutricion.TipoEdadIntegrante = integrante.TipoEdad;
            } else {
              this._toastr.warning("Se detecto que existen identificaciones duplicadas para realizar el registro de nutriciones, por favor verifique!!");
            }
          } else {
            ///Si esto pasa es porque en la familia se registraron dos integrantes con la misma identificación, esto se deberia validar 2019-08-02: 12:39 am
            this._toastr.warning("Se encontraron mas de un integrante con la misma identificación, por favor comuniquese con el departamento de soporte!!");
          }
        } else {
          this._toastr.warning("No se encontro integrante para realizar la nutrición");
        }
      }, err => {
        UtilitiesAnibalService.HideLoading();
      });
    }
  }
}

export class GestionNutricionesComponentEventResponse {
  public CantidadNutriciones: number;
  public EstaConsultando: boolean;
}
