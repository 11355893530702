export class Noticia {
    public Id: number;
    public Titulo: string;
    public Subtitulo: string;
    public Descripcion: string;
    public Fecha: Date;
    public Principal: boolean;
    public Estado: string;
    public Documentos: Array<DocumentoNoticia>;
    public Imagenes: Array<DocumentoNoticia>;
}

export class DocumentoNoticia {
    public Id: number;
    public DocumentoSoporte: string;
    public ExtensionDocumento: string;
    public NombreDocumento: string;
    public Size: number;
    public PqrId: number;
}

export class ImagenNoticia extends DocumentoNoticia {
}

