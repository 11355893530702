import { Component, OnInit } from '@angular/core';
import { Pqrs } from '../../../entities/pqr/pqr';
import { ConsultaPqrsByRadicadoResponse, ConsultaPqrsService } from '../../../services/public/consultapqrs/consulta-pqrs.service';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApisRestPublic } from '../../../system/apisrest';
import { ConstantConfigSystem } from '../../../system/constantconfig';

@Component({
    selector: 'app-consultapqrs',
    templateUrl: './consultapqrs.component.html',
    styleUrls: ['./consultapqrs.component.css'],
    providers: [ConsultaPqrsService]
})
export class ConsultapqrsComponent implements OnInit {

    public Pqrs: Pqrs;
  public NumeroRadicado: string;
  public AppName: string = ConstantConfigSystem.AppName;

    public UrlApiDownloadDocumentoSoportePqr: string = ApisRestPublic.GetDownloadDocumentoSoportePqrsById;
    public UrlApiDownloadDocumentoSoporteRespuestaPqr: string = ApisRestPublic.GetDownloadDocumentoSoporteRespuestaPqrsById;

    public disabledButton: boolean = false;

    constructor(private route: ActivatedRoute,
        private _toastr: ToastrService,
        private _consultaPqrsService: ConsultaPqrsService,
    ) { }


    ngOnInit() {

    }

    public ConsultarPqrsByNumero() {
        if (this.NumeroRadicado) {
            this.Pqrs = null;
            this.ConsultarPqrs(this.NumeroRadicado);
        } else {
            this._toastr.warning("El numero del radicado es Obligatorio!!");
        }
    }

    private ConsultarPqrs(numeroRadicado: string) {
        this.disabledButton = true;
        this._consultaPqrsService.GetPqrsByNumeroRadicado(numeroRadicado)
            .subscribe(response => {
                this.Pqrs = response.Pqrs;
                this.disabledButton = false;
            }, err => {
                this._toastr.error(this._consultaPqrsService.GetMensaje(err));
                this.disabledButton = false;
            });
    }
}
