import { Injectable } from '@angular/core';
import { HttpService } from '../../http/http.service';
import { ApisRestGestionNoticia } from '../../../system/apisrest';

import { Observable } from 'rxjs';
import { Noticia } from '../../../entities/configuracion/noticia';
import { UtilitiesAnibalService } from '../../utilities-anibal';

@Injectable()
export class GestionNoticiaService {

  constructor(private _httpService: HttpService) { }

  public ConsultarNoticias(request: ConsultarNoticiaRequest): Observable<ConsultarNoticiaResponse> {
    UtilitiesAnibalService.Console("Consultando noticias sistema");
    return this._httpService.post<ConsultarNoticiaResponse>(ApisRestGestionNoticia.PostConsultaParametrizada, request);
  }

  public RegistrarNoticias(request: RegistrarNoticiaRequest): Observable<RegistrarNoticiaResponse> {
    UtilitiesAnibalService.Console("registrando noticia");
    return this._httpService.post<RegistrarNoticiaResponse>(ApisRestGestionNoticia.PostRegistrarNoticia, request);
  }

  public CambiarEstadoNoticia(request: CambiarEstadoNoticiaRequest): Observable<CambiarEstadoNoticiaResponse> {
    UtilitiesAnibalService.Console("Cambiado estado noticia " + request.NoticiaId + " por " + request.Estado);
    return this._httpService.post<CambiarEstadoNoticiaResponse>(ApisRestGestionNoticia.PostCambiarEstadoNoticia, request);
  }

  public EstablecerComoPrincipalNoticia(request: EstablecerNoticiaPrincipalRequest): Observable<EstablecerNoticiaPrincipalResponse> {
    UtilitiesAnibalService.Console("Cambiado estado principal de noticia " + request.NoticiaId);
    return this._httpService.post<EstablecerNoticiaPrincipalResponse>(ApisRestGestionNoticia.PostEstablecerComoPrincipal, request);
  }
}

export class EstablecerNoticiaPrincipalRequest {
  public Principal: boolean;
  public NoticiaId: number;
}

export class EstablecerNoticiaPrincipalResponse {
  public Mensaje: string;
}

export class CambiarEstadoNoticiaRequest {
  public Estado: string;
  public NoticiaId: number;
}

export class CambiarEstadoNoticiaResponse {
  public Mensaje: string;
}

export class RegistrarNoticiaRequest {
  public Descripcion: string;
  public Titulo: string;
  public Subtitulo: string;
  public Principal: boolean;
  public PoseeVideoYoutube: boolean;
  public UrlVideoYoutube: string;
  public Documentos: Array<DocumentoNoticiaRequest>;
  public Imagenes: Array<ImagenNoticiaRequest>;
}

export class DocumentoNoticiaRequest {
  public ExtensionDocumento: string;
  public NombreDocumento: string;
  public Documento: any;
}

export class ImagenNoticiaRequest extends DocumentoNoticiaRequest {

}

export class RegistrarNoticiaResponse {
  public Mensaje: string;
}

export class ConsultarNoticiaRequest {
  public Descripcion: string;
  public Estado: string;
  public FechaFinal: Date;
  public FechaInicial: Date;
  public Principal: boolean;
  public Titulo: string;
  public Subtitulo?: string;
}

export class ConsultarNoticiaResponse {
  public ListaNoticia: Array<NoticiaTable>;
}

export class NoticiaTable extends Noticia {
  public EstadoBadge: string;
  public FechaPipe: string;
  public ApiImagenNoticia: string;
  public ApiDocumentoNoticia: string;
  public BotonCambiarEstadoNoticia: string;
  public BotonEstablecerComoPrincipal: string;
  public BadgePrincipal: string;
}
