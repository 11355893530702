import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
    GestionVigenciasService,
    RegistrarVigenciaRequest,
    VigenciaTimeLine
} from '../../../services/configuracion/gestionvigencias/gestion-vigencias.service';
import { ToastrService } from 'ngx-toastr';
import { CellClickedTableGeneric } from '../../../components/anibal/data-table-generic/data-table-generic.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { Noticia } from '../../../entities/configuracion/noticia';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UtilitiesAnibalService } from '../../../services/utilities-anibal';
import { ApisRestPublic } from '../../../system/apisrest';


@Component({
    selector: 'app-gestionvigencias',
    templateUrl: './gestionvigencias.component.html',
    styleUrls: ['./gestionvigencias.component.css'],
    providers: [GestionVigenciasService, ToastrService]
})
export class GestionvigenciasComponent implements OnInit {

    constructor(
        private _gestionVigenciasService: GestionVigenciasService,
        private _toastr: ToastrService) {
        this.ConsultarVigencias();
    }

    ngOnInit() {
    }

    public Vigencias: Array<VigenciaTimeLine> = [];
    public FechaActual: Date = new Date();

    public ConsultarVigencias(): void {
        this.Vigencias = [];
        UtilitiesAnibalService.ShowLoading();
        this._gestionVigenciasService.ConsultarTodasLasVigencias().subscribe(response => {
            this.Vigencias = response.Vigencias;
            UtilitiesAnibalService.HideLoading();
        }, err => {
            UtilitiesAnibalService.HideLoading();
        });
    }

    public Request: RegistrarVigenciaRequest = { VigenciaNueva: null };

    public NuevaVigencia(): void {
        UtilitiesAnibalService.ShowLoading();
        this._gestionVigenciasService.RegistrarVigencia(this.Request).subscribe(response => {
            this._toastr.success(response.Mensaje, "Correcto!");
            this.ConsultarVigencias();
            UtilitiesAnibalService.HideLoading();
        }, err => {
            UtilitiesAnibalService.HideLoading();
        });
    }
}
