import { Component, TemplateRef, AfterContentInit } from '@angular/core';
import { Router } from '@angular/router';
import 'jquery-slimscroll';
import { AuthService } from '../../../services/security/auth.service';
import { PermissionService } from '../../../services/security/permission.service';
import { Modulos, Permission } from '../../../entities/security/modulos';
import { OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxPermissionsService } from 'ngx-permissions';
import { User } from '../../../entities/security/user';
import { UserServiceService } from '../../../services/security/general/user-service.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Title } from '@angular/platform-browser';
import { LoadPermissionAppHelper } from '../../anibal/app-load/app-load.service';

declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'navigation',
  templateUrl: 'navigation.template.html',
  providers: [AuthService, PermissionService, UserServiceService, ToastrService]
})

export class NavigationComponent implements AfterContentInit {
  public UserName: string;
  public NombreTercero: string;
  public Modulos: Array<Modulos> = new Array<Modulos>();

  public Usuario: User;
  public ApiPostChangePasswordForUser: string;

  private static singleton: NavigationComponent;

  constructor(
    private _router: Router,
    private _serviceAuth: AuthService,
    private _permissionService: PermissionService,
    private _toastr: ToastrService,
    private _userServiceService: UserServiceService,
    private _modalService: BsModalService,
    private _ngxPermissionsService: NgxPermissionsService,
    private _titleService: Title) {
    if (NavigationComponent.singleton) {
      return NavigationComponent.singleton;
    }
    NavigationComponent.singleton = this;
  }

  public static DeteleObjectSingleton() {
    this.singleton = null;
  }

  public AbrirMenu(moduloSeleccionado: Modulos): void {
    this.Modulos.forEach(modulo => {
      $('#LiModulo-' + modulo.Modulo).removeClass('active');
      $('#UlModulo-' + modulo.Modulo).removeClass('in');
      if (modulo.Modulo == moduloSeleccionado.Modulo) {
        $('#LiModulo-' + modulo.Modulo).addClass('active');
        $('#UlModulo-' + modulo.Modulo).addClass('in');
      }
    });
  }

  private ConsultarPermisos() {
    this.UserName = this._serviceAuth.GetUserNameLogged();
    this.NombreTercero = this._serviceAuth.GetNombreTerceroLogged();
    this._permissionService.GetModulosWithPermission().subscribe(response => {
      let responseService = LoadPermissionAppHelper.MappedPermission(response, this._serviceAuth, this._toastr);
      if (responseService) {
        this.Modulos = responseService.ModulosVisbles;
        this.OcultarProblemaPrimerClick();
        this._ngxPermissionsService.loadPermissions(responseService.Permisos);
        if (response.PermisoInicial) {
          this._router.navigate([response.PermisoInicial.Url]);
        }
      }
    });
    this.ApiPostChangePasswordForUser = this._userServiceService.GetApiPostChangePasswordForUser();
    this._userServiceService.GetDatosUsuario().subscribe(response => {
      if (!response.Error) {
        this.Usuario = response.Usuario;
      } else {
        this._toastr.error(response.Mensaje, "Error!!");
      }
    })

  }

  private OcultarProblemaPrimerClick(): void {
    //setTimeout(() => {
    //  let PaginaActual = document.location.hash.split("#/")[1];
    //  //Se debe a que cuando carga la pagina y se cargan los menus, por algun motivo que desconosco el primer click que se hace al menu, conlleva a recargar la pagina.
    //  //por tanto estoy buscando el primer elemento del menu y le doy click automaticamente asi el usuario no tendra que hacerlo
    //  let solucionarProblemaDelPrimerclick: HTMLElement = document.getElementById("side-menu").children[1].children[0] as HTMLElement;
    //  solucionarProblemaDelPrimerclick.click();
    //  if (PaginaActual != "Home") {
    //    setTimeout(() => {
    //      //esto es para el caso en que existe una pagina diferente a la del Home y el usuario pór algun motivo necesita actualizar la pagina
    //      //al hacer esto el sistema se mantiene en la pagina actual sin embargo, cuando le de click a otro menu tambien se recargara la paginna y se redireccionara a la pagina de inicio "Home"
    //      //por tanto almaceno en una variable la pagina actual y luego de darle click al primer elemento del menu luego de que se recargue la pagina, lo redirijo a la pagina que el usuario tenia.
    //      this._router.navigate([PaginaActual]);
    //    }, 300);
    //  }
    //}, 50);
  }

  public CambiarTitle(subMenu: Modulos) {
    var titulo = this._titleService.getTitle();
    if (titulo.toString().indexOf(" | ") > 0) {
      titulo = titulo.toString().split(" | ")[0];
    }
    this._titleService.setTitle(titulo + " | " + subMenu.Titulo);
  }

  private modalRef: BsModalRef;

  public OpenModal(template: TemplateRef<any>) {
    this.modalRef = this._modalService.show(template, { class: 'modal-lg' });
  }

  public OpenModalCapturaPantalla(template: TemplateRef<any>) {
    this.OpenModal(template);
  }

  ngAfterContentInit() {
    if (this.Modulos.length <= 0) {
      this.ConsultarPermisos();
    }
    let menu = $('#side-menu');
    menu.metisMenu({
      toggle: false
    });

    if (jQuery("body").hasClass('fixed-sidebar')) {
      jQuery('.sidebar-collapse').slimscroll({
        height: '100%'
      })
    }

    this.CosasNuevas();
  }

  private CosasNuevas() {
    // Config box
    var localStorageSupport = typeof (Storage) !== "undefined";
    // Enable/disable fixed top navbar
    jQuery('#fixednavbar').click(function () {
      if (jQuery('#fixednavbar').is(':checked')) {
        jQuery(".navbar-static-top").removeClass('navbar-static-top').addClass('navbar-fixed-top');
        jQuery("body").removeClass('boxed-layout');
        jQuery("body").addClass('fixed-nav');
        jQuery('#boxedlayout').prop('checked', false);

        if (localStorageSupport) {
          localStorage.setItem("boxedlayout", 'off');
        }

        if (localStorageSupport) {
          localStorage.setItem("fixednavbar", 'on');
        }
      } else {
        jQuery(".navbar-fixed-top").removeClass('navbar-fixed-top').addClass('navbar-static-top');
        jQuery("body").removeClass('fixed-nav');
        jQuery("body").removeClass('fixed-nav-basic');
        jQuery('#fixednavbar2').prop('checked', false);

        if (localStorageSupport) {
          localStorage.setItem("fixednavbar", 'off');
        }

        if (localStorageSupport) {
          localStorage.setItem("fixednavbar2", 'off');
        }
      }
    });

    // Enable/disable fixed top navbar
    jQuery('#fixednavbar2').click(function () {
      if (jQuery('#fixednavbar2').is(':checked')) {
        jQuery(".navbar-static-top").removeClass('navbar-static-top').addClass('navbar-fixed-top');
        jQuery("body").removeClass('boxed-layout');
        jQuery("body").addClass('fixed-nav').addClass('fixed-nav-basic');
        jQuery('#boxedlayout').prop('checked', false);

        if (localStorageSupport) {
          localStorage.setItem("boxedlayout", 'off');
        }

        if (localStorageSupport) {
          localStorage.setItem("fixednavbar2", 'on');
        }
      } else {
        jQuery(".navbar-fixed-top").removeClass('navbar-fixed-top').addClass('navbar-static-top');
        jQuery("body").removeClass('fixed-nav').removeClass('fixed-nav-basic');
        jQuery('#fixednavbar').prop('checked', false);

        if (localStorageSupport) {
          localStorage.setItem("fixednavbar2", 'off');
        }
        if (localStorageSupport) {
          localStorage.setItem("fixednavbar", 'off');
        }
      }
    });

    // Enable/disable fixed sidebar
    jQuery('#fixedsidebar').click(function () {
      if (jQuery('#fixedsidebar').is(':checked')) {
        jQuery("body").addClass('fixed-sidebar');
        jQuery('.sidebar-collapse').slimScroll({
          height: '100%',
          railOpacity: 0.9
        });

        if (localStorageSupport) {
          localStorage.setItem("fixedsidebar", 'on');
        }
      } else {
        jQuery('.sidebar-collapse').slimscroll({ destroy: true });
        jQuery('.sidebar-collapse').attr('style', '');
        jQuery("body").removeClass('fixed-sidebar');

        if (localStorageSupport) {
          localStorage.setItem("fixedsidebar", 'off');
        }
      }
    });

    // Enable/disable collapse menu
    jQuery('#collapsemenu').click(function () {
      if (jQuery('#collapsemenu').is(':checked')) {
        jQuery("body").addClass('mini-navbar');
        //SmoothlyMenu();

        if (localStorageSupport) {
          localStorage.setItem("collapse_menu", 'on');
        }

      } else {
        jQuery("body").removeClass('mini-navbar');
        //SmoothlyMenu();

        if (localStorageSupport) {
          localStorage.setItem("collapse_menu", 'off');
        }
      }
    });

    // Enable/disable boxed layout
    jQuery('#boxedlayout').click(function () {
      if (jQuery('#boxedlayout').is(':checked')) {
        jQuery("body").addClass('boxed-layout');
        jQuery('#fixednavbar').prop('checked', false);
        jQuery('#fixednavbar2').prop('checked', false);
        jQuery(".navbar-fixed-top").removeClass('navbar-fixed-top').addClass('navbar-static-top');
        jQuery("body").removeClass('fixed-nav');
        jQuery("body").removeClass('fixed-nav-basic');
        jQuery(".footer").removeClass('fixed');
        jQuery('#fixedfooter').prop('checked', false);

        if (localStorageSupport) {
          localStorage.setItem("fixednavbar", 'off');
        }

        if (localStorageSupport) {
          localStorage.setItem("fixednavbar2", 'off');
        }

        if (localStorageSupport) {
          localStorage.setItem("fixedfooter", 'off');
        }


        if (localStorageSupport) {
          localStorage.setItem("boxedlayout", 'on');
        }
      } else {
        jQuery("body").removeClass('boxed-layout');

        if (localStorageSupport) {
          localStorage.setItem("boxedlayout", 'off');
        }
      }
    });

    // Enable/disable fixed footer
    jQuery('#fixedfooter').click(function () {
      if (jQuery('#fixedfooter').is(':checked')) {
        jQuery('#boxedlayout').prop('checked', false);
        jQuery("body").removeClass('boxed-layout');
        jQuery(".footer").addClass('fixed');

        if (localStorageSupport) {
          localStorage.setItem("boxedlayout", 'off');
        }

        if (localStorageSupport) {
          localStorage.setItem("fixedfooter", 'on');
        }
      } else {
        jQuery(".footer").removeClass('fixed');

        if (localStorageSupport) {
          localStorage.setItem("fixedfooter", 'off');
        }
      }
    });

    // SKIN Select
    jQuery('.spin-icon').click(function () {
      jQuery(".theme-config-box").toggleClass("show");
    });

    // Default skin
    jQuery('.s-skin-0').click(function () {
      jQuery("body").removeClass("skin-1");
      jQuery("body").removeClass("skin-2");
      jQuery("body").removeClass("skin-3");
    });

    // Blue skin
    jQuery('.s-skin-1').click(function () {
      jQuery("body").removeClass("skin-2");
      jQuery("body").removeClass("skin-3");
      jQuery("body").addClass("skin-1");
    });

    // Inspinia ultra skin
    jQuery('.s-skin-2').click(function () {
      jQuery("body").removeClass("skin-1");
      jQuery("body").removeClass("skin-3");
      jQuery("body").addClass("skin-2");
    });

    // Yellow skin
    jQuery('.s-skin-3').click(function () {
      jQuery("body").removeClass("skin-1");
      jQuery("body").removeClass("skin-2");
      jQuery("body").addClass("skin-3");
    });

    if (localStorageSupport) {
      var collapse = localStorage.getItem("collapse_menu");
      var fixedsidebar = localStorage.getItem("fixedsidebar");
      var fixednavbar = localStorage.getItem("fixednavbar");
      var fixednavbar2 = localStorage.getItem("fixednavbar2");
      var boxedlayout = localStorage.getItem("boxedlayout");
      var fixedfooter = localStorage.getItem("fixedfooter");

      if (collapse == 'on') {
        jQuery('#collapsemenu').prop('checked', 'checked')
      }
      if (fixedsidebar == 'on') {
        jQuery('#fixedsidebar').prop('checked', 'checked')
      }
      if (fixednavbar == 'on') {
        jQuery('#fixednavbar').prop('checked', 'checked')
      }
      if (fixednavbar2 == 'on') {
        jQuery('#fixednavbar2').prop('checked', 'checked')
      }
      if (boxedlayout == 'on') {
        jQuery('#boxedlayout').prop('checked', 'checked')
      }
      if (fixedfooter == 'on') {
        jQuery('#fixedfooter').prop('checked', 'checked')
      }
    }
  }

  activeRoute(routename: string): boolean {
    let comparacion = this._router.url.indexOf(routename);

    return comparacion > -1;
  }

  Logout(): void {
    this._serviceAuth.logout();
  }
}
