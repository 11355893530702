<div class="wrapper wrapper-content animated fadeInRight">
  <div class="row">
    <div class="col-lg-12">
      <div class="ibox float-e-margins">
        <div class="ibox-title">
          <h5>Terceros <small class="m-l-sm"> Consulta parametrizada</small></h5>
        </div>
        <div class="ibox-content ">
          <div class="row">
            <div class="col-lg-2 col-sm-12 col-md-2">
              <div class="form-group">
                <label>Tipo tercero</label>
                <select class="form-control" [(ngModel)]="RequestConsultaParametrizada.TipoTercero" name="TipoTercero">
                  <option value="">Todos</option>
                  <option *ngFor="let tipoTercero of TiposTerceros" [value]="tipoTercero.Value">
                    {{tipoTercero.DisplayName}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-lg-2">
              <div class="form-group">
                <label>Identificación</label>
                <input type="text" [(ngModel)]="RequestConsultaParametrizada.Identificacion" name="Identificacion" required placeholder="Identificación" class="form-control">
              </div>
            </div>
            <div class="col-lg-2">
              <div class="form-group">
                <label>Dirección</label>
                <input type="text" [(ngModel)]="RequestConsultaParametrizada.Direccion" name="Direccion" required placeholder="Dirección" class="form-control">
              </div>
            </div>
            <div class="col-lg-2">
              <div class="form-group">
                <label>Nombre</label>
                <input type="text" [(ngModel)]="RequestConsultaParametrizada.NombreCompleto" name="NombreCompleto" required placeholder="Nombre" class="form-control">
              </div>
            </div>
            <div class="col-lg-2">
              <div class="form-group">
                <label>Correo electronico</label>
                <input type="text" [(ngModel)]="RequestConsultaParametrizada.CorreoElectronico" name="CorreoElectronico" required placeholder="Correo electronico" class="form-control">
              </div>
            </div>
            <div class="col-lg-2 col-sm-12 col-md-2">
              <button class="btn btn-primary " (click)="ConsultarTerceros()" type="button"><i class="fa fa-search"></i>&nbsp;Consultar</button>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <app-data-table-generic [NameReport]="'Informe de terceros registrados en el sistema'" [Filas]="Terceros" [ShowLoading]="CargandoTablaTerceros" [columns]="Columnas"></app-data-table-generic>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
