import { Routes } from "@angular/router";

import { NgxPermissionsGuard } from 'ngx-permissions';
import { BasicLayoutComponent } from "../components/common/layouts/basicLayout.component";

import { GestiontercerosComponent } from '../views/datosbasicos/gestionterceros/gestionterceros.component';

export const DatosBasicos: any = {
  path: 'DatosBasicos', component: BasicLayoutComponent,
  children: [
    {
      path: 'Consulta/Terceros', component: GestiontercerosComponent, canActivate: [NgxPermissionsGuard],
      data: {
        permissions: {
          only: ['DatosBasicos.ConsultaTerceros'],
          redirectTo: 'Home'
        }
      }
    }
  ]
};
