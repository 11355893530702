import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {
  GestionParametrosService,
  ParametroTable,
  ModificarParametroRequest,
  DetallesValidosParametro,
  DetalleParametroModificacionRequest,
  ConsultaParametrizadaParametrosRequest
} from '../../../services/configuracion/gestionparametros/gestion-parametros.service';
import { ToastrService } from 'ngx-toastr';
import { CellClickedTableGeneric } from '../../../components/anibal/data-table-generic/data-table-generic.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UtilitiesAnibalService } from '../../../services/utilities-anibal';
import { ParametroBasic } from '../../../entities/configuracion/parametro';
import { EnumerationObject } from '../../../entities/general/enumeration-object';
import * as moment from 'moment';

@Component({
  selector: 'app-gestionparametros',
  templateUrl: './gestionparametros.component.html',
  styleUrls: ['./gestionparametros.component.css'],
  providers: [GestionParametrosService, ToastrService]
})
export class GestionparametrosComponent implements OnInit {

  constructor(
    private _gestionParametroService: GestionParametrosService,
    private _toastr: ToastrService,
    private _modalService: BsModalService,
    private _modalServiceModificarParametro: BsModalService,
    private _router: Router) {
    this.ConsultarDatosFormulario();
  }
  public Columnas: Array<any> = [
    { title: 'Id', name: 'Id' },
    { title: 'Nombre', name: 'Nombre', classNameRow: 'text-left' },
    { title: 'Descripción', name: 'Descripcion', classNameRow: 'cortar-texto-3' },
    { title: 'Cantidad Detalles', name: 'CantidadDetallesBadge', classNameRow: 'text-center', replaceNameInExportFor: 'CantidadDetallesRegistrados' },
    { title: 'Estado', name: 'EstadoBadge', classNameRow: 'text-center', replaceNameInExportFor: 'Estado' },
    { title: '', name: 'BotonEditarParametro', classNameRow: 'text-center', hideInExport: true  },
  ];

  public disabledButtonRegistroParametro: boolean = false;
  private modalRefRegistroParametro: BsModalRef;

  public CargandoTablaParametros: boolean = true;

  public Modulos: Array<EnumerationObject>;
  public TiposDatos: Array<EnumerationObject>;
  public Estados: Array<EnumerationObject>;

  public ParametrosNoRegistrados: Array<ParametroBasic>;

  ngOnInit() {
    this.ParametrosNoRegistrados = [];
  }
  public Request: ConsultaParametrizadaParametrosRequest = { Modulo: "" };

  public Parametros: Array<ParametroTable>;

  public ConsultarParametrosRegistrados(): void {
    this.CargandoTablaParametros = true;
    this.Parametros = [];
    this._gestionParametroService.ConsultarParametrosRegistrados(this.Request).subscribe(response => {
      this.CargandoTablaParametros = false;
      this.Parametros = response.ListaParametros;
      for (let parametro of this.Parametros) {
        if (parametro.Estado == "Activo") {
          parametro.EstadoBadge = '<span class="badge badge-success">' + parametro.Estado + '</span>';
        } else {
          parametro.EstadoBadge = '<span class="badge badge-danger">' + parametro.Estado + '</span>';
        }
        if (parametro.CantidadDetallesRegistrados < parametro.CantidadDetallesObligatorios) {
          parametro.CantidadDetallesBadge = '<span class="badge badge-warning">ATENCIÓN: ha registrado ' + parametro.CantidadDetallesRegistrados + ' detalles debe registrar por lo menos ' + parametro.CantidadDetallesObligatorios + '</span>';
        } else if (parametro.CantidadDetallesRegistrados > 0) {
          parametro.CantidadDetallesBadge = '<span class="badge badge-success" title="Usted puede registrar mas de ' + parametro.CantidadDetallesRegistrados + ' detalles en caso de ser necesario">Se han registrado ' + parametro.CantidadDetallesRegistrados + ' detalles de ' + parametro.CantidadDetallesObligatorios + ' obligatorios</span>';
        } else {
          parametro.CantidadDetallesBadge = '<span class="badge badge-danger">ATENCIÓN: Debe registrar por lo menos ' + parametro.CantidadDetallesObligatorios + ' detalles</span>';
        }
        parametro.BotonEditarParametro = '<a href="javascript:0;"  title="Editar parametro" class="btn btn-xs btn-primary"><i class="fa fa-edit"> Editar</i></a>';
      }
    }, err => {
      this.CargandoTablaParametros = false;
    });
  }

  public ConsultarDatosFormulario(): void {
    this.CargandoTablaParametros = true;
    this.Parametros = [];
    this._gestionParametroService.ConsultarDatosFormulario().subscribe(response => {
      this.ConsultarParametrosRegistrados();
      this.CargandoTablaParametros = false;
      this.Modulos = response.Modulos;
      this.Estados = response.Estados;
      this.TiposDatos = response.TiposDatos;
      this.ParametrosNoRegistrados = response.ParametrosNoRegistrados;
    }, err => {
      this.CargandoTablaParametros = false;
    });
  }

  @ViewChild('ModalRegistrarParametro') public templateRefModalRegistrar: TemplateRef<any>;

  public OpenModalRegistroParametro(template: TemplateRef<any>) {
    this.disabledButtonRegistroParametro = false;
    this.modalRefRegistroParametro = this._modalService.show(this.templateRefModalRegistrar);
  }

  public ParametroARegistrarSeleccionado = "";
  public RequestRegistroParametro: ParametroBasic = { Modulo: "", Abreviatura: "", Descripcion: "", Estado: "", Nombre: "", Id: 0, CantidadDetallesObligatorios: 0, CantidadDetallesRegistrados: 0 };
  public CambioParametroARegistrar() {
    this.RequestRegistroParametro = this.ParametrosNoRegistrados.filter(it => {
      return it.Abreviatura == this.ParametroARegistrarSeleccionado;
    })[0];
  }

  public RegistrarParametro(parametro): void {
    this.disabledButtonRegistroParametro = true;
    UtilitiesAnibalService.ShowLoading();
    this._gestionParametroService.RegistrarParametro(this.RequestRegistroParametro).subscribe(response => {
      UtilitiesAnibalService.HideLoading();
      this.ConsultarDatosFormulario();
      this.disabledButtonRegistroParametro = false;
      this.modalRefRegistroParametro.hide();
      this._toastr.success(response.Mensaje, "Correcto!");
    }, err => {
      UtilitiesAnibalService.HideLoading();
      this.disabledButtonRegistroParametro = false;
    });
  }

  @ViewChild('ModalModificarParametro') public templateRefModalEditar: TemplateRef<any>;
  private modalRefModificarParametro: BsModalRef;
  public disabledButtonModificarParametro: boolean;

  public OpenModalModificarParametro() {
    this.disabledButtonModificarParametro = false;
    this.modalRefModificarParametro = this._modalServiceModificarParametro.show(this.templateRefModalEditar, { class: "modal-dialog modal-lg modal-xlg80" });
  }

  //public OpenModalModificarParametro(template: TemplateRef<any>) {
  //  this.disabledButtonRegistroParametro = false;
  //  this._modalService.config.class = "modal-dialog modal-lg";
  //  this.modalRefModificarParametro = this._modalService.show(template);
  //}

  public ClickFila(data: CellClickedTableGeneric): any {
    if (data.Columna === "BotonEditarParametro") {
      this.ConsultarParametro((data.Fila as ParametroTable).Abreviatura);
    }
  }

  public RequestModificar: ModificarParametroRequest = { Descripcion: "", Detalles: [], Estado: "", Nombre: "", ParametroId: 0 }
  public DetallesValidosParametro: Array<DetallesValidosParametro> = [];

  public ConsultarParametro(abreviatura: string): void {
    UtilitiesAnibalService.ShowLoading();
    this._gestionParametroService.ConsultarDetallesValidosFromParametro(abreviatura).subscribe(response => {
      UtilitiesAnibalService.HideLoading();
      this.RequestModificar = response.Parametro;
      for (let detalle of this.RequestModificar.Detalles) {
        detalle.FechaFinal = moment(detalle.FechaFinal).format("YYYY-MM-DD");
        detalle.FechaInicial = moment(detalle.FechaInicial).format("YYYY-MM-DD");
      }
      this.DetallesValidosParametro = response.DetallesValidos;
      this.OpenModalModificarParametro();
    }, err => {
      UtilitiesAnibalService.HideLoading();
    });
  }

  public ModificarParametro(parametro): void {
    this.disabledButtonModificarParametro = true;
    UtilitiesAnibalService.ShowLoading();
    this._gestionParametroService.ModificarParametro(this.RequestModificar).subscribe(response => {
      UtilitiesAnibalService.HideLoading();
      this.ConsultarDatosFormulario();
      this.disabledButtonModificarParametro = false;
      this.modalRefModificarParametro.hide();
      this._toastr.success(response.Mensaje, "Correcto!");
    }, err => {
      UtilitiesAnibalService.HideLoading();
      this.disabledButtonModificarParametro = false;
    });
  }

  public NuevoDetalle: DetalleParametroModificacionRequest = { Abreviatura: "", Descripcion: "", Estado: "", FechaFinal: "", FechaInicial: "", Id: null, Nombre: "", TipoDato: "", Valor: "" }

  public SeleccionoUnNuevoDetalle() {
    let detallesRegistradosConLaMismaAbreviatura = this.RequestModificar.Detalles.filter(it => {
      return it.Abreviatura == this.NuevoDetalle.Abreviatura;
    });

    let detalleConCamposSujeridos = this.DetallesValidosParametro.filter(it => {
      return it.Abreviatura == this.NuevoDetalle.Abreviatura;
    })[0];

    this.NuevoDetalle.Nombre = detalleConCamposSujeridos.Nombre;
    this.NuevoDetalle.TipoDato = detalleConCamposSujeridos.TipoDato;
    this.NuevoDetalle.Estado = detalleConCamposSujeridos.Estado;
    this.NuevoDetalle.Abreviatura = detalleConCamposSujeridos.Abreviatura;
    this.NuevoDetalle.Descripcion = detalleConCamposSujeridos.Descripcion;

    this.NuevoDetalle.FechaInicial = moment().format("YYYY-MM-DD");
    this.NuevoDetalle.FechaFinal = moment(new Date().getFullYear() + "-12-31").format("YYYY-MM-DD");

    if (detallesRegistradosConLaMismaAbreviatura.length <= 0) {
      this.NuevoDetalle.Valor = detalleConCamposSujeridos.Valor;
    }
  }

  public AgregarDetalle() {
    if (this.NuevoDetalle.Abreviatura) {
      this.RequestModificar.Detalles.unshift(this.NuevoDetalle);
      this.NuevoDetalle = { Abreviatura: "", Descripcion: "", Estado: "", FechaFinal: "", FechaInicial: "", Id: null, Nombre: "", TipoDato: "", Valor: "" };
    } else {
      this._toastr.warning("No ha seleccionado ningun detalle del parametro", "Advertencia!");
    }
  }

  public AgregarTodosLosDetalles() {
    if (this.RequestModificar.Detalles.length == 0) {
      for (let detalle of this.DetallesValidosParametro) {
        this.NuevoDetalle = new DetalleParametroModificacionRequest();
        this.NuevoDetalle.Abreviatura = detalle.Abreviatura;
        this.SeleccionoUnNuevoDetalle();
        this.AgregarDetalle();
      }
    } else {
      this._toastr.error("Esta opción es solo para aquellos parametros nuevos!!");
    }
  }

}
