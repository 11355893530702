<div class="ibox">
  <div class="ibox-content">
    <div class="row">
      <div class="col-lg-12">
        <div class="m-b-md">
          <button type="button" class="btn btn-primary btn-sm pull-right" (click)="Guardar()"><i class="fa fa-save (alias)"></i> Guardar</button>
          <h2>APGAR: FUNCIONALIDAD FAMILIAR / AFECTO</h2>
        </div>

      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <dl class="row mb-0">
          <div class="col-sm-4 text-sm-right"><dt>Valoración:</dt> </div>
          <div class="col-sm-8 text-sm-left"><dd class="mb-1"><span class="label label-primary">{{RequestGestion.Apgar.ValoracionApgar}}</span></dd></div>
        </dl>
        <dl class="row mb-0">
          <div class="col-sm-4 text-sm-right"><dt>Valor:</dt> </div>
          <div class="col-sm-8 text-sm-left"><dd class="mb-1">{{RequestGestion.Apgar.ValorApgar}}</dd> </div>
        </dl>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Observación</label>
          <textarea rows="3" required [(ngModel)]="RequestGestion.Apgar.Observacion" name="ApgarObservacion" style="width: 100%;"></textarea>
        </div>
      </div>
    </div>
    <div class="row m-t-sm">
      <div class="col-lg-12">
        <label>Cuestionario</label>
        <div class="table-responsive">
          <table class="table table-striped table-bordered">
            <thead>
              <tr>
                <th class="text-center">Pregunta</th>
                <th class="text-center">Respuesta</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let cuestionario of RequestGestion.Apgar.Cuestionarios">
                <td class="text-left" placement="top" title="{{cuestionario.NombrePregunta}}">
                  {{cuestionario.NombrePregunta}}
                </td>
                <td class="text-left" >
                  <select class="form-control" [(ngModel)]="cuestionario.Valor">
                    <option value="">Seleccione una opción</option>
                    <option value="{{respuestaApgar.Value}}" *ngFor="let respuestaApgar of RespuestasApgars">{{respuestaApgar.DisplayName}}</option>
                  </select>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
