export class ApisRestBase {
  public static UrlServer = "";
}

export class ApisRestUser extends ApisRestBase {
  public static PostLogin = ApisRestUser.UrlServer + "Token";
  public static PostLogout = ApisRestUser.UrlServer + "api/Account/Logout";
  public static GetPermissionUser = ApisRestUser.UrlServer + "api/User/Permission";
  public static GetUser = ApisRestUser.UrlServer + "api/User";
  public static PostChangePassword = ApisRestUser.UrlServer + "api/User/ChangePassword";
}

export class ApisRestSoporteTecnico extends ApisRestBase {
  public static PostEnviarSoporte = ApisRestSoporteTecnico.UrlServer + "api/Soporte/Tecnico/Enviar";
}

export class ApisRestGestionUsers extends ApisRestBase {
  public static GetAllUsers = ApisRestGestionUsers.UrlServer + "api/Gestion/User/Get/All";
  public static PostRegisterUser = ApisRestGestionUsers.UrlServer + "api/Gestion/User/Register";
}

export class ApisRestHealthApplication extends ApisRestBase {
  public static GetAllProcess = ApisRestBase.UrlServer + "api/Health/Process";
  public static GetKillProcess(processName): string {
    return ApisRestBase.UrlServer + "api/Health/Kill/JsReport/" + processName;
  }
}

export class ApisRestGestionPermisos extends ApisRestBase {
  public static GetModules = ApisRestGestionPermisos.UrlServer + "api/Roles/Modules";
  public static PostGetPermission = ApisRestGestionPermisos.UrlServer + "api/Roles/Get/Permission";
  public static PostGestionPermission = ApisRestGestionPermisos.UrlServer + "api/Roles/Set/Permission";
  public static GetUser = ApisRestGestionPermisos.UrlServer + "api/Roles/User";
}

export class ApisRestChangePassword extends ApisRestBase {
  public static PostChangePassword = ApisRestChangePassword.UrlServer + "api/Password/Change";
  public static GetUser = ApisRestChangePassword.UrlServer + "api/Password/User";
}

export class ApisRestAsignarPaginaInicio extends ApisRestBase {
  public static GetTerceroWithPermissionByIdTercero = ApisRestAsignarPaginaInicio.UrlServer + "api/Permission/Initial";
  public static PostAsignarPaginaInicialByUserAndPermissionName = ApisRestAsignarPaginaInicio.UrlServer + "api/Permission/Initial/Asignar";
}

export class ApisRestAudotiria extends ApisRestBase {
  public static GetDatosBasicos = ApisRestAudotiria.UrlServer + "api/Auditoria/datos";
  public static PostConsultaAuditoria = ApisRestAudotiria.UrlServer + "api/Auditoria/Parametrizada";
}

export class ApisRestForzarEmailConfirm extends ApisRestBase {
  public static GetConfirmEmail = ApisRestForzarEmailConfirm.UrlServer + "api/Confirm/Email/Forze";
  public static GetUser = ApisRestChangePassword.UrlServer + "api/Confirm/Email/User";
}

export class ApisRestModificarUserAndTercero extends ApisRestBase {
  public static GetTerceroAndUser = ApisRestModificarUserAndTercero.UrlServer + "api/Modificar/UserWithTercero";
  public static PostModificarTercero = ApisRestModificarUserAndTercero.UrlServer + "api/Modificar/UserWithTercero";
}

export class ApisRestCambiarEstadoUserAndTercero extends ApisRestBase {
  public static GetTerceroAndUser(idTercero): string { return ApisRestCambiarEstadoUserAndTercero.UrlServer + "api/Cambiar/Estado/UserWithTercero" + idTercero; }
  public static PostModificarTercero = ApisRestCambiarEstadoUserAndTercero.UrlServer + "api/Cambiar/Estado/UserWithTercero";
}

export class ApisRestRegistroPqr extends ApisRestBase {
  public static PostAllonimousRegistroPqr = ApisRestRegistroPqr.UrlServer + "api/PQR/Registro";
}

export class ApisRestConsultaPqr extends ApisRestBase {
  public static GetAllonimousPqrsByNumeroRadicado = ApisRestConsultaPqr.UrlServer + "api/Public/PQRS";
}

export class ApisRestGestionPqr extends ApisRestBase {
  public static PostConsultaParametrizada = ApisRestGestionPqr.UrlServer + "api/PQRS/Parametrizada";
}

export class ApisRestPublic extends ApisRestBase {

  public static GetDatosEntidad = ApisRestBase.UrlServer + "api/Public/DatosEntidad";

  public static GetPaginaPrincipalIndex = ApisRestBase.UrlServer + "api/Public/PaginaPrincipal";

  public static GetDownloadDocumentoSoportePqrsById = ApisRestPublic.UrlServer + "api/Public/Multimedia/DocumentoSoporte/PQRS/";
  public static GetDownloadDocumentoSoporteRespuestaPqrsById = ApisRestPublic.UrlServer + "api/Public/Multimedia/DocumentoSoporte/RespuestaPQRS/";

  public static GetDownloadDocumentoSoporteNoticiaById = ApisRestPublic.UrlServer + "api/Public/Multimedia/DocumentoSoporte/Noticia/";
  public static GetDownloadImagenNoticiaById = ApisRestPublic.UrlServer + "api/Public/Multimedia/Imagen/Noticia/";

  public static GetDepartamentos = ApisRestPublic.UrlServer + "api/Public/Departamento/All";
  public static GetMunicipios(departamentoId): string {
    return ApisRestPublic.UrlServer + "api/Public/Departamento/" + departamentoId + "/Municipios";
  }

  public static GetAsentamientos(municipioId): string {
    return ApisRestPublic.UrlServer + "api/Public/Departamento/" + municipioId + "/Asentamientos";
  }

  public static GetSectores(asentamientoId): string {
    return ApisRestPublic.UrlServer + "api/Public/Departamento/" + asentamientoId + "/Sectores";
  }
}

export class ApisRestResponderPqrs extends ApisRestBase {
  public static GetPqrsById = ApisRestResponderPqrs.UrlServer + "api/PQRS/ParaResponder";
  public static PostRegistrarRespuestaPqrs = ApisRestResponderPqrs.UrlServer + "api/PQRS/Responder";
}

export class ApisRestGestionNoticia extends ApisRestBase {
  public static PostConsultaParametrizada = ApisRestGestionNoticia.UrlServer + "api/Noticia/Parametrizada";
  public static PostRegistrarNoticia = ApisRestGestionNoticia.UrlServer + "api/Noticia/Registrar";
  public static PostCambiarEstadoNoticia = ApisRestGestionNoticia.UrlServer + "api/Noticia/CambiarEstado";
  public static PostEstablecerComoPrincipal = ApisRestGestionNoticia.UrlServer + "api/Noticia/EstablecerComoPrincipal";
}

export class ApisRestGestionParametros extends ApisRestBase {
  public static GetDatosFormulario = ApisRestGestionParametros.UrlServer + "api/Parametros/DatosFormulario";
  public static PostConsultaParametrizada = ApisRestGestionParametros.UrlServer + "api/Parametros/Parametrizada";
  public static PostRegistrarParametro = ApisRestGestionParametros.UrlServer + "api/Parametros/Registrar";

  public static GetConsultarDetallesFromParametro(abreviaturaParametro): string {
    return ApisRestGestionParametros.UrlServer + "api/Parametros/DetallesFromParametro/" + abreviaturaParametro;
  }
  public static PostModificarParametro = ApisRestGestionParametros.UrlServer + "api/Parametros/Modificar";
}

export class ApisRestGestionVigencias extends ApisRestBase {
  public static GetAllVigencias = ApisRestGestionVigencias.UrlServer + "api/Vigencia/All";
  public static PostRegistrarNuevaVigencia = ApisRestGestionVigencias.UrlServer + "api/Vigencia/Registrar";
}

export class ApisRestGestionTerceros extends ApisRestBase {
  public static GetDatosFormulario = ApisRestGestionTerceros.UrlServer + "api/Tercero/DatosFormulario";
  public static PostConsultaParametrizada = ApisRestGestionTerceros.UrlServer + "api/Tercero/ConsultaParametrizada";
}

export class ApisRestGestionFamiliasIndigenas extends ApisRestBase {
  public static GetDatosFormulario = ApisRestGestionFamiliasIndigenas.UrlServer + "api/FamiliasIndigenas/DatosFormulario";
  public static PostConsultaParametrizada = ApisRestGestionFamiliasIndigenas.UrlServer + "api/FamiliasIndigenas/ConsultaParametrizada";
  public static PostRegistrarFamilia = ApisRestGestionFamiliasIndigenas.UrlServer + "api/FamiliasIndigenas/Registro";
  public static GetIntegrantesFamilia(identificacion): string {
    return ApisRestGestionFamiliasIndigenas.UrlServer + "api/FamiliasIndigenas/Obtener/Integrante/" + identificacion;
  }
}

export class ApisRestCaracterizacionFamiliaIndigena extends ApisRestBase {
  public static GetConsultaFamiliaIndigena(numeroFamilia): string {
    return ApisRestGestionParametros.UrlServer + "api/Caracterizacion/FamiliaIndigena/" + numeroFamilia;
  }
}

export class ApisRestCaracterizacionFamiliaIndigenaGestionIntegrantes extends ApisRestBase {
  public static GetConsultarDatosFormulario(familiaIndigenaId: number): string {
    return ApisRestCaracterizacionFamiliaIndigenaGestionIntegrantes.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/Integrantes/DatosFormulario/" + familiaIndigenaId;
  }
  public static PostGestionIntegrantes = ApisRestGestionFamiliasIndigenas.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/Integrantes";
  public static GetIntegrantes(identificacion: string): string {
    return ApisRestCaracterizacionFamiliaIndigenaGestionIntegrantes.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/Integrantes/Obtener/Integrante/" + identificacion;
  }
}

export class ApisRestCaracterizacionFamiliaIndigenaGestionClasificacionFamiliar extends ApisRestBase {
  public static GetConsultarDatosFormulario(familiaIndigenaId: number): string {
    return ApisRestCaracterizacionFamiliaIndigenaGestionClasificacionFamiliar.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/ClasificacionFamiliar/DatosFormulario/" + familiaIndigenaId;
  }
  public static PostGestionClasificacionFamiliar = ApisRestCaracterizacionFamiliaIndigenaGestionClasificacionFamiliar.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/ClasificacionFamiliar";
}

export class ApisRestCaracterizacionFamiliaIndigenaGestionApgar extends ApisRestBase {
  public static GetConsultarDatosFormulario(familiaIndigenaId: number): string {
    return ApisRestCaracterizacionFamiliaIndigenaGestionApgar.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/Apgar/DatosFormulario/" + familiaIndigenaId;
  }
  public static PostGestionApgar = ApisRestCaracterizacionFamiliaIndigenaGestionApgar.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/Apgar";
}

export class ApisRestCaracterizacionFamiliaIndigenaGestionEcomapa extends ApisRestBase {
  public static GetConsultarDatosFormulario(familiaIndigenaId: number): string {
    return ApisRestCaracterizacionFamiliaIndigenaGestionEcomapa.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/Ecomapa/DatosFormulario/" + familiaIndigenaId;
  }
  public static PostGestionEcomapa = ApisRestCaracterizacionFamiliaIndigenaGestionEcomapa.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/Ecomapa";
}

export class ApisRestCaracterizacionFamiliaIndigenaGestionFactorProtector extends ApisRestBase {
  public static GetConsultarDatosFormulario(familiaIndigenaId: number): string {
    return ApisRestCaracterizacionFamiliaIndigenaGestionFactorProtector.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/FactorProtector/DatosFormulario/" + familiaIndigenaId;
  }
  public static PostGestionFactorProtector = ApisRestCaracterizacionFamiliaIndigenaGestionFactorProtector.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/FactorProtector";
}

export class ApisRestCaracterizacionFamiliaIndigenaGestionRemisiones extends ApisRestBase {
  public static GetConsultarDatosFormulario(familiaIndigenaId: number): string {
    return ApisRestCaracterizacionFamiliaIndigenaGestionRemisiones.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/Remisiones/DatosFormulario/" + familiaIndigenaId;
  }
  public static GetIntegranteFamilia(identificacion: string, familiaIndigenaId: number): string {
    return ApisRestCaracterizacionFamiliaIndigenaGestionRemisiones.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/Remisiones/Obtener/Integrante/" + identificacion + "/" + familiaIndigenaId;
  }
  public static PostGestionRemisiones = ApisRestCaracterizacionFamiliaIndigenaGestionRemisiones.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/Remisiones";
}

export class ApisRestCaracterizacionFamiliaIndigenaGestionProcesoRiesgo extends ApisRestBase {
  public static GetConsultarDatosFormulario(familiaIndigenaId: number): string {
    return ApisRestCaracterizacionFamiliaIndigenaGestionProcesoRiesgo.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/ProcesoRiesgo/DatosFormulario/" + familiaIndigenaId;
  }
  public static PostGestionProcesoRiesgo = ApisRestCaracterizacionFamiliaIndigenaGestionProcesoRiesgo.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/ProcesoRiesgo";
}

export class ApisRestCaracterizacionFamiliaIndigenaGestionNutriciones extends ApisRestBase {
  public static GetConsultarDatosFormulario(familiaIndigenaId: number): string {
    return ApisRestCaracterizacionFamiliaIndigenaGestionNutriciones.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/Nutriciones/DatosFormulario/" + familiaIndigenaId;
  }
  public static GetIntegranteFamilia(identificacion: string, familiaIndigenaId: number): string {
    return ApisRestCaracterizacionFamiliaIndigenaGestionNutriciones.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/Nutriciones/Obtener/Integrante/" + identificacion + "/" + familiaIndigenaId;
  }
  public static PostGestionNutriciones = ApisRestCaracterizacionFamiliaIndigenaGestionNutriciones.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/Nutriciones";
}

export class ApisRestCaracterizacionFamiliaIndigenaGestionCapacitaciones extends ApisRestBase {
  public static GetConsultarDatosFormulario(familiaIndigenaId: number): string {
    return ApisRestCaracterizacionFamiliaIndigenaGestionCapacitaciones.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/Capacitaciones/DatosFormulario/" + familiaIndigenaId;
  }
  public static PostGestionCapacitacion = ApisRestCaracterizacionFamiliaIndigenaGestionCapacitaciones.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/Capacitaciones";
}

export class ApisRestCaracterizacionFamiliaIndigenaGestionSeguimiento extends ApisRestBase {
  public static GetConsultarDatosFormulario(familiaIndigenaId: number): string {
    return ApisRestCaracterizacionFamiliaIndigenaGestionCapacitaciones.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/Seguimientos/DatosFormulario/" + familiaIndigenaId;
  }
  public static PostRegistroSeguimiento = ApisRestCaracterizacionFamiliaIndigenaGestionCapacitaciones.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/Seguimientos";

  public static GetConsultarSeguimiento(familiaIndigenaSeguimientoId: number): string {
    return ApisRestCaracterizacionFamiliaIndigenaGestionCapacitaciones.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/Seguimientos/Obtener/Seguimiento/" + familiaIndigenaSeguimientoId;
  }
  public static PostGestionSeguimiento = ApisRestCaracterizacionFamiliaIndigenaGestionCapacitaciones.UrlServer + "api/Caracterizacion/FamiliaIndigena/Gestion/Seguimientos/Seguimiento";
}

export class ApisRestInformesSipa extends ApisRestBase {
  public static GetDatosFormulario = ApisRestGestionFamiliasIndigenas.UrlServer + "api/Sipa/Informes/DatosFormulario";
  public static GetInformeCaracterizacion = ApisRestGestionFamiliasIndigenas.UrlServer + "api/Sipa/Informes/Caracterizacion";
  public static GetInformeEcomapa(codigoEcomapa): string {
    return ApisRestGestionParametros.UrlServer + "api/Sipa/Informes/Ecomapa/" + codigoEcomapa;
  }
}
