import { Component, OnInit, TemplateRef } from '@angular/core';
import { GestionUsersService } from '../../../services/security/gestionusuario/gestion-users.service';
import { UserTable } from '../../../entities/security/user';
import { RegistroUsuarioRequest } from './entities/registro-usuario';
import { ToastrService } from 'ngx-toastr';
import { DataTableGenericComponent, CellClickedTableGeneric } from '../../../components/anibal/data-table-generic/data-table-generic.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-registrousuario',
  templateUrl: './registrousuario.component.html',
  styleUrls: ['./registrousuario.component.css'],
  providers: [GestionUsersService, ToastrService]
})
export class RegistrousuarioComponent implements OnInit {

  constructor(
    private _gestionUsersService: GestionUsersService,
    private _toastr: ToastrService,
    private _modalService: BsModalService,
    private _router: Router) {

  }

  ngOnInit() {
    this.ConsultarUsuarios();
    this.UsuarioRequest.Tercero.Pais = "Colombia";
  }
  public disabledButton: boolean = false;
  private modalRef: BsModalRef;

  public Usuarios: UserTable[];
  public CargandoTablaUsuarios: boolean = true;
  public UsuarioRequest: RegistroUsuarioRequest = new RegistroUsuarioRequest();
  public Columnas: Array<any> = [
    { title: 'Id', name: 'TerceroId', classNameRow: 'text-right' },
    { title: 'Identificación', className: 'text-center', classNameRow: 'text-right', name: 'Identificacion' },
    { title: 'Nombre Completo', name: 'NombreCompleto', },
    { title: 'Email', name: 'Email' },
    { title: 'Usuario', name: 'UserName' },
    { title: 'Correo Confirmado', name: 'CorreoConfirmadoBadge', classNameRow: 'text-center', replaceNameInExportFor: 'CorreoConfirmado' },
    { title: 'FechaDesactivación', name: 'FechaPipe' },
    { title: 'Estado', name: 'EstadoBadge', classNameRow: 'text-center', replaceNameInExportFor: 'Estado' },
    { title: '', name: 'Permission', classNameRow: 'text-center' },
    { title: '', name: 'ChangePassword' },
    { title: '', name: 'ForzeEmailConfirm' },
    { title: '', name: 'ModificarUser' },
    { title: '', name: 'AsignarPaginaInicio' }
  ];

  private ConsultarUsuarios(): void {
    this.CargandoTablaUsuarios = true;
    this._gestionUsersService.GetAllUsuarios().subscribe(response => {
      this.CargandoTablaUsuarios = false;
      if (!response.Error) {
        this.Usuarios = response.Usuarios;
        for (let usuario of this.Usuarios) {
          if (usuario.Estado == "Activo") {
            usuario.EstadoBadge = '<span class="badge badge-success">' + usuario.Estado + '</span>';
          } else {
            usuario.EstadoBadge = '<span class="badge badge-danger">' + usuario.Estado + '</span>';
          }
          if (usuario.CorreoConfirmado == "SI") {
            usuario.CorreoConfirmadoBadge = '<span class="badge badge-success">' + usuario.CorreoConfirmado + '</span>';
          } else {
            usuario.CorreoConfirmadoBadge = '<span class="badge badge-warning">' + usuario.CorreoConfirmado + '</span>';
          }
          usuario.ChangePassword = '<a href="javascript:void(0);" title="Cambiar Contraseña" ><i class="fa fa-vcard-o (alias) text-navy" aria-hidden="true">';
          usuario.Permission = '<a href="javascript:void(0);" title="Asignar Permisos" ><i class="fa fa-tasks text-navy" aria-hidden="true">';
          usuario.ForzeEmailConfirm = '<a href="javascript:void(0);" title="Confirmar Correo" ><i class="fa fa-at" aria-hidden="true">';
          usuario.ModificarUser = '<a href="javascript:void(0);" title="Modificar Usuario" ><i class="fa fa-edit (alias)" aria-hidden="true">';
          usuario.AsignarPaginaInicio = '<a href="javascript:void(0);" title="Asignar Pagina Inicio" ><i class="fa fa-desktop" aria-hidden="true">';
          usuario.FechaPipe = new DatePipe("es-CO").transform(usuario.FechaDesactivacion, 'yyyy-MM-dd');
        }
      } else {
        this._toastr.warning(response.Mensaje, "Advertencia!");
      }
    }, err => {
      this.CargandoTablaUsuarios = false;
    });

  }

  public OpenModal(template: TemplateRef<any>) {
    this.disabledButton = false;
    this.modalRef = this._modalService.show(template, { class: 'modal-lg' });
  }

  public RegistrarUsuario(user): void {
    this.disabledButton = true;
    this._gestionUsersService.RegistrarUsuario(this.UsuarioRequest).subscribe(response => {
      this.disabledButton = false;
      if (!response.Error) {
        this.modalRef.hide();
        this._toastr.success(response.Mensaje, "Correcto!");
        this.ConsultarUsuarios();
      } else {
        this._toastr.error(response.Mensaje, "Advertencia!");
      }
    }, err => {
      this.disabledButton = false;
    });
  }

  public ClickFila(data: CellClickedTableGeneric): any {
    if (data.Columna === "Permission") {
      this._router.navigate(['/Seguridad/Permisos/User/' + data.Fila.UserName]);
    } else if (data.Columna === "ChangePassword") {
      this._router.navigate(['/Seguridad/Password/User/' + data.Fila.UserName]);
    } else if (data.Columna === "ForzeEmailConfirm") {
      this._router.navigate(['/Seguridad/Email/Confirm/User/' + data.Fila.UserName]);
    } else if (data.Columna === "ModificarUser") {
      this._router.navigate(['/Seguridad/Modificar/User/Tercero/' + data.Fila.TerceroId]);
    } else if (data.Columna === "AsignarPaginaInicio") {
      this._router.navigate(['/Seguridad/Asignar/Pagina/Inicio/' + data.Fila.TerceroId]);
    }
  }

  public CambioIdentificacion(identificacion): void {
    this.UsuarioRequest.Usuario.UserName = identificacion;
  }

  public CambioCorreoElectronico(correoelectronico): void {
    this.UsuarioRequest.Usuario.Email = correoelectronico;
  }

  public GenerarPassword(): void {
    if (this.UsuarioRequest.Tercero.Identificacion && this.UsuarioRequest.Tercero.TipoIdentificacion) {
      this.UsuarioRequest.Usuario.Password = this.UsuarioRequest.Tercero.TipoIdentificacion[0].toUpperCase() + this.UsuarioRequest.Tercero.TipoIdentificacion[0].toLowerCase() + this.UsuarioRequest.Tercero.Identificacion + "*";
      this.UsuarioRequest.Usuario.PasswordConfirm = this.UsuarioRequest.Usuario.Password;
    } else {
      this._toastr.warning("Debe digitar la identificación y tipo de identificación del tercero");
    }
  }
}
